import React, { useEffect, useState } from 'react';
import second_ellipes from '../../images/Ellipse_17.webp';
import Navbargrey from '../../Components/Navbargrey/Navbargrey';
import { Link, useNavigate } from 'react-router-dom';
// importing back and next button aldogwith progress bar
import BackandNext from './BackandNext/BackandNext';
import ProgressBar from './BackandNext/Progressbar';
import {
  getDecryptedItem,
  setEncryptedItem,
} from '../../Components/CommonLibrary/EncryptDecrypt';
import Loader from '../../Components/Loader';
import VerificationEmail from './VerificationEmail';
import './LightFinder8.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  IconButton,
  CardFooter,
  CardBody,
  Card,
  Input,
} from '@material-tailwind/react';

const LightFinder5 = () => {
  const navigate = useNavigate();
  const [otpNumber, setOtp] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(!isModalOpen);
  const [selectContact, setSelectContact] = useState(null);
  const [selectContactValue, setSelectContactValue] = useState('');
  const [email, setEmail] = useState('');
  const [emailValidation, setEmailValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState('');
  const handleClickContact = (index, value) => {
    setSelectContactValue(value);
    setSelectContact(index);
  };

  const [text, setText] = useState('');
  const wordLimit = 1000;

  const handleTextareaChange = (event) => {
    const inputText = event.target.value;
    const words = inputText.trim().split(/\s+/);

    if (words.length <= wordLimit) {
      setText(inputText);
    }
  };

  const handleEmail = (value) => {
    setEmail(value);
  };

  useEffect(() => {
    setEncryptedItem('lightfinder79', selectContactValue);
  }, [selectContactValue]);

  useEffect(() => {
    setEncryptedItem('lightfinder710', text);
  }, [text]);

  useEffect(() => {
    setEncryptedItem('lightfinder711', email);
  }, [email]);

  const handleSubmitForm = async () => {
    const email = getDecryptedItem('lightfinder711');
    if (email == '') {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
      setModalOpen(true);
      const email = getDecryptedItem('lightfinder711');
      CallOtpSendAPI(email);
    }
  };

  const CallOtpSendAPI = async (email) => {
    try {
      const Parameters = {
        email: email,
        otp: '',
        type: 'otpSend',
      };

      const requestBody = JSON.stringify(Parameters);
      const url =
        process.env.REACT_APP_API_URL + 'api/lightFinder-emailVerification';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: requestBody,
      });

      const result = await response.json();
      // console.log('result :', result);
    } catch (e) {}
  };

  const handleOtp = (value) => {
    setOtp(value);
  };

  const handleOtpVerify = async () => {
    isOtpVerified();
  };

  const isOtpVerified = async () => {
    try {
      const Parameters = {
        email: email,
        otp: otpNumber,
        type: 'otpVerify',
      };

      const requestBody = JSON.stringify(Parameters);
      const url =
        process.env.REACT_APP_API_URL + 'api/lightFinder-emailVerification';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: requestBody,
      });

      const result = await response.json();

      if (result?.status == 'success') {
        setOtpError('');
        setOtp('');
        setModalOpen(false);
        reportCreate();
      } else {
        setOtpError(result?.message);
      }
    } catch (e) {}
  };

  const reportCreate = async () => {
    setLoading(true);
    const lightfinder1 = getDecryptedItem('lightfinder1');
    const type_of_lighting = getDecryptedItem('lightfinder2');
    const lighting_position = getDecryptedItem('lightfinder3');
    const design_style = getDecryptedItem('lightfinder4');
    const application_area_and_dimensions_area =
      getDecryptedItem('lightfinder5');
    const filesUploaded = getDecryptedItem('lightfinder6Images');
    const budget = getDecryptedItem('lightfinder6Budget');
    const contacted = getDecryptedItem('lightfinder79');
    const comments = getDecryptedItem('lightfinder710');
    const email = getDecryptedItem('lightfinder711');

    try {
      const Parameters = {
        lightfinder1: JSON.stringify(lightfinder1),
        type_of_lighting: JSON.stringify(type_of_lighting),
        lighting_position: JSON.stringify(lighting_position),
        design_style: design_style,
        application_area_and_dimensions_area: JSON.stringify(
          application_area_and_dimensions_area
        ),
        filesUploaded: JSON.stringify(filesUploaded),
        budget: JSON.stringify(budget),
        contacted: contacted,
        comments: comments,
        email: email,
      };

      // Convert Parameters to JSON string
      const requestBody = JSON.stringify(Parameters);
      const url = process.env.REACT_APP_API_URL + 'api/lightFinder-store';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: requestBody,
      });

      const result = await response.json();

      if (result.status == 'success') {
        setLoading(false);
        toast.info('Form submitted successfully!');

        setEncryptedItem('dw', result.link);
        setTimeout(() => {
          localStorage.removeItem('lightfinder1');
          localStorage.removeItem('lightfinder2');
          localStorage.removeItem('lightfinder3');
          localStorage.removeItem('lightfinder4');
          localStorage.removeItem('lightfinder5');
          localStorage.removeItem('lightfinder6Images');
          localStorage.removeItem('lightfinder6Budget');
          localStorage.removeItem('lightfinder710');
          localStorage.removeItem('lightfinder711');
          localStorage.removeItem('lightfinder79');
          navigate('/lightfinder8', { state: { resultData: result.data } });
        }, 2000);
      } else {
        setLoading(false);
        console.error('Error submitting the form:', result.message);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className={`h-full overflow-hidden relative min-h-screen bg-zinc-300 ${
              isModalOpen ? 'filter blur-sm' : ''
            }`}
          >
            <header className="relative z-10">
              <Navbargrey />
            </header>
            <div className="px-6 sm:px-26 lg:px-24 xl:px-56 pt-10 pb-4 font-light text-zinc-700">
              <img
                class="absolute right-0 w-96 md:w-1/2  ms-auto overflow-hidden top-1/2 left-1/2 translate-x-[0%] translate-y-[-50%] select-none"
                src={second_ellipes}
                alt="ellipes"
              />
              <div className="relative z-10">
                <div>
                  <div>
                    <p className="text-sm sm:text-lg md:text-lg">
                      9. &nbsp; How can we best connect with you?
                    </p>
                  </div>
                  <div className=" grid grid-cols-1 gap-y-2 w-fit sm:flex sm:flex-row sn:gap-2 md:gap-20 pt-4 sm:pt-8 sm:pl-8 text-xs sm:text-sm ">
                    <div
                      className={`${
                        selectContact === 0
                          ? 'bg-[#7246fd] text-zinc-100'
                          : 'bg-zinc-200 text-zinc-700'
                      } px-2 p-1 sm:p-2  sm:px-4 flex items-center justify-left rounded-sm shadow-md shadow-lightfinder duration-300 cursor-pointer`}
                      onClick={() => handleClickContact(0, 'Via e-mail')}
                    >
                      <p>E-mail</p>
                    </div>
                    <div
                      className={`${
                        selectContact === 1
                          ? 'bg-[#7246fd] text-zinc-100'
                          : 'bg-zinc-200 text-zinc-700'
                      } px-2 p-1 sm:p-2  sm:px-4 flex items-center justify-left rounded-sm shadow-md shadow-lightfinder duration-300 cursor-pointer`}
                      onClick={() => handleClickContact(1, 'Schedule a call')}
                    >
                      <p>E-meeting</p>
                    </div>
                    <div
                      className={`${
                        selectContact === 2
                          ? 'bg-[#7246fd] text-zinc-100'
                          : 'bg-zinc-200 text-zinc-700'
                      } px-2 p-1 sm:p-2  sm:px-4 flex items-center justify-left rounded-sm shadow-md shadow-lightfinder duration-300 cursor-pointer`}
                      onClick={() => handleClickContact(2, 'Fix a meeting')}
                    >
                      <p>Meet over coffee</p>
                    </div>
                  </div>
                </div>

                <div className="pt-8 sm:pt-16">
                  <p className="text-sm sm:text-lg ">
                    10. &nbsp; Share your thoughts.
                  </p>
                  <div className="w-3/4 pt-2 sm:pt-8 sm:pl-8">
                    <textarea
                      className="w-full sm:h-32 p-4 text-xs border border-zinc-300 outline-none bg-zinc-200 shadow shadow-lightfinder duration-300"
                      placeholder="Write here (1000 words)"
                      value={text}
                      onChange={handleTextareaChange}
                    />
                    <p style={{ color: 'red' }}>
                      {text.trim().split(/\s+/).length > wordLimit &&
                        'Word limit reached!'}
                    </p>
                  </div>
                </div>

                <div className="pt-8 sm:pt-14">
                  <p className="text-sm sm:text-lg md:text-lg">
                    11. &nbsp; Verify email
                  </p>
                  <div className="py-2 sm:py-4 sm:pl-8">
                    <input
                      className="border border-zinc-300 outline-none bg-zinc-200 px-2 w-3/5 lg:w-1/3 py-3 text-xs shadow shadow-lightfinder duration-300"
                      type="email"
                      name="email"
                      id=""
                      placeholder="hello@email.com"
                      value={email}
                      onChange={(e) => handleEmail(e.target.value)}
                      required
                    />
                    <br />
                    {emailValidation === true && (
                      <span class="text-red-500"> Verify your email.</span>
                    )}
                  </div>
                </div>
              </div>

              {/* ===== next & back button ===== */}
              <div className="pt-8 sm:pt-14 max-w-4xl grid grid-cols-1 gap-y-2 lg:grid-cols-2 justify-between items-center text-zinc-700">
                {/* <BackandNext backLink="/lightfinder6" nextLink="/lightfinder8" /> */}
                <div
                  className="flex gap-5 cursor-pointer font-light"
                  style={{ zIndex: 999999 }}
                >
                  <Link to={'/lightfinder6'}>
                    <div className="border border-zinc-500 px-4 sm:px-6 py-1 shadow-lightfinder duration-300">
                      <button className="">Back</button>
                    </div>
                  </Link>
                  <div className="border border-zinc-500 px-4 sm:px-6 py-1 shadow-lightfinder duration-300">
                    <button className="" onClick={handleSubmitForm}>
                      Next
                    </button>
                  </div>
                </div>
                <ProgressBar currentPage={7} totalPages={7} />
              </div>
            </div>
          </div>
          <Dialog
            open={isModalOpen}
            size={'md'}
            handler={handleOpen}
            className="mx-auto my-auto custom-dialog rounded-sm"
          >
            <DialogHeader className="justify-between">
              <div>
                <Typography
                  className="font-light tracking-wide text-lg px-2 sm:px-4 text-[#27272A]"
                  variant="h5"
                  color="blue-gray"
                >
                  Email verification
                </Typography>
              </div>
              <IconButton
              className='text-[#27272A]'
                size="sm"
                variant="text"
                onClick={handleOpen}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </DialogHeader>
            <Card className="mx-auto w-full max-w-[24rem] verificationBox">
              <CardBody className="flex flex-col items-start gap-4">
                <Typography
                  className="-mb-2 text-[#27272A] font-light text-sm tracking-wide"
                  variant="h6"
                >
                  Code sent to email
                </Typography>
                <Input
                  className="shadow-inner"
                  type="number"
                  style={{
                    width: '150px',
                    height: '36px',
                    fontSize: '12px',
                    border: '1px #A1A1AA solid',
                    outline: 'none',
                    backgroundColor: '#E4E4E7',
                  }}
                  onChange={(e) => handleOtp(e.target.value)}
                  value={otpNumber}
                />

                {otpError != '' && <span class="text-red-500">{otpError}</span>}
              </CardBody>
              <CardFooter className="pt-8">
                <Button
                  variant="gradient"
                  className={`verificationOtp py-3 px-3 text-sm tracking-wide rounded-sm font-normal w-full `}
                  style={{
                    backgroundColor: otpNumber ? '#6B46C1' : 'grey',
                    boxShadow: otpNumber
                      ? '0 4px 6px rgba(0, 0, 0, 0.1)'
                      : 'none',
                    color: otpNumber ? 'white' : 'black',
                  }}
                  onClick={handleOtpVerify}
                  disabled={!otpNumber}
                >
                  <span className="font-normal text-base capitalize">
                    Verify
                  </span>
                </Button>
              </CardFooter>
            </Card>
          </Dialog>
        </>
      )}
      <ToastContainer position="top-center" />
    </>
  );
};

export default LightFinder5;
