import { useState, useEffect } from 'react';
import React from 'react';
import Navbar from '../../../Components/Navbar/Navbar';
import Footer from '../../../Components/Footer/Footer';
import Ellipsepurpule from '../../../images/Ellipse14.webp';
import Contactpop from '../../../Components/Contactpopup/Contactpopup';

const Whatwebelieve = () => {
  const [textone, setTextone] = useState(false);
  const [texttwo, setTexttwo] = useState(false);
  const [textthree, setTextthree] = useState(false);

  const handleButtonClick = (section) => {
    setTextone(false);
    setTexttwo(false);
    setTextthree(false);

    switch (section) {
      case 'textone':
        setTextone(!textone);
        break;
      case 'texttwo':
        setTexttwo(!texttwo);
        break;
      case 'textthree':
        setTextthree(!textthree);
        break;
      default:
        break;
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };

  return (
    <>
      <section className={blurBackground ? 'filter blur-xl' : ''}>
        <div className="what-we-belive bg-[#000000]">
          <header className="relative z-10">
            <Navbar />
          </header>
          <div className="main-body-section">
            <div className="">
              <img
                className="absolute ms-auto top-0 flex items-center justify-center"
                src={Ellipsepurpule}
                alt=""
              />
            </div>

            <div className="relative top-section px-6 sm:px-26 lg:pl-24 xl:pl-56 py-32 sm:py-48  text-zinc-400">
              <div className="font-extralight text-4xl sm:text-5xl sm:mt-20 md:text-7xl md:mt-24 lg:text-8xl lg:mt-16 xl:text-9xl">
                <p>What we believe ?</p>
              </div>
              <div className="flex flex-col font-light justify-center w-3/4 sm:max-w-3xl text-sm mt-8 lg:mt-20 md:text-lg ">
                <p>
                  At Kaash, we believe in the magic of light. We envision a
                  world glowing from humancentric sustainable lighting
                  solutions, where every space is transformed into a haven of
                  health and wellbeing in harmony with nature.
                  <br />
                  <br />
                  We are driven by a passion for light, to create spaces that
                  inspire, energize, and connect. From heritage buildings to
                  modern offices we fuse intelligent technology with efficient
                  solutions for a greener planet!
                </p>
              </div>
            </div>

            <div className="relative text-zinc-400 font-extralight center-body flex flex-col gap-y-16 px-6 sm:px-26 lg:px-24 xl:px-56">
              <div>
                <div className="flex flex-row gap-8  text-3xl sm:text-4xl lg:text-5xl xl:text-7xl ">
                  <div>
                    <button onClick={() => handleButtonClick('textone')}>
                      {textone === true ? '-' : '+'}
                    </button>
                  </div>
                  <div>
                    <p>Human centric design</p>
                  </div>
                </div>
                <div className=" text-xs px-12 mt-4 md:text-sm lg:px-16 lg:my-16 lg:text-base xl:px-24 2xl:text-xl max-w-4xl">
                  {textone && (
                    <p>
                      Beyond daylight, light exist to support people and their
                      space function. We design with light for people and their
                      architecture to create spaces that evoke emotions.
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="flex flex-row gap-8 text-3xl sm:text-4xl lg:text-5xl xl:text-7xl ">
                  <div>
                    <button onClick={() => handleButtonClick('texttwo')}>
                      {texttwo === true ? '-' : '+'}
                    </button>
                  </div>
                  <div>
                    <p>Technology</p>
                  </div>
                </div>
                <div className="text-xs px-12 mt-4 md:text-sm lg:px-16 lg:my-16 lg:text-base xl:px-24 xl:mt-10 2xl:text-xl max-w-4xl">
                  {texttwo && (
                    <p>
                      Staying at the forefront of innovations, we maximimize the
                      efficient use of technology to intelligent and safe
                      infrastructure that adapt to light needs.
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="flex flex-row gap-8  text-3xl sm:text-4xl lg:text-5xl xl:text-7xl ">
                  <div>
                    <button onClick={() => handleButtonClick('textthree')}>
                      {textthree === true ? '-' : '+'}
                    </button>
                  </div>
                  <div>
                    <p>Sustainability</p>
                  </div>
                </div>
                <div className=" text-xs px-12 mt-4 md:text-sm lg:px-16 lg:my-16 lg:text-base xl:px-24 xl:mt-10 2xl:text-xl max-w-4xl">
                  {textthree && (
                    <p>
                      Light is energy, every use generate emissions. At Kaash,
                      we are committed to sustinable planet by minimizing light
                      carbon footprint and its ecological imapct. With efficient
                      design and smart solutions we optimize energy consumption
                      that reduce light waste.
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/*============contact us button==========*/}
            <div className="relative px-10 my-24 sm:px-26  lg:my-60 lg:px-24 xl:px-56 2xl:px-56 ">
              <p className="text-zinc-400 font-extralight text-lg sm:text-2xl md:text-3xl">
                Wish to talk about light.{' '}
              </p>
              <button
                className=" px-4 py-2 my-44  bg-transparent text-zinc-400 border border-zinc-400 text-xl mt-10 font-light btn-shadow-white"
                onClick={togglePopup}
              >
                Talk to us
              </button>
            </div>
          </div>
          <div className="">
            <Footer />
          </div>
        </div>
      </section>

      <div className="contactpop-section">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
    </>
  );
};
export default Whatwebelieve;
