import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import download from '../../images/downloadarrow.webp';
import ellpise_lightfinder_report_bg from '../../images/ellpise_lightfinder_report_bg.webp';
import Navbargrey from '../../Components/Navbargrey/Navbargrey';
import { getDecryptedItem } from '../../Components/CommonLibrary/EncryptDecrypt';

const LightFinder9 = () => {
  const lightfinderImages = getDecryptedItem('lightfinder6Images');
  const downloadLink = getDecryptedItem('dw');

  const handleDownload = () => {
    // Open a new window or tab with the download link
    window.open(process.env.REACT_APP_API_URL+downloadLink, '_blank');
  };

  return (
    <>
      <div className="h-full overflow-hidden min-h-screen bg-zinc-300">
        <header className="relative z-10">
          <Navbargrey />
        </header>
        <img
          src={ellpise_lightfinder_report_bg}
          className="absolute top-0 ms-auto"
          alt=""
        />
        <div className="relative pt-40 pb-24 flex flex-col items-center justify-center gap-5 font-light">
          <div>
            <h1 className="text-[#7246FD] text-xl sm:text-4xl text-center  font-extralight max-w-2xl">
              Thank You for your request! We are excited to find you the perfect light. Our experts will reach out soon.
            </h1>
          </div>
          <div className="flex justify-center">
            <p className="text-base py-6 w-3/5 text-center text-zinc-700">
              
            </p>
          </div>
          <div>
            <button
              className="flex text-zinc-100 items-center gap-5 bg-[#7246FD] px-4 py-2 shadow-md rounded-sm shadow-lightfinder duration-200 font-light"
              onClick={handleDownload}
            >
              Download report <img src={download} alt="" />
            </button>
          </div>
          <div className="w-3/4 mx-auto border-t-2 border-zinc-500 my-10"></div>
          <div className="flex justify-center">
            <p className="px-10 md:px-0 text-center text-zinc-700">
              Meanwhile, hit the button below for some inspirations!
            </p>
          </div>
          <div>
            <Link to="/ourprojects">
              <button className="text-zinc-200 bg-[#7246FD] px-10 py-2 shadow-md rounded-sm shadow-lightfinder duration-200">
                Projects
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default LightFinder9;
