import React from 'react';
import { useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { RxChatBubble } from 'react-icons/rx';
import { RxEnvelopeOpen } from 'react-icons/rx';
import { RxEnvelopeClosed } from 'react-icons/rx';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Contactpopup.css'
import { countriesList } from './CountryList';


const Contactpopup = ({ onClose }) => {
 

  const [formData, setFormData] = useState({
    username: '',
    address: '',
    country: '',
    postcode: '',
    phone: '',
    email: '',
    message: '',
    contact_me: false,
    phone_code: '',
  });
  const [mobileError, setMobileError] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'country') {
      const selectedCountry = countriesList.find(
        (country) => country.name === value
      );

      setFormData((prevData) => ({
        ...prevData,
        country: value,
        phone_code: selectedCountry ? selectedCountry.PhoneCode : '',
      }));
    } else if (name === 'phone') {
      const phoneRegex = /^\+?[0-9\s]+$/;
      setFormData((prevData) => ({
        ...prevData,
        phone: value,
      }));
      setMobileError(!phoneRegex.test(value) ? 'Invalid phone number.' : '');
    } else if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      setFormData((prevData) => ({
        ...prevData,
        email: value,
      }));
      setEmailError(!emailRegex.test(value) ? 'Invalid email address.' : '');
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    try {
      // Send form data to the PHP script contactForm.php API
      const response = await fetch(`${process.env.REACT_APP_API_URL}contact_us`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.status == 'success') {
        toast.success('Sumbission success!');
      } else {
        console.error('Error submitting the form:', result.message);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
    onClose();
    setFormData({
      username: '',
      address: '',
      country: '',
      postcode: '',
      phone: '',
      email: '',
      message: '',
      contact_me: false,
    });
  };

  return (
    <>
      <div className="min-w-[94vw] md:max-w-4xl w-full md:min-w-[300px] px-4 sm:px-6  relative border border-zinc-400 text-zinc-400 bg-zinc-700 shadow-2xl font-light my-16 sm:my-24 mx-auto">
        <div className="flex justify-end">
          <button
            className="text-2xl sm:text-3xl text-gray-300 hover:text-red-400 mt-4 sm:mt-8"
            onClick={onClose}
          >
            <RxCross2 />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <h1 className="text-2xl sm:text-3xl md:text-4xl text-center text-gray-100 tracking-wide">
            Let’s talk about Light
          </h1>
          <p className="text-center text-gray-400 text-sm sm:text-base md:text-lg">
            Write to us, and our team of experts will connect with you soon.
          </p>

          <div className="space-y-4 sm:space-y-6">
            <input
              className="w-full px-3 py-2 sm:py-3 bg-transparent border border-gray-600 text-sm sm:text-base text-gray-300 placeholder-gray-500"
              type="text"
              name="username"
              placeholder="Name"
              value={formData.username}
              onChange={handleInputChange}
            />
            <input
              className="w-full px-3 py-2 sm:py-3 bg-transparent border border-gray-600 text-sm sm:text-base text-gray-300 placeholder-gray-500"
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleInputChange}
            />
            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="w-full px-3 py-2 sm:py-3 bg-transparent border border-gray-600 text-sm sm:text-base text-gray-300 placeholder-gray-500"
              >
                <option value="" className="bg-gray-800">
                  Select Country
                </option>
                {countriesList.map((country) => (
                  <option
                    key={country.id}
                    value={country.name}
                    className="bg-gray-800"
                  >
                    {country.name}
                  </option>
                ))}
              </select>
              <input
                className="w-full px-3 py-2 sm:py-3 bg-transparent border border-gray-600 text-sm sm:text-base text-gray-300 placeholder-gray-500"
                type="text"
                name="postcode"
                placeholder="Postcode"
                value={formData.postcode}
                onChange={handleInputChange}
              />
            </div>
            <input
              className="w-full px-3 py-2 sm:py-3 bg-transparent border border-gray-600 text-sm sm:text-base text-gray-300 placeholder-gray-500"
              type="tel"
              inputMode="tel"
              name="phone"
              placeholder="Contact Phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
            <input
              className="w-full px-3 py-2 sm:py-3 bg-transparent border border-gray-600 text-sm sm:text-base text-gray-300 placeholder-gray-500"
              type="email"
              name="email"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <textarea
              className="w-full px-3 py-2 sm:py-3 bg-transparent border border-gray-600 text-sm sm:text-base text-gray-300 placeholder-gray-500"
              name="message"
              placeholder="Tell us about your project..."
              rows="4"
              value={formData.message}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex items-center space-x-3">
            <input
              type="checkbox"
              name="contact_me"
              checked={formData.contact_me}
              onChange={handleInputChange}
              className="w-5 h-5 rounded-md text-orange-600 focus:ring-orange-600 cursor-pointer"
            />
            <p className="text-gray-400 text-sm cursor-pointer">Get in touch</p>
          </div>

          <button
            type="submit"
            disabled={mobileError || emailError}
            className={`${
              mobileError || emailError
                ? "w-full py-2 bg-orange-600 text-gray-100 text-lg md:text-xl hover:bg-orange-700 transition duration-300 ease-in-out cursor-not-allowed"
                : "w-full py-2 bg-orange-600 text-gray-100 text-lg md:text-xl hover:bg-orange-700 transition duration-300 ease-in-out"
            }`}
          >
            Submit
          </button>
        </form>
        <div className="flex flex-col sm:flex-row justify-center items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-6 pt-2 pb-4 text-xs text-gray-400">
          <a
            href="tel:+91 9102745605"
            className="hover:text-orange-400 transition-colors"
          >
            <RxChatBubble className="inline-block mr-2 text-sm" /> +352 691 566
            820
          </a>
          <a
            href="mailto:moien@kaash.eu"
            className="hover:text-orange-400 transition-colors"
          >
            <RxEnvelopeClosed className="inline-block mr-2" /> moien@kaash.eu
          </a>
          <a
            href="https://maps.app.goo.gl/oC6vTUVp9TGGXy6F6"
            target="_blank"
            className="hover:text-orange-400 transition-colors"
          >
            <RxEnvelopeOpen className="inline-block mr-2" /> 41a rue des
            romains, L8041 Luxembourg
          </a>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Contactpopup;
