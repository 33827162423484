import React from 'react';
import { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Inspiringstorieschurch from '../../images/Inspiringstorieschurch.webp';
import InspiringstoriesLightAI from '../../images/InspiringstoriesLightAI.webp';
import Inspiringstoriesvictorian from '../../images/Inspiringstoriesvictorian.webp';
import Inspiringstoriesflyingsaucer from '../../images/Inspiringstoriesflyingsaucer.webp';
import InspiringstoriesMuseum from '../../images/InspiringstoriesMuseum.webp';
import Inspiringstoriestourism from '../../images/Inspiringstoriestourism.webp';
import Inspiringstoriespollination from '../../images/Inspiringstoriespollination.webp';
import hallthelocomotived_homepage_img from '../../images/project_hallthelocomotives_homepage_img.webp';
import project_parcdemerl_homepage_img from '../../images/project_parcdemerl_homepage_img.webp';
import project_kolla_homepage_img from '../../images/project_kolla_homepage_img.webp';
import blog8 from '../../images/blog-8.webp';
import blog7 from '../../images/blog-7.webp';
import project_skatepark_homepage_img from '../../images/project_skatepark_homepage_img.webp';
import project_lachambre_homepage_img from '../../images/project_lachambre_homepage_img.webp';
import project_KNMA_homepage_img from '../../images/project_KNMA_homepage_img.webp';
import popuptriennial1 from '../../images/popup-triennial1.webp';
import blog1 from '../../images/blog-1.webp';
import project_arc_studio_homepage_img from '../../images/project_arc_studio_homepage_img.webp';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './MyCarousel.css';

import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from 'swiper/modules';

const MyCarousel = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Swiper
      cssMode={true}
      navigation={false}
      loop={true}
      autoplay={{ delay: 8000, disableOnInteraction: true }}
      // pagination={true}
      // mousewheel={true}
      keyboard={true}
      modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div class="container mx-auto px-4">
          <div class="flex md:flex-wrap">
            <div class="flex w-1/2 flex-wrap">
              <div
                class="w-1/2 p-1 md:p-2"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    // className={`block h-full w-full object-cover object-center rounded-sm ${
                    //   isHovered ? 'grayscale-off-hover' : 'grayscale-on-hover'
                    // }`}
                    src={project_arc_studio_homepage_img}
                  />
                </a>
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={Inspiringstorieschurch}
                  />
                </a>
              </div>
              <div class="w-full p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={hallthelocomotived_homepage_img}
                  />
                </a>
              </div>
            </div>
            <div class="flex w-1/2 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={project_parcdemerl_homepage_img}
                  />
                </a>
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <a href="/articles">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={blog8}
                  />
                </a>
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <a href="/articles">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={blog7}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="container mx-auto px-4">
          <div class="-m-1 flex flex-wrap md:-m-2">
            <div class="flex w-1/2 flex-wrap">
              <div class="w-1/2 p-1 md:p-2">
                <a href="/articles">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={InspiringstoriesLightAI}
                  />
                </a>
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <a href="/articles">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={Inspiringstoriesvictorian}
                  />
                </a>
              </div>
              <div class="w-full p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={project_kolla_homepage_img}
                  />
                </a>
              </div>
            </div>
            <div class="flex w-1/2 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={Inspiringstoriesflyingsaucer}
                  />
                </a>
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={project_skatepark_homepage_img}
                  />
                </a>
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={project_lachambre_homepage_img}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="container mx-auto  px-4">
          <div class="-m-1 flex flex-wrap md:-m-2">
            <div class="flex w-1/2 flex-wrap">
              <div class="w-1/2 p-1 md:p-2">
                <a href="/articles">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={Inspiringstoriespollination}
                  />
                </a>
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <a href="/articles">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={Inspiringstoriestourism}
                  />
                </a>
              </div>
              <div class="w-full p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={InspiringstoriesMuseum}
                  />
                </a>
              </div>
            </div>
            <div class="flex w-1/2 flex-wrap">
              <div class="w-full p-1 md:p-2">
                <a href="/articles">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={blog1}
                  />
                </a>
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={project_KNMA_homepage_img}
                  />
                </a>
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <a href="/ourprojects">
                  <img
                    alt="gallery"
                    class="block h-full w-full  object-cover object-center rounded-sm grayscale-on-hover"
                    src={popuptriennial1}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MyCarousel;
