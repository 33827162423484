import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Weatkaash1 from '../../images/Weatkaash1.webp';
import WeatKaash2 from '../../images/WeatKaash2.webp';
import arrow from '../../images/Arrowwhite45.webp';
import Ellipselightstory from '../../images/Ellipselightstory.webp';
import MyCarousel from '../../Components/MyCarousel/MyCarousel';
import Contactpop from '../../Components/Contactpopup/Contactpopup';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { neonCursor } from 'threejs-toys';
import './Home.css';
import { MdOutlineArrowOutward } from 'react-icons/md';

export const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };

  useEffect(() => {
    const initNeonCursor = () => {
      neonCursor({
        el: document.getElementById('neon'),
        shaderPoints: 20,
        curvePoints: 120,
        curveLerp: 0.5,
        radius1: 5,
        radius2: 30,
        velocityTreshold: 0.1,
        velocityTreshold: 0.1,
        sleepRadiusX: 100,
        sleepRadiusY: 100,
        sleepTimeCoefX: 0.0025,
        sleepTimeCoefY: 0.0025,
      });
    };

    initNeonCursor();
  }, []);
  return (
    <>
      <div className={blurBackground ? 'filter blur-sm' : ''}>
        <div className="absolute w-full z-40">
          <Navbar />
        </div>
        <div className="relative">
          <div id="neon">
            <div className="py-96 md:px-48 px-12  flex justify-center items-center">
              <p className="font-extralight text-xl sm:text-2xl text-zinc-400 py-24">
                light defines spatial experience as it falls and reflects
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="px-6 sm:px-26  lg:px-40 2xl:px-56 3xl-72 py-40 bg-zinc-950 grid md:grid-cols-2 md:gap-10 lg:gap-20 gap-12 justify-center ">
            <div>
              <p className="font-extralight text-zinc-400 text-4xl  md:text-6xl 2xl:text-8xl py-2">
                We at Kaash are fascinated about Light
              </p>
              <LazyLoadImage
                alt="Weatkaash"
                effect="blur"
                className="h-full w-full object-center object-cover"
                src={Weatkaash1}
              />
            </div>
            <div className="lg:pt-60">
              <div className="pb-16">
                <p className="text-sm sm:text-lg pb-3 text-zinc-400 font-extralight max-w-xl ">
                  The endless possibilities of light, inspire our creativity.
                  With light we create comfortable spaces, inspiring
                  institutions and playful environments. Aspiring to build
                  healthier communities with greener planet, we believe in the
                  sustainable human centric lighting.
                </p>
                <LazyLoadImage
                  alt="Weatkaash"
                  effect="blur"
                  className="h-full w-full object-center object-cover"
                  src={WeatKaash2}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="red-card py-12 sm:py-16 flex justify-center items-center">
          <div className="items-center">
            {' '}
            <h2 className="mb-2 text-center md:text-5xl text-2xl font-extralight text-zinc-200">
              Find your light
            </h2>
            <div className="md:px-40 p-4 sm:p-8">
              <p className="mb-2 max-w-xl text-center md:text-lg text-sm font-light text-zinc-200">
                {' '}
                Looking for lighting solutions for your home, office, or any
                project? Use our tool to identify your needs, and let our
                experts craft the perfect solution for you.
              </p>
            </div>
            <div className="text-center">
              <Link to="/lightfinder">
                <button className="border border-zinc-300 py-1 px-12 md:text-2xl text-zinc-200 font-extralight duration-300 btn-shadow-white">
                  Light Finder
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="px-6 md:px-16 py-8 lg:px-36 lg:py-24 bg-zinc-400">
          <h1 className="text-left font-extralight text-4xl md:text-7xl lg:text-9xl md:pb-24 lg:pb-20 py-12 pl-2">
            Inspiring Stories
          </h1>
          <MyCarousel />
        </div>
        <div className="yellow-card py-12 sm:py-16 flex justify-center items-center">
          <div className="">
            {' '}
            <h2 className="mb-2 text-center md:text-5xl text-2xl font-extralight text-zinc-800">
              Calculate light carbon
            </h2>
            <div className="md:px-40 p-4 sm:p-8">
              <p className="mb-2 max-w-xl text-center md:text-lg text-sm font-light text-zinc-800">
                {' '}
                Calculate the carbon emissions of your lighting. Know your
                savings potential with cost estimate and return of investment.
              </p>
            </div>
            <div className="text-center">
              <Link to="/carbonemission">
                <button className="border border-zinc-800 py-1 px-6 text-zinc-800 font-extralight md:text-2xl duration-300 btn-shadow ">
                  Carbon Calculator
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-[#000000] relative py-20 sm:py-48 pb-64">
          <img class="ms-auto w-64 md:w-1/3" src={Ellipselightstory} />
          <div
            className="absolute bottom-0 left-0 right-0 top-0
            overflow-hidden bg-fixed"
          >
            <div className="pt-28 sm:pt-48 xl:pt-64 xl:pl-40 md:pl-24 pl-5 w-2/3 md:w-3/5 ">
              <h2 className="font-extralight text-3xl/tight md:text-6xl xl:text-7xl/tight 2xl:text-8xl/tight text-zinc-400 pl-4 sm:pl-6">
                Let’s create your story with light
              </h2>
              <div className="flex justify-left md:justify-start items-left">
                <div className=" pt-8 lg:pt-12 text-zinc-400 text-6xl/tight font-extralight sm:text-[10rem]/tight">
                  <MdOutlineArrowOutward />
                </div>
              </div>
              <div className="flex justify-left text-center md:text-start lg:py-12 pl-4 sm:pl-6">
                {' '}
                <button
                  onClick={togglePopup}
                  className="px-4 py-1 bg-transparent text-zinc-400  border border-zinc-400  text-xl mt-10 font-light btn-shadow-white"
                >
                  Contact us
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Footer />
        </div>
      </div>
      <div className="contactpop-section">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
    </>
  );
};

export default Home;
