import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../Whatwedo/Whatwedo.css';
import Navbar from '../../../Components/Navbar/Navbar';
import Footer from '../../../Components/Footer/Footer';
import EllipseRed from '../../../images/EllipseRed.webp';
import 'aos/dist/aos.css';
// import { LiaArrowRightSolid } from 'react-icons/fa';
import { LiaArrowRightSolid } from "react-icons/lia";
import { LiaArrowLeftSolid } from "react-icons/lia";
// import { LiaArrowLeftSolid } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import Contactpop from '../../../Components/Contactpopup/Contactpopup';

function Whatwedo() {
  // ======== pop up info are here...................
  const [infopopup, setInfoPopup] = useState({
    heading: '',
    paragraph: '',
    textheading: '',
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    text5: '',
  });

  const [ispopupOpen, setispopupOpen] = useState(false);

  const [blurBackground, setBlurBackground] = useState(false);

  const openPopup = (
    heading,
    paragraph,
    textheading,
    text1,
    text2,
    text3,
    text4,
    text5
  ) => {
    setInfoPopup({
      heading,
      paragraph,
      textheading,
      text1,
      text2,
      text3,
      text4,
      text5,
    });
    setispopupOpen(true);
    setBlurBackground(!blurBackground);
  };

  const closePopup = () => {
    setispopupOpen(false);
    setBlurBackground(!blurBackground);
  };

  const popupDetails = {
    humancenteric: {
      heading: 'Human-centric design',
      paragraph:
        'Creating lighting solutions specific to your needs. With user centred design approach we optimise space use, promote user well-being, and enhance overall experience with light.',
      textheading: 'Deliverables',
      text1: 'Light visuals ',
      text2: 'Light study',
      text3: 'Tender document',
      text4: 'Construction drawings',
      text5: '',
    },
    sustainable_process: {
      heading: 'Sustainable spaces',
      paragraph:
        'Improving efficiency of your infrastructure, either a building or a city. With detailed light study we implement sustainable solutions to improve your energy efficiency and reduce carbon footprint. ',
      textheading: 'Deliverables',
      text1: 'Light audit ',
      text2: 'Energy report',
      text3: 'Carbon reduction',
      text4: 'Project management',
      text5: 'Before & After assessment',
    },
    light_engineering: {
      heading: 'Light engineering',
      paragraph:
        'Light is scientific in its origin, a good lighting tool should be a well engineered product to deliver best performance. Through light engineering we identify the ideal lighting solution for your project to create the perfect lighting.',
      textheading: 'Deliverables',
      text1: 'Led assessment ',
      text2: 'Product mock-ups',
      text3: 'Light programming',
      text4: 'Projection mapping',
      text5: 'Scenography',
    },
  };

  //========= arrow text info are here.......................
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const ShowFirst = () => {
    setFirst(!first);
  };
  const showSecond = () => {
    setSecond(!second);
  };
  const showThird = () => {
    setThird(!third);
  };

  const handleButtonClick = (section) => {
    setFirst(false);
    setSecond(false);
    setThird(false);

    // Toggle the clicked section
    switch (section) {
      case 'first':
        setFirst(!first);
        break;
      case 'second':
        setSecond(!second);
        break;
      case 'third':
        setThird(!third);
        break;
      default:
        break;
    }
  };

  // ======== contact popup.................................
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };

  return (
    <>
      <div className={blurBackground ? 'filter blur-lg bg-black' : 'bg-black'}>
        {/* ======== Navbar ======== */}
        <header className="relative z-10">
          <Navbar />
        </header>
        <div className="relative">
          {/* ======== Red Ellpise ======== */}
          <img
            class="absolute right-0 ms-auto w-10/12 md:w-7/12"
            src={EllipseRed}
          />
          <div className="relative py-24 2xl:py-32 ">
            {/* ======== What we do Top section ======== */}
            <div className="grid gap-y-9 md:gap-y-14 xl:gap-y-24 py-32 px-6 sm:px-26 lg:px-24 xl:px-56">
              <h2 className="text-zinc-400 font-extralight text-4xl sm:text-5xl md:text-7xl lg:text-8xl xl:text-9xl">
                What we do?
              </h2>
              <h4 className="text-sm sm:text-base md:mt-22 font-light md:text-lg text-zinc-400 max-w-sm mr-16 md:max-w-3xl">
                As a premier professional lighting design enterprise, we specialize in utilizing light to curate spaces that evoke positive experiences. 
                Our expertise extends from crafting comfortable residential environments to inspiring institutional settings and dynamic recreational spaces. 
                Committed to sustainability and human-centric design principles, we collaborate closely with our clients to deliver tailored lighting solutions 
                that meet their unique project requirements.<br /><br />

                Our portfolio encompasses a diverse range of projects, spanning from heritage buildings to cutting-edge corporate offices. 
                By seamlessly integrating intelligent technologies with energy-efficient solutions, we strive to contribute to a more environmentally 
                sustainable future while enhancing the visual appeal and functionality of every space we illuminate. 
              </h4>
            </div>

            {/* ======== Our Services Setion====== */}
            <div className="py-44 2xl:py-96 text-zinc-800">
              <div className="px-6 sm:px-26 lg:px-24 xl:px-56 font-light text-zinc-400 text-2xl lg:text-4xl ">
                Our services
              </div>
              {/* == Human Centric == */}
              <div className="flex flex-col gap-8">
                <div className="bg-zinc-400 mt-8 sm:mt-16 xl:py-9 2xl:py-6 md:py-7 py-3 flex flex-row items-center text-lg sm:text-2xl lg:text-4xl  px-6 sm:px-26 lg:px-24 xl:px-56 ">
                  <span className="font-light pr-12 sm:pr-40 lg:pr-48 2xl:pr-64">Human centric design</span>
                  <div>
                    <button class="text-zinc- text-3xl sm:text-5xl xl:text-6xl"
                      onClick={() => {
                        openPopup(
                          popupDetails.humancenteric.heading,
                          popupDetails.humancenteric.paragraph,
                          popupDetails.humancenteric.textheading,
                          popupDetails.humancenteric.text1,
                          popupDetails.humancenteric.text2,
                          popupDetails.humancenteric.text3,
                          popupDetails.humancenteric.text4,
                          popupDetails.humancenteric.text5
                        );
                      }}
                    >
                      <LiaArrowRightSolid />
                    </button>
                  </div>
                </div>
                {/* == Sustainable Process == */}
                <div className="bg-zinc-400 sm:mt-4 xl:py-9 2xl:py-6 md:py-7 py-3 flex flex-row items-center text-lg sm:text-2xl lg:text-4xl  px-6 sm:px-26 lg:px-24 xl:px-56">
                  <span className="font-light pr-12 sm:pr-40 lg:pr-48 2xl:pr-64">Sustainable spaces</span>
                  <button class="text-zinc- text-3xl sm:text-5xl xl:text-6xl"
                    onClick={() => {
                      openPopup(
                        popupDetails.sustainable_process.heading,
                        popupDetails.sustainable_process.paragraph,
                        popupDetails.sustainable_process.textheading,
                        popupDetails.sustainable_process.text1,
                        popupDetails.sustainable_process.text2,
                        popupDetails.sustainable_process.text3,
                        popupDetails.sustainable_process.text4,
                        popupDetails.sustainable_process.text5
                      );
                    }}
                  >
                    <LiaArrowRightSolid />
                  </button>
                </div>
                {/* == Light Engineering == */}
                <div className="bg-zinc-400 sm:mt-4 xl:py-9 2xl:py-6 md:py-7 py-3 flex flex-row items-center text-lg sm:text-2xl lg:text-4xl  px-6 sm:px-26 lg:px-24 xl:px-56">
                  <span className="font-light pr-12 sm:pr-40 lg:pr-48 2xl:pr-64">Light Engineering</span>
                  <button class="text-zinc- text-3xl sm:text-5xl xl:text-6xl"
                    onClick={() => {
                      openPopup(
                        popupDetails.light_engineering.heading,
                        popupDetails.light_engineering.paragraph,
                        popupDetails.light_engineering.textheading,
                        popupDetails.light_engineering.text1,
                        popupDetails.light_engineering.text2,
                        popupDetails.light_engineering.text3,
                        popupDetails.light_engineering.text4,
                        popupDetails.light_engineering.text5
                      );
                    }}
                  >
                    <LiaArrowRightSolid />
                  </button>
                </div>
              </div>
            </div>

            {/* ======== light project ======== */}
            <div className="">
              <div className="px-6 sm:px-26 lg:px-24 xl:px-56 font-light text-zinc-400 text-lg lg:text-3xl/normal ">
                <p>
                  Would you like to collaborate ? <br />
                  Check out project pricing.
                </p>
              </div>
              <div className="text-zinc-800 ">
                <div className="bg-zinc-400 mt-8 sm:mt-16 xl:py-9 2xl:py-6 md:py-7 py-4 flex flex-row items-center  px-6 sm:px-26 lg:px-24 xl:px-56 text-lg sm:text-2xl  lg:text-4xl ">
                  <span className="font-light pr-12 sm:pr-40 lg:pr-48 2xl:pr-64">Individual project</span>
                  <button class=" text-3xl sm:text-5xl xl:text-6xl"  onClick={() => handleButtonClick('first')}>
                    {first === true ? <LiaArrowLeftSolid /> : <LiaArrowRightSolid />}
                  </button>
                  {first && <div>9 €/m2</div>}
                </div>
                <div className="bg-zinc-400 mt-8 sm:mt-12 xl:py-9 2xl:py-6 md:py-7 py-4 flex flex-row items-center px-6 sm:px-26 lg:px-24 xl:px-56 text-lg sm:text-2xl lg:text-4xl  ">
                  <span className="font-light pr-12 sm:pr-40 lg:pr-48 2xl:pr-64">
                    Public & Private company
                  </span>
                  <button class=" text-3xl sm:text-5xl xl:text-6xl"  onClick={() => handleButtonClick('second')}>
                    {second === true ? <LiaArrowLeftSolid /> : <LiaArrowRightSolid />}
                  </button>
                  {second && <div> 29 €/m2</div>}
                </div>
                <div className="font-light bg-zinc-400 mt-8 sm:mt-12 xl:py-9 2xl:py-6 md:py-7 py-4 flex flex-row items-center  px-6 sm:px-26 lg:px-24 xl:px-56 2xl:pr-96 text-lg sm:text-2xl lg:text-4xl ">
                  <span className=" pr-12 sm:pr-40 lg:pr-48 2xl:pr-64 ">Architects & Engineers</span>
                  <button class=" text-3xl sm:text-5xl xl:text-6xl" onClick={() => handleButtonClick('third')}>
                    {third === true ? <LiaArrowLeftSolid /> : <LiaArrowRightSolid />}
                  </button>
                  {third && <div> 19 €/m2</div>}
                </div>
              </div>
            </div>

            {/* ======== Contact us button ======== */}
            <div className="px-6 sm:px-26 lg:px-28 xl:px-56 py-28 mt-8 md:mt-32 xl:mt-40 mb-20 md:mb-36 xl:mb-56">
              <h2 className="text-zinc-400 font-light text-lg lg:text-3xl/normal py-4">
              Would you like to discuss a project ?
            </h2>
              <button
                className="px-4 py-2 bg-transparent text-zinc-400  border border-zinc-400 mt-8 text-base md:text-xl xl:text-2xl font-light btn-shadow-white"
                onClick={togglePopup}
              >
                Talk to us
              </button>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
      {/* ======== All Popups are here ======== */}
      {ispopupOpen ? (
        <div className="bg-zinc-300 text-zinc-800 bg-opacity-40 backdrop-blur-2xl shadow-xl whatwedo-popup font-light max-w-4xl">
          <div className="px-6 sm:px-16 pt-6 sm:pt-10">
            <div className="relative flex flex-row text-xl sm:text-3xl  justify-between">
              <span className="">{infopopup.heading}</span>
              <button className="text-xl sm:text-4xl" onClick={closePopup}>
                <RxCross2 />
              </button>
            </div>
            <div className="relative flex flex-wrap my-4 mb-8 sm:py-8 gap-4 sm:gap-12  text-sm md:text-lg ">
              <div className="w-96   ">
                <p>{infopopup.paragraph}</p>
              </div>
              <div className="">
                <strong>{infopopup.textheading}</strong>
                <p>{infopopup.text1}</p>
                <p>{infopopup.text2}</p>
                <p>{infopopup.text3}</p>
                <p>{infopopup.text4}</p>
                <p>{infopopup.text5}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="contactpop-section">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
    </>
  );
}

export default Whatwedo;
