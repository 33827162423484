import React from 'react';
import { RxCross2 } from 'react-icons/rx';

const BenefitsPopupContent = ({ onClose }) => {
  return (
    <div>
      <div className="flex flex-row items-center justify-between px-2 py-2">
        <div className="flex items-center">
          <h2 className="text-zinc-700 text-base sm:text-2xl">
            Benefits of light upgardation
          </h2>
        </div>
        <div className="">
          <button className="text-xl sm:text-2xl" onClick={onClose}>
            <RxCross2 />
          </button>
        </div>
      </div>
      <div className="mx-2 text-zinc-700 font-light text-xs sm:text-base ">
        <div>
          <div className="pt-2 sm:pt-6 flex">
            <span>1.</span>
            <p className="ml-2">
              <b>Slash Energy Use by Up to 80% :</b> Ditch the old, drafty
              bulbs! Adaptive lighting solutions can be energy ninjas, saving
              you big on bills.
            </p>
          </div>
          <div className="pt-2  sm:pt-6 flex">
            <span>2.</span>
            <p className="ml-2">
              <b>Lighten Your Carbon Footprint :</b> Every watt saved makes a
              difference. Upgrading cuts emissions and helps you be a
              sustainability superhero.
            </p>
          </div>
          <div className="pt-2  sm:pt-6 flex">
            <span>3.</span>
            <p className="ml-2">
              <b>Experience Brilliance :</b> Modern lighting offers longer life
              and less maintenance, keeping your spaces bright without the
              hassle.
            </p>
          </div>
          <div className="pt-2 sm:pt-6  flex">
            <span>4.</span>
            <p className="ml-2">
              <b>Thrive in Positive Light :</b> Well-lit environments foster
              health, well-being, and productivity. Upgrade and create a space
              that energizes you.
            </p>
          </div>
          <div className="pt-2 sm:pt-6  flex">
            <span>5.</span>
            <p className="ml-2">
              <b>Boost Your Bottom Line :</b> Reduced energy bills free up cash
              for exciting new projects. It's a win-win for your wallet and the
              environment.
            </p>
          </div>
          <div className="pt-2 sm:pt-6  flex">
            <span>6.</span>
            <p className="ml-2">
              <b>Unlock Government Incentives :</b> Smart upgrades often come
              with financial rewards. Let the government help you illuminate the
              path to a brighter future.
            </p>
          </div>
          <div className="pt-2 sm:pt-6  flex">
            <span>7.</span>
            <p className="ml-2">
              <b>Champion Sustainability :</b> Embrace the UN's vision for a
              carbon-neutral world. Every upgrade is a step towards a brighter
              tomorrow.
            </p>
          </div>
          <div className="pt-2 sm:pt-6 sm:pb-4 flex">
            <span></span>
            <p className="ml-2"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsPopupContent;
