const popupDeatils = {
    Home: {
      heading: "Home",
      check1: `Living Room`,
      check2: "Bedroom",
      check3: "Kids room",
      check4: "Kids room",
      check5: "Kitchen",
      check6: "Bathroom",
      check7: "Toilet",
      check8: "Garden",
      check9: "Terrace / Balcony ",
      check10: "Driveway",
      check11: "Storage",
      check12: "Garage",
    },
  
    Office: {
      heading: "Office",
      check1: "Reception",
      check2: "Corridor",
      check3: "Open Office",
      check4: "Cabin",
      check5: "Meeting room",
      check6: "Conference room",
      check7: "Breakout",
      check8: "Cafe",
      check9: "Storage",
      check10: "Facilities",
      check11: "Server",
      check12: "Toilet",
      check13: "Training room",
      check14: "Training room",
      check15: "Kitchen",
      check16: "Garden /Outdoor",
      check17: "Parking",
    },
  
    Hotel: {
      heading: "Hotel",
      check1: "Entrance",
      check2: "Lobby",
      check3: "Reception",
      check4: "Stairway",
      check5: "Corridor",
      check6: "Lounge",
      check7: "Ballroom / Events",
      check8: "Bar",
      check9: "Restaurant",
      check10: "Rooms",
      check11: "Play space",
      check12: "Washroom",
      check13: "Pool",
      check14: "Jardin / Landscape",
      check15: "Business",
      check16: "Office / Working",
      check17: "Service",
      check18: "Kitchen",
      check19: "Storage",
      check20: "Toilet",
      check21: "Parking",
    },
    Restaurant: {
      heading: "Restaurant",
      check1: "Customer area",
      check2: "Counter",
      check3: "Kitchen",
      check4: "Service",
      check5: "Storage",
      check6: "Toilet",
    },
  
    Shop: {
      heading: "Shop",
      check1: "Shop window",
      check2: "Furniture",
      check3: "Wall shelves",
      check4: "Counter",
      check5: "Trial room",
      check6: "Storage",
    },
  
    Museum: {
      heading: "Museum",
      check1: "Reception",
      check2: "Museum shop",
      check3: "Gallery",
      check4: "Cafe",
      check5: "Lobby",
      check6: "Corridor",
      check7: "Breakout",
      check8: "Toilet",
      check9: "Storage",
      check10: "Facilities",
      check11: "Server",
      check12: "Outdoor",
      check13: "Training room",
      check14: "Office",
      check15: "Parking",
    },
  
    Factory: {
      heading: "Factory",
      check1: "Reception",
      check2: "Office",
      check3: "Meeting room",
      check4: "Conference room",
      check5: "Training room",
      check6: "Corridor",
      check7: "Open production",
      check8: "Closed production",
      check9: "Clean room",
      check10: "Breakout",
      check11: "Toilet",
      check12: "Services",
      check13: "Storage",
      check14: "Warehouse",
      check15: "Kitchen",
      check16: "Transport",
      check17: "Outdoor",
      check18: "Parking",
    },
    Warehouse: {
      heading: "Warehouse",
      check1: "Reception",
      check2: "Office",
      check3: "Meeting room",
      check4: "Conference room",
      check5: "Training room",
      check6: "Corridor",
      check7: "Storage High",
      check8: "Storage low",
      check9: "Clean room",
      check10: "Breakout",
      check11: "Toilet",
      check12: "Services",
      check13: "Kitchen",
      check14: "Transport",
      check15: "Outdoor",
      check16: "Parking",
    },
  
    Hospital: {
      heading: "Hospital",
      check1: "Entrance",
      check2: "Lobby",
      check3: "Reception",
      check4: "Stairway",
      check5: "Corridor",
      check6: "Emergency",
      check7: "First care",
      check8: "Labs",
      check9: "Sterile spaces",
      check10: "Patient rooms",
      check11: "Doc / Visit rooms",
      check12: "OT",
      check13: "Cafe",
      check14: "Staff breakout",
      check15: "Waiting",
      check16: "Office",
      check17: "Play space",
      check18: "Toilets",
      check19: "Service",
      check20: "Kitchen",
      check21: "Storage",
      check22: "Garden",
      check23: "Parking",
    },
  };
  
  export default popupDeatils;
  