import React from "react";
import Navbargrey from "../../../Components/Navbargrey/Navbargrey";
import { useNavigate } from "react-router-dom";
import Ellipsecarbon from "../../../images/Ellipse-carbon.webp";

const Carbonemission = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="h-full overflow-hidden relative min-h-screen bg-zinc-300">
        <header className="relative z-10">
          <Navbargrey />
        </header>
        <img
          className="absolute w-96 md:w-1/2 right-0 ms-auto overflow-hidden top-1/2 translate-x-[0%] translate-y-[-50%]"
          src={Ellipsecarbon}
          alt="ellipes"
        />
        <section className="pb-4 lg:pb-8 px-6 sm:px-26 lg:px-28 xl:px-56 pt-24 select-none">
          <div className="text-zinc-800 relative">
            <div className="">
              <h1 className="text-3xl sm:text-5xl md:text-8xl font-extralight sm:pt-12">
                Carbon calculator
              </h1>
              <h2 className="py-6 sm:py-12 text-sm sm:text-base lg:text-lg max-w-2xl">
                This tool allows you to calculate the carbon emissions of your
                lighting and estimate the scope of energy savings.
              </h2>
            </div>

            <div className="pt-6">
              <p className="text-xs sm:text-sm lg:text-base">How does it Work?</p>
              <div>
                <div className="flex flex-row  gap-2 pt-2 ">
                  <div className=" w-8 h-8 rounded-full flex justify-center items-center">
                    <p>1.</p>
                  </div>
                  <div>
                    <p className="text-xs sm:text-sm lg:text-base w-60 sm:w-full max-w-2xl">
                      Specify type of light, country, daily usage hours, and
                      annual usage days for accurate light analysis.
                    </p>
                  </div>
                </div>

                <div className="flex flex-row  gap-2 py-2">
                  <div className=" w-8 h-8 rounded-full flex justify-center items-center">
                    <p>2.</p>
                  </div>
                  <div>
                    <p className="text-xs sm:text-sm lg:text-base w-60 sm:w-full max-w-2xl">
                      Get realtime insights about carbon emissions, savings
                      potentials and cost estimates for upgradation.
                    </p>
                  </div>
                </div>

                <div className="pt-12">
                  <button
                    className="border border-zinc-700  py-1 px-10 text-zinc-700 text-xl shadow-lightfinder duration-500"
                    onClick={() => navigate("/CCGetres")}
                  >
                    Start
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Carbonemission;
