import React, { useState } from "react";
import "../Lengthdropdown/Lengthdropdown.css";

const LengthDropdown = (props) => {
  const {setAreaDropDownValue}= props;
  const [selectedUnit, setSelectedUnit] = useState("m²");
  const lengthUnits = ["m²", "cm²", "km²", "mm²"];

  const handleUnitChange = (event) => {
    setAreaDropDownValue(event.target.value);
    setSelectedUnit(event.target.value);
  };

  return (
    <div className="bg-[#E4E4E7]">
      <select
        id="length-dropdown"
        value={selectedUnit}
        onChange={handleUnitChange}
        className="outline-none py-1 px-3 text-center font-light text-zinc-800 text-base border border-zinc-300  bg-zinc-200 shadow-md rounded-sm shadow-lightfinder duration-300"
      >
        {lengthUnits.map((unit) => (
          <option key={unit} value={unit}>
            {unit}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LengthDropdown;
