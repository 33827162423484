import React, { useState, useEffect } from 'react';
import { RxCross2 } from 'react-icons/rx';
import '../LightFinder/LightFinder.css';
import popupDeatils from './LightFinder1Info';
import BackandNext from './BackandNext/BackandNext';
import ProgressBar from './BackandNext/Progressbar';
import Home_default from '../../images/Home_default.webp';
import Office3_default from '../../images/Office3.webp';
import Hotel_default from '../../images/Hotel.webp';
import Cafe_default from '../../images/Cafe.webp';
import Restaurant_default from '../../images/Restaurant.webp';
import shop_default from '../../images/Shop.webp';
import Museum_default from '../../images/Exhibition.webp';
import Factory_default from '../../images/Factory.webp';
import Warehouse_default from '../../images/Warehouse2.webp';
import Corporate_default from '../../images/Corporate event.webp';
import Concert_default from '../../images/Concerts.webp';
import Celebration_default from '../../images/Celebration.webp';
import Sports_default from '../../images/Sports.webp';
import Hospital_default from '../../images/Hospital.webp';
import Street_default from '../../images/Street.webp';
import Park_default from '../../images/Park.webp';
import Garden_default from '../../images/Garden.webp';
import Facade_default from '../../images/Facade.webp';
import Yacht_default from '../../images/Yacht.webp';
import Airplane_default from '../../images/Airplane.webp';
import Loco_default from '../../images/Locomotives.webp';
import Home_white from '../../images/Home_white.webp';
import Office3_white from '../../images/Office3_white.webp';
import Hotel_white from '../../images/Hotel_white.webp';
import Cafe_white from '../../images/Cafe_white.webp';
import Restaurant_white from '../../images/Restaurant_white.webp';
import Shop_white from '../../images/Shop_white.webp';
import Exhibition_white from '../../images/Exhibition_white.webp';
import Factory_white from '../../images/Factory_white.webp';
import Warehouse2_white from '../../images/Warehouse2_white.webp';
import Corporateevent_white from '../../images/Corporate event_white.webp';
import Concerts_white from '../../images/Concerts_white.webp';
import Celebration_white from '../../images/Celebration_white.webp';
import Sports_white from '../../images/Sports_white.webp';
import Hospital_white from '../../images/Hospital_white.webp';
import Street_white from '../../images/Street_white.webp';
import Park_white from '../../images/Park_white.webp';
import Garden_white from '../../images/Garden_white.webp';
import Facade_white from '../../images/Facade_white.webp';
import Yacht_white from '../../images/Yacht_white.webp';
import Airplane_white from '../../images/Airplane_white.webp';
import Locomotives_white from '../../images/Locomotives_white.webp';
import second_ellipes from '../../images/Ellipse_17.webp';
import Navbargrey from '../../Components/Navbargrey/Navbargrey';
import { setEncryptedItem } from '../../Components/CommonLibrary/EncryptDecrypt';
import { useDispatch, useSelector } from 'react-redux';
import { setUserName } from '../../redux/app/app.slice';

const LightFinder1 = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDiv, setSelectedDiv] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isopenPopup, setIsopenpopup] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const [popupContent, setPopupContent] = useState({
    heading: '',
    check1: '',
    check2: '',
    check3: '',
    check4: '',
    check5: '',
    check6: '',
    check7: '',
    check8: '',
    check9: '',
    check10: '',
    check11: '',
    check12: '',
    check13: '',
    check14: '',
    check15: '',
    check16: '',
    check17: '',
    check18: '',
    check19: '',
    check20: '',
    check21: '',
    check22: '',
    check23: '',
    check24: '',
    check25: '',
  });
  const username = useSelector((state) => state.app.username);
  console.log('username', username);
  const dispatch = useDispatch();

  // const handleSelect = (category) => {
  //   dispatch(setUserName('zehan'));
  //   if (selectedCategory === category && !isPopupOpen) {
  //     setSelectedCategory(null);
  //     setSelectedDiv(null);
  //     resetFilter(category);
  //     openPopupDetails(category);
  //   } else {
  //     if (selectedDiv) {
  //       resetFilter(selectedDiv);
  //     }
  //     setSelectedCategory(category);
  //     setSelectedDiv(category);
  //     applyFilter(category);
  //     openPopupDetails(category);

  //     localStorage.setItem(
  //       'lightFinderData',
  //       JSON.stringify({ selectedCategory: category, selectedDiv: category })
  //     );
  //   }
  // };

  const handleSelect = (category) => {
    if (selectedCategory === category && !isPopupOpen) {
      setSelectedCategory(null);
      setSelectedDiv(null);
      resetFilter(category);
      openPopupDetails(category);
    } else {
      if (selectedDiv) {
        resetFilter(selectedDiv);
      }
      setSelectedCategory(category);
      setSelectedDiv(category);
      applyFilter(category);
      openPopupDetails(category);
    }
    localStorage.setItem('selectedCategory', category);
  };

  useEffect(() => {
    const storedCategory = localStorage.getItem('selectedCategory');
    if (storedCategory) {
      setSelectedCategory(storedCategory);
      setSelectedDiv(storedCategory);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.removeItem('selectedCategory');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // useEffect(() => {
  //   // Retrieve stored data from localStorage on component mount
  //   const storedData = JSON.parse(localStorage.getItem('lightFinderData'));
  //   if (storedData) {
  //     setSelectedCategory(storedData.selectedCategory);
  //     setSelectedDiv(storedData.selectedDiv);
  //   }
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem('lightFinderData');
  //   };

  //   // Add beforeunload event listener
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Remove beforeunload event listener on component unmount
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    localStorage.removeItem('lightfinder1');
    localStorage.removeItem('lightfinder2');
    localStorage.removeItem('lightfinder3');
    localStorage.removeItem('lightfinder4');
    localStorage.removeItem('lightfinder5');
    localStorage.removeItem('lightfinder6Images');
    localStorage.removeItem('lightfinder6Budget');
    localStorage.removeItem('lightfinder710');
    localStorage.removeItem('lightfinder711');
    localStorage.removeItem('lightfinder79');
  }, []);

  const openPopupDetails = (category) => {
    const imageElement = document.getElementById(`image-${category}`);
    if (imageElement) {
      imageElement.style.background =
        'linear-gradient(to bottom, transparent 0%, rgba(255, 255, 0, 100) 100%)';
    }
    setIsPopupOpen(true);
  };

  const applyFilter = (category) => {
    if (category) {
      const imageElement = document.getElementById(`image-${category}`);

      if (imageElement) {
        imageElement.style.background =
          'linear-gradient(to bottom, transparent 0%, rgba(255, 255, 0, 100) 100%)';
      }
    }
  };

  const resetFilter = (category) => {
    if (category) {
      const imageElement = document.getElementById(`image-${category}`);
      if (imageElement) {
        // Reset filter properties
        imageElement.style.filter = '';
      }
    }
  };

  const getCategoryStyle = (category) => {
    return {
      background:
        selectedCategory === category
          ? 'linear-gradient(to bottom, transparent 2%, rgba(255, 255, 0, 100) 98%)'
          : '',
    };
  };

  const renderDiv = (category, imageSrc, label, selectedImageSrc) => (
    <div className="pushable">
      <div
        className={`bg-${
          selectedDiv === category ? '[#7246FD]' : '[#F4F4F5]'
        } rounded-sm cursor-pointer font-light flex flex-col items-center pt-6 shadow-md duration-300 shadow-lightfinder`}
        onClick={() => handleSelect(category)}
        onDoubleClick={() => handleSelect(category)}
        onMouseEnter={() => handleHoverEnter(category)}
        onMouseLeave={() => handleHoverLeave(category)}
      >
        <div>
          <img
            style={getCategoryStyle(category)}
            id={`image-${category}`}
            className="w-16"
            src={selectedDiv === category ? selectedImageSrc : imageSrc}
            alt=""
          />
        </div>

        <p
          className={`pt-2 pb-3 text-xs sm:text-sm ${
            selectedCategory === category ? 'text-zinc-100' : ''
          }`}
        >
          {label}
        </p>
      </div>
    </div>
  );

  const handleHoverEnter = (category) => {
    if (selectedCategory !== category) {
      const imageElement = document.getElementById(`image-${category}`);
      if (imageElement) {
        imageElement.style.background =
          'linear-gradient(to bottom, transparent 0%, rgba(255, 255, 0, 100) 100%)';
      }
    }
  };
  const handleHoverLeave = (category) => {
    if (selectedCategory !== category) {
      const imageElement = document.getElementById(`image-${category}`);
      if (imageElement) {
        imageElement.style.background =
          selectedDiv === category ? '[#7246FD]' : '';
      }
    }
  };

  const handleConfirm = () => {
    setIsopenpopup(false);
    setBlurBackground(false);

    if (selectedDiv) {
      // Here you can apply your filter logic based on the selectedDiv and checkbox states
      const checkboxes = document.querySelectorAll(
        '.popup input[type="checkbox"]'
      );

      const selectedCheckboxesValues = Array.from(checkboxes)
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.parentElement.textContent.trim());

      const storeLightfinder1 = {
        needLightFor: selectedDiv,
        needLightApplicable: selectedCheckboxesValues,
      };
      setEncryptedItem('lightfinder1', storeLightfinder1);

      document.getElementById('image-' + selectedDiv).style.background =
        '[#7246FD]';
    }
  };

  const openPopup = (
    heading,
    check1,
    check2,
    check3,
    check4,
    check5,
    check6,
    check7,
    check8,
    check9,
    check10,
    check11,
    check12,
    check13,
    check14,
    check15,
    check16,
    check17,
    check18,
    check19,
    check20,
    check21,
    check22,
    check23,
    check24,
    check25
  ) => {
    setPopupContent({
      heading,
      check1,
      check2,
      check3,
      check4,
      check5,
      check6,
      check7,
      check8,
      check9,
      check10,
      check11,
      check12,
      check13,
      check14,
      check15,
      check16,
      check17,
      check18,
      check19,
      check20,
      check21,
      check22,
      check23,
      check24,
      check25,
    });
    setIsopenpopup(true);
    setBlurBackground(true);
  };

  const closePopup = () => {
    setIsopenpopup(false);
    setIsPopupOpen(false);
    setBlurBackground(false);

    if (selectedDiv) {
      // Reset the background color and filter properties
      const imageElement = document.getElementById(`image-${selectedDiv}`);
      if (imageElement) {
        imageElement.style.background = selectedCategory
          ? 'linear-gradient(to bottom, transparent 4.48%, rgba(252, 255, 103, 0.932) 98.23%)'
          : 'white';
        imageElement.style.filter = '';
      }

      // Reset the selected div
      setSelectedDiv(null);
      setSelectedCategory(null);
    }
  };

  const renderCheckboxes = () => {
    const checkboxes = [];
    for (let i = 1; i <= 25; i++) {
      const checkboxKey = `check${i}`;
      const checkboxContent = popupContent[checkboxKey];

      if (checkboxContent) {
        checkboxes.push(
          <div className="flex gap-4 font-light" key={checkboxKey}>
            <input type="checkbox" className="w-4 h-4" id={`checkbox-${i}`} />
            <p className="text-sm sm:text-base">{checkboxContent}</p>
          </div>
        );
      }
    }
    return checkboxes;
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    // Select or deselect all checkboxes
    for (let i = 1; i <= 25; i++) {
      const checkbox = document.getElementById(`checkbox-${i}`);
      if (checkbox) {
        checkbox.checked = isChecked;
      }
    }
  };

  return (
    <>
      <div
        className={`h-full overflow-hidden relative min-h-screen bg-zinc-300 ${
          blurBackground ? 'blur-background' : ''
        }`}
      >
        <header className="relative z-10">
          <Navbargrey />
        </header>
        <div className="px-6 sm:px-26 lg:px-24 xl:px-48 h-full">
          <img
            class="absolute right-0 w-96 md:w-1/2  ms-auto overflow-hidden top-1/2 left-1/2 translate-x-[0%] translate-y-[-50%] select-none"
            src={second_ellipes}
            alt="ellipes"
          />
          <div>
            <div className="relative">
              <p className="sm:pl-8 pt-8 text-sm sm:text-lg font-light">
                1. &nbsp; Where do you need light ?
              </p>
            </div>

            {/*===== images start here===== */}
            <div className="relative text-zinc-700 text-center grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-4 sm:gap-4 lg:gap-x-6 sm:px-8 sm:pl-14 mt-5 mb-10  max-w-5xl">
              <div
                onClick={() =>
                  openPopup(
                    popupDeatils.Home.heading,
                    popupDeatils.Home.check1,
                    popupDeatils.Home.check2,
                    popupDeatils.Home.check3,
                    popupDeatils.Home.check4,
                    popupDeatils.Home.check5,
                    popupDeatils.Home.check6,
                    popupDeatils.Home.check7,
                    popupDeatils.Home.check8,
                    popupDeatils.Home.check9,
                    popupDeatils.Home.check10,
                    popupDeatils.Home.check11,
                    popupDeatils.Home.check12
                  )
                }
              >
                {renderDiv('Home', Home_default, 'Home', Home_white)}
              </div>
              <div
                onClick={() =>
                  openPopup(
                    popupDeatils.Office.heading,
                    popupDeatils.Office.check1,
                    popupDeatils.Office.check2,
                    popupDeatils.Office.check3,
                    popupDeatils.Office.check4,
                    popupDeatils.Office.check5,
                    popupDeatils.Office.check6,
                    popupDeatils.Office.check7,
                    popupDeatils.Office.check8,
                    popupDeatils.Office.check9,
                    popupDeatils.Office.check10,
                    popupDeatils.Office.check11,
                    popupDeatils.Office.check12,
                    popupDeatils.Office.check13,
                    popupDeatils.Office.check14,
                    popupDeatils.Office.check15,
                    popupDeatils.Office.check16,
                    popupDeatils.Office.check17
                  )
                }
              >
                {renderDiv('Office', Office3_default, 'Office', Office3_white)}
              </div>
              <div
                onClick={() =>
                  openPopup(
                    popupDeatils.Hotel.heading,
                    popupDeatils.Hotel.check1,
                    popupDeatils.Hotel.check2,
                    popupDeatils.Hotel.check3,
                    popupDeatils.Hotel.check4,
                    popupDeatils.Hotel.check5,
                    popupDeatils.Hotel.check6,
                    popupDeatils.Hotel.check7,
                    popupDeatils.Hotel.check8,
                    popupDeatils.Hotel.check9,
                    popupDeatils.Hotel.check10,
                    popupDeatils.Hotel.check11,
                    popupDeatils.Hotel.check12,
                    popupDeatils.Hotel.check13,
                    popupDeatils.Hotel.check14,
                    popupDeatils.Hotel.check15,
                    popupDeatils.Hotel.check16,
                    popupDeatils.Hotel.check17,
                    popupDeatils.Hotel.check18,
                    popupDeatils.Hotel.check19,
                    popupDeatils.Hotel.check20,
                    popupDeatils.Hotel.check21
                  )
                }
              >
                {renderDiv('Hotel', Hotel_default, 'Hotel', Hotel_white)}
              </div>
              <div>{renderDiv('Cafe', Cafe_default, 'Cafe', Cafe_white)}</div>
              <div
                onClick={() =>
                  openPopup(
                    popupDeatils.Restaurant.heading,
                    popupDeatils.Restaurant.check1,
                    popupDeatils.Restaurant.check2,
                    popupDeatils.Restaurant.check3,
                    popupDeatils.Restaurant.check4,
                    popupDeatils.Restaurant.check5,
                    popupDeatils.Restaurant.check6
                  )
                }
              >
                {renderDiv(
                  'Restaurant',
                  Restaurant_default,
                  'Restaurant',
                  Restaurant_white
                )}
              </div>
              <div
                onClick={() =>
                  openPopup(
                    popupDeatils.Shop.heading,
                    popupDeatils.Shop.check1,
                    popupDeatils.Shop.check2,
                    popupDeatils.Shop.check3,
                    popupDeatils.Shop.check4,
                    popupDeatils.Shop.check5,
                    popupDeatils.Shop.check6
                  )
                }
              >
                {renderDiv('Shop', shop_default, 'Shop', Shop_white)}
              </div>
              <div
                onClick={() =>
                  openPopup(
                    popupDeatils.Museum.heading,
                    popupDeatils.Museum.check1,
                    popupDeatils.Museum.check2,
                    popupDeatils.Museum.check3,
                    popupDeatils.Museum.check4,
                    popupDeatils.Museum.check5,
                    popupDeatils.Museum.check6,
                    popupDeatils.Museum.check7,
                    popupDeatils.Museum.check8,
                    popupDeatils.Museum.check9,
                    popupDeatils.Museum.check10,
                    popupDeatils.Museum.check11,
                    popupDeatils.Museum.check12,
                    popupDeatils.Museum.check13,
                    popupDeatils.Museum.check14,
                    popupDeatils.Museum.check15
                  )
                }
              >
                {renderDiv(
                  'Museum',
                  Museum_default,
                  'Museum',
                  Exhibition_white
                )}
              </div>
              <div
                onClick={() =>
                  openPopup(
                    popupDeatils.Factory.heading,
                    popupDeatils.Factory.check1,
                    popupDeatils.Factory.check2,
                    popupDeatils.Factory.check3,
                    popupDeatils.Factory.check4,
                    popupDeatils.Factory.check5,
                    popupDeatils.Factory.check6,
                    popupDeatils.Factory.check7,
                    popupDeatils.Factory.check8,
                    popupDeatils.Factory.check9,
                    popupDeatils.Factory.check10,
                    popupDeatils.Factory.check11,
                    popupDeatils.Factory.check12,
                    popupDeatils.Factory.check13,
                    popupDeatils.Factory.check14,
                    popupDeatils.Factory.check15,
                    popupDeatils.Factory.check16,
                    popupDeatils.Factory.check17,
                    popupDeatils.Factory.check18
                  )
                }
              >
                {renderDiv(
                  'Factory',
                  Factory_default,
                  'Factory',
                  Factory_white
                )}
              </div>

              <div
                onClick={() =>
                  openPopup(
                    popupDeatils.Warehouse.heading,
                    popupDeatils.Warehouse.check1,
                    popupDeatils.Warehouse.check2,
                    popupDeatils.Warehouse.check3,
                    popupDeatils.Warehouse.check4,
                    popupDeatils.Warehouse.check5,
                    popupDeatils.Warehouse.check6,
                    popupDeatils.Warehouse.check7,
                    popupDeatils.Warehouse.check8,
                    popupDeatils.Warehouse.check9,
                    popupDeatils.Warehouse.check10,
                    popupDeatils.Warehouse.check11,
                    popupDeatils.Warehouse.check12,
                    popupDeatils.Warehouse.check13,
                    popupDeatils.Warehouse.check14,
                    popupDeatils.Warehouse.check15,
                    popupDeatils.Warehouse.check16
                  )
                }
              >
                {renderDiv(
                  'Warehouse',
                  Warehouse_default,
                  'Warehouse',
                  Warehouse2_white
                )}
              </div>
              <div>
                {renderDiv(
                  'Corporate',
                  Corporate_default,
                  'Corporate',
                  Corporateevent_white
                )}
              </div>
              <div>
                {renderDiv(
                  'Concert',
                  Concert_default,
                  'Concert',
                  Concerts_white
                )}
              </div>
              <div>
                {renderDiv(
                  'Celebration',
                  Celebration_default,
                  'Celebration',
                  Celebration_white
                )}
              </div>
              <div>
                {renderDiv('Sports', Sports_default, 'Sports', Sports_white)}
              </div>
              <div
                onClick={() =>
                  openPopup(
                    popupDeatils.Hospital.heading,
                    popupDeatils.Hospital.check1,
                    popupDeatils.Hospital.check2,
                    popupDeatils.Hospital.check3,
                    popupDeatils.Hospital.check4,
                    popupDeatils.Hospital.check5,
                    popupDeatils.Hospital.check6,
                    popupDeatils.Hospital.check7,
                    popupDeatils.Hospital.check8,
                    popupDeatils.Hospital.check9,
                    popupDeatils.Hospital.check10,
                    popupDeatils.Hospital.check11,
                    popupDeatils.Hospital.check12,
                    popupDeatils.Hospital.check13,
                    popupDeatils.Hospital.check14,
                    popupDeatils.Hospital.check15,
                    popupDeatils.Hospital.check16,
                    popupDeatils.Hospital.check17,
                    popupDeatils.Hospital.check18,
                    popupDeatils.Hospital.check19,
                    popupDeatils.Hospital.check20,
                    popupDeatils.Hospital.check21,
                    popupDeatils.Hospital.check22,
                    popupDeatils.Hospital.check23
                  )
                }
              >
                {renderDiv(
                  'Hospital',
                  Hospital_default,
                  'Hospital',
                  Hospital_white
                )}
              </div>

              <div>
                {renderDiv('Street', Street_default, 'Street', Street_white)}
              </div>
              <div>{renderDiv('Park', Park_default, 'Park', Park_white)}</div>
              <div>
                {renderDiv('Garden', Garden_default, 'Garden', Garden_white)}
              </div>
              <div>
                {renderDiv('Facade', Facade_default, 'Facade', Facade_white)}
              </div>
              <div>
                {renderDiv('Yacht', Yacht_default, 'Yacht', Yacht_white)}
              </div>
              <div>
                {renderDiv(
                  'Airplane',
                  Airplane_default,
                  'Airplane',
                  Airplane_white
                )}
              </div>
              <div>
                {renderDiv(
                  'Locomotives',
                  Loco_default,
                  'Locomotives',
                  Locomotives_white
                )}
              </div>
            </div>
            {/* ===== next & back button ===== */}
            <div className="pt-4 sm:pt-12 sm:px-8 max-w-5xl grid grid-cols-1 gap-y-4 lg:grid-cols-2 justify-between items-center">
              <BackandNext
                backLink="/lightfinder"
                nextLink="/lightfinder2"
                disabled={!selectedDiv}
              />
              <ProgressBar currentPage={1} totalPages={7} />
            </div>
          </div>
        </div>
      </div>

      {isopenPopup && (
        <div className="popup bg-zinc-200 p-6 z-10">
          <div className="flex flex-row justify-between items-center">
            <h1 className="text-xl text-zinc-700">{popupContent.heading}</h1>
            <button className="text-2xl" onClick={closePopup}>
              <RxCross2 />
            </button>
          </div>
          {/* Crossclick */}
          <div className="  grid sm:grid-cols-2 w-fit sm:gap-6 pt-6 text-base">
            <p>Choose as applicable</p>
            <br />

            <div className="flex flex-row items-center gap-5">
              <input
                type="checkbox"
                className="lg:w-5 lg:h-5 w-4 h-4"
                onChange={(event) => handleSelectAll(event)}
              />
              <p>All</p>
            </div>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-3 pt-6 gap-1  ">
            {renderCheckboxes()}
          </div>

          <div className="pt-10 flex float-right ">
            <button
              onClick={handleConfirm}
              className="bg-[#7246FD] rounded-sm font-light text-zinc-100 px-4 py-2"
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LightFinder1;
