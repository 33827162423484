import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import aiiimage16 from "../../../../images/Blogaifacade.webp";
import aimappa from "../../../../images/Blogaihut.webp";
import aiimage1410 from "../../../../images/Blogailandscape.webp";
import Blogaishop from "../../../../images/Blogaishop.webp";
import Navbargrey from "../../../../Components/Navbargrey/Navbargrey";
import Footergrey from "../../../../Components/Footergrey/Footergrey";

export const LightAI = () => {
  return (
    <div className="bg-zinc-400">
      <header className="relative z-10">
        <Navbargrey />
      </header>
      <div className="flex flex-row justify-center">
        <div className="bg-zinc-400">
          {/* main heading and date */}
          <p className="bg-zinc-400 p-6 sm:p-26 lg:p-24 xl:p-56 py-32">
            <span className="text-zinc-800 font-extralight text-3xl sm:text-5xl md::text-6xl lg:text-8xl">
              Intelligence of Light
              <br />
              <br />
            </span>
            <span className="text-zinc-800 font-extralight text-sm md:text-lg lg:text-xl ">
              updated jan 2023
            </span>
          </p>

          <figure className="flex flex-row justify-center align-items w-screen">
            <LazyLoadImage
              alt="Intelligence of Light"
              effect="blur"
              className="w-full object-center object-cover"
              src={aiiimage16}
            />
          </figure>

          {/* first grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div className="text-zinc-800 font-light text-xl md:text-2xl xl:text-3xl max-w-xl">
              “Intelligent use of intelligent technology is the future for
              sustainable planet”
            </div>

            <div className="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
              Another example of lighting technology impact in our daily life is
              through Radar sensors and Lidar sensors driven lighting
              application that can present building owners or visitors with
              parking availability data on mobile app, allow employees to book
              meeting rooms or homeowners with personalised lighting and
              temperature setting based on human behaviour.
            </div>
          </div>
          {/* second grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Intelligence of Light"
                effect="blur"
                className="max-w-5/12 max-h-1/6 pt-2 object-center object-cover"
                src={Blogaishop}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
              In one of his work, in 2004, John McCarthy, one of the founding
              fathers of artificial intelligence, defined it as the science and
              engineering of making intelligent machines, especially intelligent
              computer programs.
              <br />
              High customer expectations, rising competition and climate
              challenges, the artificial lighting industry is demanding to
              improve both building lighting design and energy efficiency with
              smart lighting control systems. AI technology enabled building
              systems, it's faster to turn a sustainable design idea into an
              intelligent product and a vision into reality with AI algorithm.
              <br />
              Augmented reality and AI enabled lighting components already exist
              in our smart building system technologies. Let’s look at LEDs as
              light source, they are efficient with long lifetime and low energy
              consumption, but what makes them really smart is the integration
              of smart lighting control to automate energy efficiency and
              predict human behaviour with machine learning. Gradually reducing
              artificial light carbon footprint and improving user experience
              with innovation.
            </div>
          </div>
          {/* third grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Intelligence of Light"
                effect="blur"
                className="h-100 object-center object-cover"
                src={aiimage1410}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl  max-w-xl">
              Machine learning is helping mankind with smart building
              infrastructure. About 80 percent of led lighting is attributed to
              professional applications such as building, office, industry or
              street lighting system, and about 20 percent to private
              residential lighting. Adjusting led lights levels or enabling
              other functions lead to ease of life and work with smart
              integration of existing technology into lights.
              <br />
              <br />
              <br />
              Now the talk of town, ChatGPT and DALL-E, interacting with users
              through text inputs to create ready to consume articles, concepts,
              renders and even the best practices examples. Every question has a
              well researched, thoughtfully curated professional answer just in
              matter of seconds (how much should the lighting fixtures and
              lighting control cost for a specific case, the function of
              architectural lighting designer, energy savings, smart home,
              history of Smart lighting).
            </div>
          </div>

          {/* fourth grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Intelligence of Light"
                effect="blur"
                className="h-100 object-center object-cover"
                src={aimappa}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
              AI in lighting design have changed our conception of the art. It’s
              easier to create images with thoughts, realistic renders from
              ideas. For planners, it's even easier in the time of context as
              instruments create realistic usable solutions.
              <br />
              <br />
              European energy policy is trying to shift towards renewable
              energy-based supply by 2050. Smarter integration of existing
              avenues with smart devices can accelerate the change by 30% just
              with lighting by doing what we already know. So, in the future we
              will get energy from wind and sun.
            </div>
          </div>

          {/* fifth grid */}

          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 p-6 sm:py-12  md:p-24 xl:p-48">
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              <br />
              If you would like to discuss further or you have an interesting
              light story to share, write to us at moien@kaash.eu.
            </div>
          </div>
        </div>
      </div>
      <Footergrey />
    </div>
  );
};
export default LightAI;
