import React from 'react';
import { RxCross2 } from 'react-icons/rx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const OurprojectModel = ({
  isOpen,
  closeModal,
  bigImageSrc,
  thumbnails,
  handleThumbnailClick,
  handleThumbnailEnter,
  heading,
  Description1,
  Description2,
  Description3,
  Description4,
  Description5,
  Collab,
  Collabinfo1,
  Collabinfo2,
  Collabinfo3,
  Date,
}) => {
  const modalStyles = {
    modal: {
      position: 'fixed',
      top: '5%',
      right: '0%',
      left: '0%',
    },
  };
  return (
    isOpen &&
    thumbnails &&
    thumbnails.length > 0 && (
      <div
        className="w-full h-full z-50 flex flex-col justify-center items-center px-2 sm:px-8 "
        style={modalStyles.modal}
      >
        <div className="bg-zinc-700 bg-opacity-80 backdrop-blur-xl border border-zinc-700 shadow-2xl text-zinc-400 max-w-5xl w-full h-full p-2 sm:pt-4 sm:px-8 overflow-y-auto overflow-hidden scroll-smooth ">
          <div
            onClick={closeModal}
            className="text-xl sm:text-2xl font-extralight flex place-content-end cursor-pointer"
          >
            <RxCross2 />
          </div>
          <div className="sm:mb-4 ">
            <h2 className="font-extralight text-lg sm:text-3xl">{heading}</h2>
          </div>
          <div className="gap-3 mt-2 grid sm:grid-cols-3 sm:mt-6 h-fit w-fit">
            <div className="grid grid-cols-4 sm:grid-cols-2 gap-3 cursor-pointer h-fit w-fit">
              {thumbnails.map((thumbnail, index) => (
                <div
                  key={index}
                  onClick={() => handleThumbnailClick(thumbnail)}
                  onMouseEnter={() => handleThumbnailEnter(thumbnail)}
                >
                  <LazyLoadImage
                    alt={`Thumbnail ${index}`}
                    effect="blur"
                    className="h-full w-full  object-center object-cover"
                    src={thumbnail}
                  />
                </div>
              ))}
            </div>
            <div className="overflow-hidden col-span-2 pr-1 sm:pr-0 cursor-pointer">
              <LazyLoadImage
                alt="Big Image"
                effect="blur"
                className="h-full w-full  object-center object-cover"
                src={bigImageSrc}
              />
            </div>
          </div>
          <div className="grid px-1 py-3 sm:py-6 sm:grid-cols-3 sm:px-3  font-extralight font-italic text-xs sm:text-sm gap-10 sm:gap-24 ">
            <div>
              <p>{Description1}</p>
              <div className=" py-4 sm:py-8">
                <p className="italic">{Collab}</p>
                <p className="italic">{Collabinfo1}</p>
                <p className="italic">{Collabinfo2}</p>
                <p className="italic">{Collabinfo3}</p>
                <p className="italic">{Date}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 col-span-2 gap-5 sm:gap-10 pb-24 font-light  text-xs sm:text-sm lg:text-base">
              <p>{Description2}</p>
              <p>{Description3}</p>
              <p>{Description4}</p>
              <p>{Description5}</p>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default OurprojectModel;
