import React from 'react';
import { useState } from 'react';
import FooterLogo from '../../images/Footerlogokaash.webp';
import Partnershipicons from '../../images/Partnershipicons.webp';
import { GrFacebookOption } from 'react-icons/gr';
import { GrInstagram } from 'react-icons/gr';
import { GrLinkedinOption } from 'react-icons/gr';
import Contactpop from '../../Components/Contactpopup/Contactpopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };

  const [subscribe, setSubscribe] = useState('');

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSubscribe(value);
  };

  const handleSubscribe = async () => {
    try {
      const Parameters = {
        email: subscribe,
      };

      // Convert Parameters to JSON string
      const requestBody = JSON.stringify(Parameters);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}subscribe`,
        {
          method: 'POST',
          body: requestBody,
        }
      );

      const result = await response.json();
      if (result.status === 'success') {
        toast(result?.message);
      } else {
        console.error('Error submitting the form:', result.message);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  };
  return (
    <>
      <footer className="bg-gradient-to-br from-zinc-800 via-zinc-900 to-black text-zinc-300">
        <div className="container px-8 py-12 mx-auto">
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
            {/* Sign up Section */}
            <div className="sm:col-span-2 col-start-1 col-span-2">
              <h1 className="max-w-lg text-xl font-normal text-white">
                Sign up for light stories!
              </h1>
              <div className="flex flex-col mx-auto mt-6 space-y-3 md:space-y-0 md:flex-row">
                <input
                  id="email"
                  type="email"
                  className="px-4 py-2 text-zinc-100 bg-zinc-700 border border-zinc-600 rounded-lg focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-600 shadow-md placeholder-gray-400 transition-all duration-200 w-full md:w-2/5" // Changed here
                  placeholder="Your email"
                  value={subscribe}
                  onChange={handleInputChange}
                />
                <button
                  className="w-full px-6 py-2 mt-3 text-sm font-medium text-white transition-colors duration-300 transform bg-orange-600 rounded-lg hover:bg-orange-700 shadow-md md:w-auto md:mt-0 md:ml-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  onClick={handleSubscribe}
                >
                  Subscribe
                </button>
              </div>
              <p className="max-w-md mt-5 text-xs text-gray-400">
                By signing up to receive emails, you agree to our Privacy
                Policy. We treat your info responsibly. Unsubscribe anytime.
              </p>
            </div>

            {/* Navigation Links */}
            <div>
              <p className="text-xl font-normal text-white">
                Explore
              </p>
              <div className="flex flex-col items-start mt-3 space-y-2 text-sm text-gray-400">
                <button onClick={togglePopup} className="hover:text-white">
                  Contact us
                </button>
                <a href="/ourprojects" className="hover:text-white">
                  Projects
                </a>
                <a href="/whatwedo" className="hover:text-white">
                  Services
                </a>
                <a href="/whoarewe" className="hover:text-white">
                  Company
                </a>
                <a href="/carbonemission" className="hover:text-white">
                  Carbon Calculator
                </a>
                <a href="/lightfinder" className="hover:text-white">
                  Light Finder
                </a>
              </div>
            </div>

            {/* Partnership Icons */}
            <div>
              <img
                alt="Partnership Logos"
                className="w-1/2 ml-auto opacity-90 hover:opacity-100 transition-opacity duration-200"
                src={Partnershipicons}
              />
            </div>
          </div>

          {/* Divider */}
          <hr className="my-6 border-zinc-700" />

          {/* Footer Bottom */}
          <div className="flex items-center justify-between">
            <img
              alt="Footer Logo"
              className="w-auto h-5 opacity-90"
              src={FooterLogo}
            />
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/kaash.eu"
                target="_blank"
                className="text-zinc-400 transition-colors duration-300 hover:text-blue-500 text-lg"
                aria-label="Facebook"
              >
                <GrFacebookOption />
              </a>
              <a
                href="https://www.instagram.com/kaash.eu/"
                target="_blank"
                className="text-zinc-400 transition-colors duration-300 hover:text-pink-500 text-lg"
                aria-label="Instagram"
              >
                <GrInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/kaash/"
                target="_blank"
                className="text-zinc-400 transition-colors duration-300 hover:text-blue-500 text-lg"
                aria-label="LinkedIn"
              >
                <GrLinkedinOption />
              </a>
            </div>
          </div>
        </div>
      </footer>

      <div className="contactpop-section">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
      <ToastContainer position="top-center" />
    </>
  );
};

export default Footer;
