import React from 'react';
import { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { neonCursor } from 'threejs-toys';

import { LiaArrowRightSolid } from 'react-icons/lia';
import Navbar from '../../../Components/Navbar/Navbar.js';
import Footer from '../../../Components/Footer/Footer.js';
import OurprojectModel from '../Ourprojects/OurprojectModel.js';
import imageThumbnails from '../Ourprojects/OurprojectModelInfo.js';

// ourproject home page images
import hallthelocomotived_homepage_img from '../../../images/project_hallthelocomotives_homepage_img.webp';
import project_greenwall_homepage_img from '../../../images/project_greenwall_homepage_img.webp';
import project_trennial_homepage_img from '../../../images/project_trennial_homepage_img.webp';
import project_parcdemerl_homepage_img from '../../../images/project_parcdemerl_homepage_img.webp';
import project_arc_studio_homepage_img from '../../../images/project_arc_studio_homepage_img.webp';
import project_chruch_homepage_img from '../../../images/project_chruch_homepage_img.webp';
import project_galerei_homepage_img from '../../../images/project_galerei_homepage_img.webp';
import project_KNMA_homepage_img from '../../../images/project_KNMA_homepage_img.webp';
import project_mosque_homepage_img from '../../../images/project_mosque_homepage_img.webp';
import project_flyingsaucer_homepage_img from '../../../images/project_flyingsaucer_homepage_img.webp';
import project_lachambre_homepage_img from '../../../images/project_lachambre_homepage_img.webp';
import project_skatepark_homepage_img from '../../../images/project_skatepark_homepage_img.webp';
import project_kolla_homepage_img from '../../../images/project_kolla_homepage_img.webp';
import popuptriennial1 from '../../../images/popup-triennial1.webp';

// contact popup.................................
import Contactpop from '../../../Components/Contactpopup/Contactpopup.js';

// Big images imported here..........................................
import Projecthalldelocomotives_bigimg from '../../../images/Projecthalldelocomotives_bigimg.webp';
import Greenwall_bigimg from '../../../images/Greenwall_bigimg.webp';
import parcedemarl_bigimg from '../../../images/Parcdemerl_bigimg.webp';
import Architectural_bigimg from '../../../images/Architectural_bigimg.webp';
import Cathedral_bigimg from '../../../images/Cathedral_bigimg.webp';
import Galerie_bigimg from '../../../images/Galerie_bigimg.webp';
import KNMA_bigimg from '../../../images/KNMA_bigimg.webp';
import Mosque_bigimg from '../../../images/Mosque_bigimg.webp';
import Flyingsaucer_bigimg from '../../../images/Flyingsaucer_bigimg.webp';
import Lachambre_bigimg from '../../../images/Lachambre_bigimg.webp';
import Skatepark_bigimg from '../../../images/Skatepark_bigimg.webp';
import LightatKolla_bigimg from '../../../images/LightatKolla_bigimg.webp';

// our project modal section here ..........................................
const Ourproject = () => {
  const defaultBigImages = {
    Projecthalldelocomotives: Projecthalldelocomotives_bigimg,
    ProjectGreenwall4: Greenwall_bigimg,
    Projecttriennial1a: popuptriennial1,
    ParcdeMerl: parcedemarl_bigimg,
    Architectural: Architectural_bigimg,
    Cathedral: Cathedral_bigimg,
    Galerie: Galerie_bigimg,
    KNMA: KNMA_bigimg,
    Mosque: Mosque_bigimg,
    Flyingsaucer: Flyingsaucer_bigimg,
    Lachambre: Lachambre_bigimg,
    Skatepark: Skatepark_bigimg,
    LightatKolla: LightatKolla_bigimg,
  };
  const [currentImageKey, setCurrentImageKey] = useState(
    'Projecthalldelocomotives'
  );
  const [bigImageSrc, setBigImageSrc] = useState(
    defaultBigImages[currentImageKey]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentThumbnails, setCurrentThumbnails] = useState(
    imageThumbnails[currentImageKey].images
  );
  const [isMouseOverThumbnail, setIsMouseOverThumbnail] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [modalDescription1, setModalDescription1] = useState('');
  const [modalDescription2, setModalDescription2] = useState('');
  const [modalDescription3, setModalDescription3] = useState('');
  const [modalDescription4, setModalDescription4] = useState('');
  const [modalDescription5, setModalDescription5] = useState('');
  const [modalCollab, setModalCollab] = useState('');
  const [modalCollabInfo1, setModalCollabInfo1] = useState('');
  const [modalCollabInfo2, setModalCollabInfo2] = useState('');
  const [modalCollabInfo3, setModalCollabInfo3] = useState('');
  const [modalDate, setModalDate] = useState('');

  const openModal = (imageKey) => {
    setCurrentImageKey(imageKey);
    setBigImageSrc(defaultBigImages[imageKey]);
    setCurrentThumbnails(imageThumbnails[imageKey].images);
    setModalHeading(imageThumbnails[imageKey].heading);
    setModalDescription1(imageThumbnails[imageKey].Description1);
    setModalDescription2(imageThumbnails[imageKey].Description2);
    setModalDescription3(imageThumbnails[imageKey].Description3);
    setModalDescription4(imageThumbnails[imageKey].Description4);
    setModalDescription5(imageThumbnails[imageKey].Description5);
    setModalCollab(imageThumbnails[imageKey].Collab);
    setModalCollabInfo1(imageThumbnails[imageKey].Collabinfo1);
    setModalCollabInfo2(imageThumbnails[imageKey].Collabinfo2);
    setModalCollabInfo3(imageThumbnails[imageKey].Collabinfo3);
    setModalDate(imageThumbnails[imageKey].Date);
    setIsModalOpen(true);
    setBlurBackground(!blurBackground);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setBlurBackground(!blurBackground);
  };

  const handleThumbnailClick = (selectedImage) => {
    setBigImageSrc(selectedImage);
  };

  const handleThumbnailEnter = (selectedImage) => {
    setBigImageSrc(selectedImage);
  };

  // blur background................................
  const [blurBackground, setBlurBackground] = useState(false);

  // contact popup...................................
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };

  return (
    <div id="neon">
      <div className={blurBackground ? 'filter blur': ''}>
        <header className="relative bg-zinc-950 z-10">
          <Navbar />
        </header>
        <div className="bg-gradient-to-b  from-zinc-950 via-red-900 to-zinc-950 px-6 sm:px-26 lg:px-24 xl:px-56 py-16">
          <h1 className="font-extralight text-4xl sm:6xl md:7xl lg:text-8xl/tight xl:text-9xl/tight text-zinc-400 py-8 sm:py-24 2xl:py-48">
            Our Projects
          </h1>
          <div className="grid md:grid-cols-2 gap-3 text-zinc-400">
            <div
              className="overflow-hidden"
              onClick={() => openModal('Projecthalldelocomotives')}
            >
              <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2 font-extralight text-sm sm:text-base xl:text-lg">
                <p>Hall de locomotives</p>
                <LiaArrowRightSolid />
              </div>
              <div>
                <LazyLoadImage
                  alt="Hall de locomotives"
                  effect="blur"
                  className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                  src={hallthelocomotived_homepage_img}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div></div>
              <div
                className="overflow-hidden "
                onClick={() => openModal('ProjectGreenwall4')}
              >
                <div className=" cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2 font-extralight text-base lg:text-lg">
                  <p>Greenwall</p>
                  <LiaArrowRightSolid />
                </div>
                <div className="">
                  <LazyLoadImage
                    alt="Greenwall"
                    effect="blur"
                    className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                    src={project_greenwall_homepage_img}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-3 pt-3 pb-3 text-zinc-400">
            {/* ==== */}
            <div className="grid grid-cols-2 gap-3">
              <div onClick={() => openModal('Projecttriennial1a')}>
                <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2 font-extralight text-xs sm:text-base">
                  <p>Architecture Triennial</p>
                  <LiaArrowRightSolid />
                </div>

                <LazyLoadImage
                  alt="Architecture Triennial"
                  effect="blur"
                  className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                  src={project_trennial_homepage_img}
                />
              </div>
              <div className="grid grid-rows-2 gap-3">
                <div></div>
                <div onClick={() => openModal('Architectural')}>
                  <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2 font-extralight text-xs sm:text-base">
                    <p>Architectural studio</p>
                    <LiaArrowRightSolid />
                  </div>

                  <LazyLoadImage
                    alt="Architectural studio"
                    effect="blur"
                    className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                    src={project_arc_studio_homepage_img}
                  />
                </div>
              </div>
            </div>

            {/* ==== */}
            <div className="grid grid-rows-2 gap-3">
              <div className="" onClick={() => openModal('ParcdeMerl')}>
                <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2  font-extralight text-xs sm:text-base">
                  <p>Parc de Merl</p>
                  <LiaArrowRightSolid />
                </div>
                <div className="">
                  <LazyLoadImage
                    alt="Parc de Merl"
                    effect="blur"
                    className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                    src={project_parcdemerl_homepage_img}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div></div>
                <div className="" onClick={() => openModal('Cathedral')}>
                  <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2  font-extralight text-xs sm:text-base">
                    <p>Kierch um Glacis</p>
                    <LiaArrowRightSolid />
                  </div>
                  <div className="">
                    <LazyLoadImage
                      alt="Kierch um Glacis"
                      effect="blur"
                      className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                      src={project_chruch_homepage_img}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-3 pt-3 text-zinc-400">
            <div className="grid grid-rows-2 gap-3">
              <div className="grid grid-cols-2 gap-3">
                <div
                  className="overflow-hidden"
                  onClick={() => {
                    openModal('Galerie');
                  }}
                >
                  <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2  font-extralight text-xs sm:text-base">
                    <p>Galerie</p>
                    <LiaArrowRightSolid />
                  </div>
                  <div className="">
                    <LazyLoadImage
                      alt="Galerie"
                      effect="blur"
                      className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                      src={project_galerei_homepage_img}
                    />
                  </div>
                </div>
                <div></div>
              </div>
              <div
                className="overflow-hidden "
                onClick={() => {
                  openModal('Flyingsaucer');
                }}
              >
                <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2 font-extralight text-xs sm:text-base">
                  <p>Flying saucer</p>
                  <LiaArrowRightSolid />
                </div>
                <div>
                  <LazyLoadImage
                    alt="Flying saucer"
                    effect="blur"
                    className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                    src={project_flyingsaucer_homepage_img}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className="grid grid-rows-2 gap-3">
                <div
                  className="overflow-hidden"
                  onClick={() => {
                    openModal('KNMA');
                  }}
                >
                  <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2  font-extralight text-xs sm:text-base">
                    <p>KNMA</p>
                    <LiaArrowRightSolid />
                  </div>
                  <div className="">
                    <LazyLoadImage
                      alt="KNMA"
                      effect="blur"
                      className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                      src={project_KNMA_homepage_img}
                    />
                  </div>
                </div>
                <div
                  className="overflow-hidden"
                  onClick={() => {
                    openModal('Lachambre');
                  }}
                >
                  <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2  font-extralight text-xs sm:text-base">
                    <p>La chambre</p>
                    <LiaArrowRightSolid />
                  </div>
                  <div className="">
                    <LazyLoadImage
                      alt="La chambre"
                      effect="blur"
                      className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                      src={project_lachambre_homepage_img}
                    />
                  </div>
                </div>
              </div>
              <div
                className="overflow-hidden"
                onClick={() => {
                  openModal('Mosque');
                }}
              >
                <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2  font-extralight text-xs sm:text-base">
                  <p>Mosque in KSA</p>
                  <LiaArrowRightSolid />
                </div>
                <div className="">
                  <LazyLoadImage
                    alt="Mosque in KSA"
                    effect="blur"
                    className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                    src={project_mosque_homepage_img}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-3 pt-3 text-zinc-400">
            <div className="grid grid-cols-2 gap-3">
              <div></div>
              <div
                className="overflow-hidden"
                onClick={() => {
                  openModal('Skatepark');
                }}
              >
                <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2  font-extralight text-xs sm:text-base">
                  <p>Skatepark</p>
                  <LiaArrowRightSolid />
                </div>
                <div>
                  <LazyLoadImage
                    alt="Skatepark"
                    effect="blur"
                    className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                    src={project_skatepark_homepage_img}
                  />
                </div>
              </div>
            </div>
            <div
              className="overflow-hidden"
              onClick={() => {
                openModal('LightatKolla');
              }}
            >
              <div className="cursor-pointer flex justify-between items-center border border-zinc-400 mb-3 px-2  font-extralight text-xs sm:text-base">
                <p>Light at Kolla</p>
                <LiaArrowRightSolid />
              </div>
              <div>
                <LazyLoadImage
                  alt="Light at Kolla"
                  effect="blur"
                  className="h-full w-full cursor-pointer object-center object-cover border border-zinc-400"
                  src={project_kolla_homepage_img}
                />
              </div>
            </div>
          </div>
          <div className="my-12 sm:my-48 flex items-center justify-center ">
            <h2 className="text-zinc-400 mx-8 font-light text-base sm:text-xl">
              Would you like to discuss a project?
            </h2>
            <button
              onClick={togglePopup}
              className="px-4 py-2 bg-transparent text-zinc-400  border border-zinc-400 text-base sm:text-xl  font-light btn-shadow-white"
            >
              Talk to us
            </button>
          </div>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
      <OurprojectModel
        isOpen={isModalOpen}
        closeModal={closeModal}
        bigImageSrc={bigImageSrc}
        thumbnails={currentThumbnails}
        handleThumbnailClick={handleThumbnailClick}
        handleThumbnailEnter={handleThumbnailEnter}
        heading={modalHeading}
        Description1={modalDescription1}
        Description2={modalDescription2}
        Description3={modalDescription3}
        Description4={modalDescription4}
        Description5={modalDescription5}
        Collab={modalCollab}
        Collabinfo1={modalCollabInfo1}
        Collabinfo2={modalCollabInfo2}
        Collabinfo3={modalCollabInfo3}
        Date={modalDate}
      />
      <div className="contactpop-section">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
    </div>
  );
};

export default Ourproject;
