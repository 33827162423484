import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Bloghumancentriclight from "../../../../images/Bloghumancentriclight.webp";
import Bloghumancentriclight1 from "../../../../images/Bloghumancentriclight1.webp";
import Bloghumancentriclight2 from "../../../../images/Bloghumancentriclight2.webp";
import Bloghumancentriclight3 from "../../../../images/Bloghumancentriclight3.webp";
import Navbargrey from "../../../../Components/Navbargrey/Navbargrey";
import Footergrey from "../../../../Components/Footergrey/Footergrey";

export const Human = () => {
  return (
    <div className="bg-zinc-400">
      <header className="relative z-10">
        <Navbargrey />
      </header>
      <div className="flex flex-row justify-center">
        <div className="bg-zinc-400">
          {/* main heading and date */}
          <p className="bg-zinc-400 p-6 sm:p-26 lg:p-24 xl:p-56 py-32">
            <span className="text-zinc-800 font-extralight text-3xl sm:text-5xl md::text-6xl lg:text-8xl">
              Human-centric lighting design
              <br />
              <br />
            </span>
            <span className="text-zinc-800 font-extralight text-sm md:text-lg lg:text-xl">
              updated jan 2023
            </span>
          </p>

          <LazyLoadImage
            alt="Bloghumancentriclight"
            effect="blur"
            className="px-2 md:px-5 lg:px-10 w-full h-730 object-cover object-center"
            src={Bloghumancentriclight}
          />
          {/* first grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div className="text-zinc-800 font-light text-xl md:text-2xl xl:text-3xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              “Designing with light for activity, architecture and atmosphere”
            </div>
            <div className="text-zinc-800 font-light text-sm md:base xl:text-xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              The advent of artificial light has transformed human lives.
              It&#39;s becoming more common in our contemporary life to work in
              environments that lack natural light - basements, dark rooms or
              workplaces with artificial lighting till far into the night.
            </div>
          </div>

          {/* second grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Bloghumancentriclight"
                effect="blur"
                className="max-w-5/12 pt-2 object-cover object-center"
                src={Bloghumancentriclight3}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
              {" "}
              In view of the fact that the most of our time is spent indoors,
              it&#39;s imperative to understand the role of lighting in
              influencing our mental health and, by extension, the well-being of
              others who reside in the space.
              <br />
              <br />
              Emerging light technologies focusing on the data power of light
              and its impact on people is growing fast. Human Centric Lighting
              (HCL) is a comprehensive technique of examining the impact of
              light on humans.
            </div>
          </div>

          {/* third grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Bloghumancentriclight"
                effect="blur"
                className="max-w-5/12 pt-2 object-cover object-center"
                src={Bloghumancentriclight2}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
              The fundamentals revolves around mimicking behaviour of natural
              light in indoor environment based on the time and location. Many
              solution providers limit themselves to simple uplight solution
              with changing colors from warm to cool with day time, as even the
              billion dollar enterprise struggle to get back buck for money in
              providing a solution synchronised with day, time and location.
              Maybe the boring company will have a solution someday.
            </div>
          </div>

          <LazyLoadImage
            alt="Bloghumancentriclight"
            effect="blur"
            className="left-0 w-full h-730 object-cover object-center"
            src={Bloghumancentriclight1}
          />

          {/* fourth grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 p-6 sm:py-12  md:p-24 xl:p-48">
            <div class="text-zinc-800 font-light text-sm md:text-base xl:text-xl max-w-xl">
              If you would like to discuss further or you have an interesting
              light story to share, write to us at moien@kaash.eu.
            </div>
            <div class="text-zinc-800 font-light text-sm md:text-lg lg:text-xl">
              Working with light for more than a decade, we are of strong
              opinion that the concept of HCL goes beyond technology into
              holistic light planning. Light is for people, their task and its
              environment, which is where the idea of human centric lighting
              begins - with people at it core.
              <br />
              In this endeavour, light application, tool selection, technical
              parameters, and human comfort are evaluated in synthesis. A
              functional yet comfortable space is pivotal in increasing health
              and well being of its user. Studies have proven the positive
              impact on happiness quotient in healthcare facilities, increased
              productivity in offices and healthy engagement in urban spaces. We
              are on a mission to establish a seamless sync of the mind, body,
              and light by incorporating people centric design approach. Come
              join the revolution.
            </div>
          </div>
        </div>
      </div>
      <Footergrey />
    </div>
  );
};
export default Human;
