import { React, useEffect, useState } from 'react';
import '../../pages/LightFinder/LightFinder4.css';
import Navbargrey from '../../Components/Navbargrey/Navbargrey';

// importing back and next button aldogwith progress bar
import BackandNext from './BackandNext/BackandNext';
import ProgressBar from './BackandNext/Progressbar';

// importing default images
import Victorian from '../../images/Victorian.webp';
import Classical from '../../images/Classical.webp';
import neoClassical from '../../images/Neoclassical.webp';
import Brutalist from '../../images/Brutalist.webp';
import eclectric from '../../images/Eclectic.webp';
import Industrial from '../../images/Industrial.webp';
import minimal from '../../images/Minimal.webp';
import functional from '../../images/Functional.webp';
import hybrid from '../../images/Hybrid.webp';
import hidden from '../../images/Hidden.webp';
import glamour from '../../images/Glamour.webp';
import abstract from '../../images/Abstract.webp';

// importing the images for selected states ie.White images
import Victorian_white from '../../images/Victorian_white.webp';
import Classical_white from '../../images/Classical_white.webp';
import Neoclassical_white from '../../images/Neoclassical_white.webp';
import Brutalist_white from '../../images/Brutalist_white.webp';
import Eclectic_white from '../../images/Eclectic_white.webp';
import Industrial_white from '../../images/Industrial_white.webp';
import Minimal_white from '../../images/Minimal_white.webp';
import Functional_white from '../../images/Functional_white.webp';
import Hybrid_white from '../../images/Hybrid_white.webp';
import Hidden_white from '../../images/Hidden_white.webp';
import Glamour_white from '../../images/Glamour_white.webp';
import Abstract_white from '../../images/Abstract_white.webp';

// importing background ellipes image
import second_ellipes from '../../images/Ellipse_17.webp';
import { setEncryptedItem } from '../../Components/CommonLibrary/EncryptDecrypt';

// Array of Icon and there name
const Data = [
  {
    name: 'Eclectic',
    defaultImage: eclectric,
    selectedImage: Eclectic_white,
    isSelected: false,
  },
  {
    name: 'Victorian',
    defaultImage: Victorian,
    selectedImage: Victorian_white,
    isSelected: false,
  },
  {
    name: 'Classical',
    defaultImage: Classical,
    selectedImage: Classical_white,
    isSelected: false,
  },
  {
    name: 'Neo classical',
    defaultImage: neoClassical,
    selectedImage: Neoclassical_white,
    isSelected: false,
  },
  {
    name: 'Brutalist',
    defaultImage: Brutalist,
    selectedImage: Brutalist_white,
    isSelected: false,
  },
  {
    name: 'Industrial',
    defaultImage: Industrial,
    selectedImage: Industrial_white,
    isSelected: false,
  },
  {
    name: 'Glamour Bling',
    defaultImage: glamour,
    selectedImage: Glamour_white,
    isSelected: false,
  },
  {
    name: 'Minimal',
    defaultImage: minimal,
    selectedImage: Minimal_white,
    isSelected: false,
  },
  {
    name: 'Functional',
    defaultImage: functional,
    selectedImage: Functional_white,
    isSelected: false,
  },
  {
    name: 'Hybrid',
    defaultImage: hybrid,
    selectedImage: Hybrid_white,
    isSelected: false,
  },
  {
    name: 'Hidden',
    defaultImage: hidden,
    selectedImage: Hidden_white,
    isSelected: false,
  },
  {
    name: 'Abstract',
    defaultImage: abstract,
    selectedImage: Abstract_white,
    isSelected: false,
  },
];

const LightFinder5 = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState([]);

  useEffect(() => {
    // Load the selected image index from local storage on component mount
    const savedIndex = JSON.parse(localStorage.getItem('selectedImageIndex'));
    const savedName = localStorage.getItem('selectedImageName');
    if (savedIndex !== null && savedIndex >= 0 && savedIndex < Data.length) {
      setSelectedImageIndex(savedIndex);
    }
    if (savedName !== null) {
      setSelectedImageName(savedName);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove stored selected image index on page unload (refresh or close)
      localStorage.removeItem('selectedImageIndex');
      localStorage.removeItem('selectedImageName');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleClick = (index, name) => {
    // Toggle the selection state
    setSelectedImageIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedImageName(name);

    // Store the selected index in local storage
    localStorage.setItem('selectedImageIndex', JSON.stringify(index));
    localStorage.setItem('selectedImageName', name);
  };
  // const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  // const [hoveredIndex, setHoveredIndex] = useState(null);

  // const handleMouseEnter = (index) => {
  //   setHoveredIndex(index);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredIndex(null);
  // };

  // const handleClick = (index) => {
  //   // Toggle the selection state
  //   setSelectedImageIndex((prevIndex) => (prevIndex === index ? null : index));

  //   // If selected, update the selectedImageName
  //   if (selectedImageIndex === null || selectedImageIndex !== index) {
  //     setSelectedImageName(Data[index].name);
  //   } else {
  //     // If deselected, set selectedImageName to null
  //     setSelectedImageName([]);
  //   }
  // };

  // const handleClick = (index) => {
  //   // If the clicked index is already selected, deselect it
  //   if (selectedImageIndex === index) {
  //     setSelectedImageIndex(null);
  //     setSelectedImageName([]);
  //   } else {
  //     // Otherwise, select the clicked index
  //     setSelectedImageIndex(index);
  //     setSelectedImageName(Data[index].name);
  //   }
  // };
  useEffect(() => {
    if (selectedImageName !== '') {
      // Assuming setEncryptedItem is a function that properly encrypts and stores data
      setEncryptedItem('lightfinder4', selectedImageName);
    }
  }, [selectedImageName]);

  // useEffect(() => {
  //   if (selectedImageName?.length > 0) {
  //     setEncryptedItem('lightfinder4', selectedImageName);
  //   } else {
  //     setEncryptedItem('lightfinder4', []);
  //   }
  // }, [selectedImageName]);

  return (
    <>
      <div className="h-full overflow-hidden relative min-h-screen bg-zinc-300">
        <header className="relative z-10">
          <Navbargrey />
        </header>
        <div className="px-6 sm:px-26 lg:px-24 xl:px-48 h-full font-light leading-tight">
          <img
            class="absolute right-0 w-96 md:w-1/2  ms-auto overflow-hidden top-1/2 left-1/2 translate-x-[0%] translate-y-[-50%] select-none"
            src={second_ellipes}
            alt="ellipes"
          />
          <div>
            <div>
              <p className="sm:pl-8 pt-8 text-sm sm:text-lg">
                4. &nbsp; Choose a design style.
              </p>
            </div>

            <div className="relative text-zinc-700 text-xs sm:text-sm text-center grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 sm:gap-4 lg:gap-x-6 sm:px-8 sm:pl-16 mt-5 mb-10 max-w-4xl">
              {Data.map((image, index) => (
                <div
                  key={index}
                  className={`bg-${
                    selectedImageIndex === index ? '[#7246FD]' : '[#F4F4F5]'
                  } pt-6 pb-2 sm:pb-4 box rounded-sm cursor-pointer shadow-md shadow-lightfinder duration-300`}
                  onClick={() => handleClick(index)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="mx-5 flex flex-col items-center justify-center">
                    <img
                      className="w-16 icon"
                      src={
                        selectedImageIndex === index
                          ? image.selectedImage
                          : image.defaultImage
                      }
                      alt={image.name}
                      style={{
                        background:
                          hoveredIndex === index
                            ? 'linear-gradient(to bottom, transparent 0%, rgba(255, 255, 0) 100%)'
                            : 'none',
                      }}
                    />
                    <p
                      className={`pt-2 ${
                        selectedImageIndex === index ? 'text-zinc-100' : ''
                      }`}
                    >
                      {image.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="px-6 sm:px-26 lg:px-24 xl:px-48">
          {/* ===== next & back button ===== */}
          <div className="pt-4 sm:pt-12 sm:px-8 max-w-5xl grid grid-cols-1 gap-y-4 lg:grid-cols-2 justify-between items-center">
            <BackandNext
              backLink="/lightfinder3"
              nextLink="/lightfinder5"
              disabled={selectedImageIndex === null}
            />
            <ProgressBar currentPage={4} totalPages={7} />
          </div>
        </div>
      </div>
    </>
  );
};
export default LightFinder5;
