import CryptoJS from "crypto-js";

// Function to store an encrypted key-value pair in localStorage
export function setEncryptedItem(key, value) {
    localStorage.setItem(key, encryptData(value));
};

// Function to retrieve and decrypt the value associated with a key from localStorage
export function getDecryptedItem(key) {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
        return decryptData(encryptedData);
    }
    return null;
};

// Function to encrypt data
const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'kaashLight@2024').toString();
};

// Function to decrypt data
const decryptData = (encryptedData) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, 'kaashLight@2024');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch (error) {
        console.error("Error decrypting data:", error);
        return null;
    }
};
