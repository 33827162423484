import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbargrey from "../../../../Components/Navbargrey/Navbargrey";
import Footergrey from "../../../../Components/Footergrey/Footergrey";
import Bloghotellight1b from "../../../../images/Bloghotellight1b.webp";
import Bloghotellight1 from "../../../../images/Bloghotel1.webp";
import Bloghotellight2 from "../../../../images/Bloghotel2.webp";
import Bloghotellight4 from "../../../../images/Bloghotel4.webp";

export const Immersive = () => {
  return (
    <div className="bg-zinc-400">
      <header className="relative z-10">
        <Navbargrey />
      </header>
      <div className="flex flex-row justify-center">
        <div className="bg-zinc-400">
          {/* main heading and date */}
          <p className=" p-6 sm:p-26 lg:p-24 xl:p-56 py-32">
            <span className="text-zinc-800 font-extralight text-3xl sm:text-5xl md::text-6xl lg:text-8xl">
              Immersive hotels with Light <br />
              <br />
            </span>
            <span className="text-zinc-800 font-extralight text-sm md:text-lg lg:text-xl ">
              updated jan 2023
            </span>
          </p>

          <figure className="flex flex-row justify-center align-items w-screen">
            <LazyLoadImage
              alt="Bloghotellight"
              effect="blur"
              className="w-full object-center object-cover"
              src={Bloghotellight1b}
            />
          </figure>

          {/* first grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div className="text-zinc-800 font-light text-xl md:text-2xl xl:text-3xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              “Light defines the identity and brand image”
            </div>

            <div className="text-zinc-800 font-light text-sm md:base xl:text-xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              <span className="text-wrapper-19">
                Le Corbusier, a great Swiss architect and designer, stated:{" "}
              </span>
              <span className="text-wrapper-20">
                &#34;Light creates ambience and feel of a place, as well as the
                expression of a structure”
              </span>
              <span className="text-wrapper-19">
                . In fact, light can determine the ambience of an environment
                and alter our way of life. It's important to what we choose in
                the lighting system, as it defines the identity and brand image
                we desire to present. An expert opinion adds functional yet
                aesthetic value by elevating the experience which an architect
                or an interior designer may not be an expert of. <br />
              </span>
            </div>
          </div>

          {/* second grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Bloghotellight"
                effect="blur"
                className="max-w-5/12 pt-2 object-center object-cover"
                src={Bloghotellight4}
              />
            </div>
            <div class="text-zinc-900 font-light text-sm md:base xl:text-xl max-w-xl">
              The ironical truth is the awareness of light importance, yet the
              limited actions to deliver. Let's explore about architectural
              lighting with digital media systems and sustainable technology for
              hospitality. Architecture and space design creates a interactive
              experience for all ages. New technologies represent a value for
              the economy, supporting user experience in the hospitality
              journey. Interior design for every hotel exclaims the brand
              identity in its own unique style. Lighting here can play dual
              roles - function and design. The design of decorative lights sets
              the mood for all spaces. From industrial pendants to delicate
              flowing patterns or minimal sleek solutions, the space often
              dominated by interior design experts and off the shelves light
              sellers. The second role come into action in the form of
              architectural lighting. The crucial and intelligent use of light
              to support material design and space function. From beam angles to
              delivered lumens, relative lighting and contrast are crucial
              parameters.
            </div>
          </div>

          {/* third grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Bloghotellight"
                effect="blur"
                className="max-w-5/12 pt-2 object-center object-cover"
                src={Bloghotellight2}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
              Space experience is influenced by lighting design, and integrating
              natural light is the perfect starting point. Identification of
              interior and exterior details is necessary to separate hot lights
              and soft lights. Soft lights complement the morning freshness for
              breakfast and evening warmth for dinners, while hot lights add to
              contrast and differentiation of primary and secondary spaces,
              adding to an overall personalised experience. Rooms are a bit
              tricky, often tagged as spaces with boring, flat and insufficient
              lighting. Integrating layers of light with pre-set themes to
              support every user demand can be good way to resolve the ancient
              mystery of poor lighting in hotel rooms. Transitional spaces, such
              as corridors, lounges or even fitness areas play its own role in
              building the overall visitor experience in a hotel. From short
              coffee moments to long workouts, lighting should support space
              function and complement its design. The influence of indirect
              lighting in these areas can be very impactful.
            </div>
          </div>

          {/* fourth grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Bloghotellight"
                effect="blur"
                className="max-w-5/12 pt-2 object-center object-cover"
                src={Bloghotellight1}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
              Decorative lighting in hospitality comes first. In order, the
              prime element to consider is the entrance to impress visitors
              (chandeliers for example); the check-in point with decorative
              lighting lamps; bright and indirect light and soft tones
              functional to the small size of bathroom; optimal lighting in the
              corridors to facilitate the orientation of the guests (vitally
              important for outdoors pools and garden as well, with floor
              lamps).
              <div class="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
                Taking inspirations from geographical highlights can do wonders
                in creating exotic atmospheres. A smart example is the Icehotel
                in Sweden, Kiruna, handsculpted in 1989, a must-see in the
                bucket list for tourists and locals. Worldwide known to be the
                biggest hotel of ice and snow, it is a combination of ice rooms
                and elegant art suites, churches, decorations by local artists,
                all made of ice. Snow and ice are taken from Torne River,
                nearby. All in 2100 square meter. You can also attend a course
                on Scandivian cuisine. If you are ready to leave in a -5 °C (23
                °F), between December and April, feel welcome.
              </div>
            </div>
          </div>

          {/* fifth grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 p-6 sm:py-12 md:p-24 xl:p-48">
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl py-10 md:py-24 xl:py-32 max-w-xl">
              <br />
              If you would like to discuss further or find the ideal solution
              for your need, talk to our light experts at moien@kaash.eu.
            </div>
          </div>
        </div>
      </div>
      <Footergrey />
    </div>
  );
};
export default Immersive;
