import React from 'react';
import { Link } from 'react-router-dom';

const BackNextButtons = ({ backLink, nextLink, disabled }) => {
  return (
    <div className="flex gap-5 cursor-pointer font-light">
      <Link to={backLink}>
        <div className="border border-zinc-500 px-4 sm:px-6 py-1  text-zinc-800 shadow-lightfinder duration-300">
          <button className="">Back</button>
        </div>
      </Link>
      <Link to={nextLink}>
        {/* <div className="border border-zinc-500 px-4 sm:px-6 py-1  text-zinc-800 shadow-lightfinder duration-300">
          <button className="">Next</button>
        </div> */}
        <div
          className={`border border-zinc-500 px-4 sm:px-6 py-1 text-zinc-800 shadow-lightfinder duration-300 ${
            disabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          <button className="" disabled={disabled}>
            Next
          </button>
        </div>
      </Link>
    </div>
  );
};

export default BackNextButtons;
