import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbargrey from "../../../../Components/Navbargrey/Navbargrey";
import Footergrey from "../../../../Components/Footergrey/Footergrey";
import Bloglightfestival from "../../../../images/Bloglightfestival.webp";
import Bloglightfestival1 from "../../../../images/Bloglightfestival1.webp";
import Bloglightfestival2 from "../../../../images/Bloglightfestival2.webp";
import Bloglightfestival3 from "../../../../images/Bloglightfestival3.webp";

export const Festivals = () => {
  return (
    <div className="bg-zinc-400">
      <header className="relative z-10">
        <Navbargrey />
      </header>
      <div className="flex flex-row justify-center">
        <div className="bg-zinc-400">
          {/* main heading and date */}
          <p className="bg-zinc-400 p-6 sm:p-26 lg:p-24 xl:p-56 py-32">
            <span className="text-zinc-800 font-extralight text-3xl sm:text-5xl md::text-6xl lg:text-8xl">
              Lights in Bergamo and Brescia
              <br />
              <br />
            </span>
            <span className="text-zinc-800 font-extralight text-sm md:text-lg lg:text-xl ">
              updated jan 2023
            </span>
          </p>

          <figure className="flex flex-row justify-center align-items">
            <LazyLoadImage
              alt="Bloglightfestival"
              effect="blur"
              className="w-full h-730 object-center object-cover"
              src={Bloglightfestival1}
            />
          </figure>

          {/* first grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div className="text-zinc-800 font-light text-xl md:text-2xl xl:text-3xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              “Light festivals are the excellent visiting card of a city”
            </div>
            <div className="text-zinc-800 font-light text-sm md:base xl:text-xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              Are you ready to light up your life? Well, in Bergamo and Brescia
              they did just that with the &#34;Light is Life&#34; festival! Held
              in February to bring together renewable sources of energy and
              tourism in a dazzling display of lights.
              <br />
              <br />
              The festival had a noble cause too, with the aim of raising
              awareness for energy consumption and promoting social well-being.
              Funds raised were donated to the Energy Bank to support local
              families, who were hit hard by the pandemic. A2A, a lighting
              society, even donated a sum equivalent to the volume of energy
              used!
              <br />
              Lighting map of the city
            </div>
          </div>

          {/* second grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Bloglightfestival"
                effect="blur"
                className="max-w-5/12 pt-2 object-cover object-center"
                src={Bloglightfestival3}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:text-base xl:text-xl max-w-xl">
              The festival was organized by some big names from around the
              world, including Angelo Bonelli, the founder of Kitonb Creative
              Studio and Pam Show, the curator of the Light Art Collection in
              Amsterdam. Students from both cities worked tirelessly to create
              installations that expressed their feelings about art.
              <br />
              <br />
              Art lighting with AL in an architectural context
              <br />
              The festival in Bergamo featured the Upper Town, which covered all
              the must-see historic and cultural spots. The heart of Bergamo’s
              ancient city, Piazza Vecchia, was illuminated with masterpieces of
              light engineering. And to top it off, the Choir of the Gaetano
              Donizetti Conservatory accompanied video projections on the Angelo
              Mai Civic Library.
            </div>
          </div>

          <figure className="flex flex-row justify-center align-items">
            <LazyLoadImage
              alt="Bloglightfestival"
              effect="blur"
              className=" w-full object-cover object-center"
              src={Bloglightfestival2}
            />
          </figure>

          {/* third grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="text-zinc-800 font-light text-sm md:text-base xl:text-xl max-w-xl">
              Art lighting in Bergamo&#39;s historic centre
              <br />
              The festival was such a hit that the mayors of both cities
              couldn&#39;t stop talking about it! Brescia&#39;s mayor, Emilio
              Del Bono, called it an &#34;excellent visiting card&#34; for the
              year ahead. While Bergamo&#39;s mayor, Giorgio Gori, hailed it as
              &#34;one of the most important and spectacular events in the
              capital&#34;. The festival brought national and international
              light art expressions to Bergamo for the first time, and the
              installations were designed to enhance the city&#39;s rich
              cultural heritage.
            </div>
          </div>

          {/* image */}
          <figure className="flex flex-row justify-center align-items">
            <LazyLoadImage
              alt="Bloglightfestival"
              effect="blur"
              className=" w-full object-cover object-center"
              src={Bloglightfestival}
            />
          </figure>

          {/* fourth grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:p-24 xl:p-48">
            <div class="text-zinc-800 font-light text-sm md:text-base xl:text-xl max-w-xl">
              If you would like to discuss further or you have an interesting
              light story to share, write to us at moien@kaash.eu.
            </div>
            <div class="text-zinc-900 font-light text-sm md:text-lg lg:text-xl ">
              So, if you missed out on this festival, don&#39;t worry. Keep an
              eye out for next year&#39;s &#34;Light is Life&#34; festival and
              experience the magic of light and art in Bergamo and Brescia.
              It&#39;s an event that&#39;s sure to leave you feeling energized
              and illuminated!
            </div>
          </div>
        </div>
      </div>
      <Footergrey />
    </div>
  );
};

export default Festivals;
