export const countriesList = [
  { id: 1, name: "Algeria", PhoneCode: "+213" },
  { id: 2, name: "Andorra", PhoneCode: "+376" },
  { id: 3, name: "Angola", PhoneCode: "+244" },
  { id: 4, name: "Anguilla", PhoneCode: "+1264" },
  { id: 5, name: "Antigua Barbuda", PhoneCode: "+1268" },
  { id: 6, name: "Argentina", PhoneCode: "+54" },
  { id: 7, name: "Armenia", PhoneCode: "+374" },
  { id: 8, name: "Aruba", PhoneCode: "+297" },
  { id: 9, name: "Australia", PhoneCode: "+61" },
  { id: 10, name: "Austria", PhoneCode: "+43" },
  { id: 11, name: "Azerbaijan", PhoneCode: "+994" },
  { id: 12, name: "Bahamas", PhoneCode: "+1242" },
  { id: 13, name: "Bahrain", PhoneCode: "+973" },
  { id: 14, name: "Bangladesh", PhoneCode: "+880" },
  { id: 15, name: "Barbados", PhoneCode: "+1246" },
  { id: 16, name: "Belarus", PhoneCode: "+375" },
  { id: 17, name: "Belgium", PhoneCode: "+32" },
  { id: 18, name: "Belize", PhoneCode: "+501" },
  { id: 19, name: "Benin", PhoneCode: "+229" },
  { id: 20, name: "Bermuda", PhoneCode: "+1441" },
  { id: 21, name: "Bhutan", PhoneCode: "+975" },
  { id: 22, name: "Bolivia", PhoneCode: "+591" },
  { id: 23, name: "Bosnia Herzegovina", PhoneCode: "+387" },
  { id: 24, name: "Botswana", PhoneCode: "+267" },
  { id: 25, name: "Brazil", PhoneCode: "+55" },
  { id: 26, name: "Brunei", PhoneCode: "+673" },
  { id: 27, name: "Bulgaria", PhoneCode: "+359" },
  { id: 28, name: "Burkina Faso", PhoneCode: "+226" },
  { id: 29, name: "Burundi", PhoneCode: "+257" },
  { id: 30, name: "Cambodia", PhoneCode: "+855" },
  { id: 31, name: "Cameroon", PhoneCode: "+237" },
  { id: 32, name: "Canada", PhoneCode: "+1" },
  { id: 33, name: "Cape Verde Islands", PhoneCode: "+238" },
  { id: 34, name: "Cayman Islands", PhoneCode: "+1345" },
  { id: 35, name: "Central African Republic", PhoneCode: "+236" },
  { id: 36, name: "Chile", PhoneCode: "+56" },
  { id: 37, name: "China", PhoneCode: "+86" },
  { id: 38, name: "Colombia", PhoneCode: "+57" },
  { id: 39, name: "Comoros", PhoneCode: "+269" },
  { id: 40, name: "Congo", PhoneCode: "+242" },
  { id: 41, name: "Cook Islands", PhoneCode: "+682" },
  { id: 42, name: "Costa Rica", PhoneCode: "+506" },
  { id: 43, name: "Croatia", PhoneCode: "+385" },
  { id: 44, name: "Cuba", PhoneCode: "+53" },
  { id: 45, name: "Cyprus North", PhoneCode: "+90392" },
  { id: 46, name: "Cyprus South", PhoneCode: "+357" },
  { id: 47, name: "Czech Republic", PhoneCode: "+42" },
  { id: 48, name: "Denmark", PhoneCode: "+45" },
  { id: 49, name: "Djibouti", PhoneCode: "+253" },
  { id: 50, name: "Dominica", PhoneCode: "+1809" },
  { id: 51, name: "Dominican Republic", PhoneCode: "+1809" },
  { id: 52, name: "Ecuador", PhoneCode: "+593" },
  { id: 53, name: "Egypt", PhoneCode: "+20" },
  { id: 54, name: "El Salvador", PhoneCode: "+503" },
  { id: 55, name: "Equatorial Guinea", PhoneCode: "+240" },
  { id: 56, name: "Eritrea", PhoneCode: "+291" },
  { id: 57, name: "Estonia", PhoneCode: "+372" },
  { id: 58, name: "Ethiopia", PhoneCode: "+251" },
  { id: 59, name: "Falkland Islands", PhoneCode: "+500" },
  { id: 60, name: "Faroe Islands", PhoneCode: "+298" },
  { id: 61, name: "Fiji", PhoneCode: "+679" },
  { id: 62, name: "Finland", PhoneCode: "+358" },
  { id: 63, name: "France", PhoneCode: "+33" },
  { id: 64, name: "French Guiana", PhoneCode: "+594" },
  { id: 65, name: "French Polynesia", PhoneCode: "+689" },
  { id: 66, name: "Gabon", PhoneCode: "+241" },
  { id: 67, name: "Gambia", PhoneCode: "+220" },
  { id: 68, name: "Georgia", PhoneCode: "+7880" },
  { id: 69, name: "Germany", PhoneCode: "+49" },
  { id: 70, name: "Ghana", PhoneCode: "+233" },
  { id: 71, name: "Gibraltar", PhoneCode: "+350" },
  { id: 72, name: "Greece", PhoneCode: "+30" },
  { id: 73, name: "Greenland", PhoneCode: "+299" },
  { id: 74, name: "Grenada", PhoneCode: "+1473" },
  { id: 75, name: "Guadeloupe", PhoneCode: "+590" },
  { id: 76, name: "Guam", PhoneCode: "+671" },
  { id: 77, name: "Guatemala", PhoneCode: "+502" },
  { id: 78, name: "Guinea", PhoneCode: "+224" },
  { id: 79, name: "Guinea - Bissau", PhoneCode: "+245" },
  { id: 80, name: "Guyana", PhoneCode: "+592" },
  { id: 81, name: "Haiti", PhoneCode: "+509" },
  { id: 82, name: "Honduras", PhoneCode: "+504" },
  { id: 83, name: "Hong Kong", PhoneCode: "+852" },
  { id: 84, name: "Hungary", PhoneCode: "+36" },
  { id: 85, name: "Iceland", PhoneCode: "+354" },
  { id: 86, name: "India", PhoneCode: "+91" },
  { id: 87, name: "Indonesia", PhoneCode: "+62" },
  { id: 88, name: "Iran", PhoneCode: "+98" },
  { id: 89, name: "Iraq", PhoneCode: "+964" },
  { id: 90, name: "Ireland", PhoneCode: "+353" },
  { id: 91, name: "Israel", PhoneCode: "+972" },
  { id: 92, name: "Italy", PhoneCode: "+39" },
  { id: 93, name: "Jamaica", PhoneCode: "+1876" },
  { id: 94, name: "Japan", PhoneCode: "+81" },
  { id: 95, name: "Jordan", PhoneCode: "+962" },
  { id: 96, name: "Kazakhstan", PhoneCode: "+7" },
  { id: 97, name: "Kenya", PhoneCode: "+254" },
  { id: 98, name: "Kiribati", PhoneCode: "+686" },
  { id: 99, name: "Korea North", PhoneCode: "+850" },
  { id: 100, name: "Korea South", PhoneCode: "+82" },
  { id: 101, name: "Kuwait", PhoneCode: "+965" },
  { id: 102, name: "Kiribati", PhoneCode: "+686" },
  { id: 103, name: "Kyrgyzstan", PhoneCode: "+996" },
  { id: 104, name: "Laos", PhoneCode: "+856" },
  { id: 105, name: "Latvia", PhoneCode: "+371" },
  { id: 106, name: "Lebanon", PhoneCode: "+961" },
  { id: 107, name: "Lesotho", PhoneCode: "+266" },
  { id: 108, name: "Liberia", PhoneCode: "+231" },
  { id: 109, name: "Libya", PhoneCode: "+218" },
  { id: 110, name: "Latvia", PhoneCode: "+371" },
  { id: 111, name: "Liechtenstein", PhoneCode: "+417" },
  { id: 112, name: "Lithuania", PhoneCode: "+370" },
  { id: 113, name: "Luxembourg", PhoneCode: "+352" },
  { id: 114, name: "Macao", PhoneCode: "+853" },
  { id: 115, name: "Macedonia", PhoneCode: "+389" },
  { id: 116, name: "Madagascar", PhoneCode: "+261" },
  { id: 117, name: "Malawi", PhoneCode: "+265" },
  { id: 118, name: "Malaysia", PhoneCode: "+60" },
  { id: 119, name: "Maldives", PhoneCode: "+960" },
  { id: 120, name: "Mali", PhoneCode: "+223" },
  { id: 121, name: "Malta", PhoneCode: "+356" },
  { id: 122, name: "Marshall Islands", PhoneCode: "+692" },
  { id: 123, name: "Martinique", PhoneCode: "+596" },
  { id: 124, name: "Mauritania", PhoneCode: "+222" },
  { id: 125, name: "Mayotte", PhoneCode: "+269" },
  { id: 126, name: "Mexico", PhoneCode: "+52" },
  { id: 127, name: "Micronesia", PhoneCode: "+691" },
  { id: 128, name: "Moldova", PhoneCode: "+373" },
  { id: 129, name: "Monaco", PhoneCode: "+377" },
  { id: 130, name: "Mongolia", PhoneCode: "+976" },
  { id: 131, name: "Montserrat", PhoneCode: "+1664" },
  { id: 132, name: "Morocco", PhoneCode: "+212" },
  { id: 133, name: "Mozambique", PhoneCode: "+258" },
  { id: 134, name: "Myanmar", PhoneCode: "+95" },
  { id: 135, name: "Namibia", PhoneCode: "+264" },
  { id: 136, name: "Nauru", PhoneCode: "+674" },
  { id: 137, name: "Nepal", PhoneCode: "+977" },
  { id: 138, name: "Netherlands", PhoneCode: "+31" },
  { id: 139, name: "New Caledonia", PhoneCode: "+687" },
  { id: 140, name: "New Zealand", PhoneCode: "+64" },
  { id: 141, name: "Nicaragua", PhoneCode: "+505" },
  { id: 142, name: "Nepal", PhoneCode: "+977" },
  { id: 143, name: "Netherlands", PhoneCode: "+31" },
  { id: 144, name: "New Caledonia", PhoneCode: "+687" },
  { id: 145, name: "Niger", PhoneCode: "+227" },
  { id: 146, name: "Nigeria", PhoneCode: "+234" },
  { id: 147, name: "Niue", PhoneCode: "+683" },
  { id: 148, name: "Norfolk Islands", PhoneCode: "+672" },
  { id: 149, name: "Northern Marianas", PhoneCode: "+670" },
  { id: 150, name: "Norway", PhoneCode: "+47" },
  { id: 151, name: "Oman", PhoneCode: "+968" },
  { id: 152, name: "Palau", PhoneCode: "+680" },
  { id: 153, name: "Panama", PhoneCode: "+507" },
  { id: 154, name: "Papua New Guinea", PhoneCode: "+675" },
  { id: 155, name: "Paraguay", PhoneCode: "+595" },
  { id: 156, name: "Peru", PhoneCode: "+51" },
  { id: 157, name: "Philippines", PhoneCode: "+63" },
  { id: 158, name: "Poland", PhoneCode: "+48" },
  { id: 159, name: "Portugal", PhoneCode: "+351" },
  { id: 160, name: "Puerto Rico", PhoneCode: "+1787" },
  { id: 161, name: "Qatar", PhoneCode: "+974" },
  { id: 162, name: "Reunion", PhoneCode: "+262" },
  { id: 163, name: "Romania", PhoneCode: "+40" },
  { id: 164, name: "Russia", PhoneCode: "+7" },
  { id: 165, name: "Rwanda", PhoneCode: "+250" },
  { id: 166, name: "San Marino", PhoneCode: "+378" },
  { id: 167, name: "Sao Tome Principe", PhoneCode: "+239" },
  { id: 168, name: "Saudi Arabia", PhoneCode: "+966" },
  { id: 169, name: "Senegal", PhoneCode: "+221" },
  { id: 170, name: "Serbia", PhoneCode: "+381" },
  { id: 171, name: "Seychelles", PhoneCode: "+248" },
  { id: 172, name: "Sierra Leone", PhoneCode: "+232" },
  { id: 173, name: "Singapore", PhoneCode: "+65" },
  { id: 174, name: "Slovak Republic", PhoneCode: "+421" },
  { id: 175, name: "Slovenia", PhoneCode: "+386" },
  { id: 176, name: "Solomon Islands", PhoneCode: "+677" },
  { id: 177, name: "Somalia", PhoneCode: "+252" },
  { id: 178, name: "South Africa", PhoneCode: "+27" },
  { id: 179, name: "Spain", PhoneCode: "+34" },
  { id: 180, name: "Sri Lanka", PhoneCode: "+94" },
  { id: 181, name: "St. Helena", PhoneCode: "+290" },
  { id: 182, name: "St. Kitts", PhoneCode: "+1869" },
  { id: 183, name: "St. Lucia", PhoneCode: "+1758" },
  { id: 184, name: "Sudan", PhoneCode: "+249" },
  { id: 185, name: "Sur id: 1,iname", PhoneCode: "+597" },
  { id: 186, name: "Swaziland", PhoneCode: "+268" },
  { id: 187, name: "Sweden", PhoneCode: "+46" },
  { id: 188, name: "Switzerland", PhoneCode: "+41" },
  { id: 189, name: "Syria", PhoneCode: "+963" },
  { id: 190, name: "Taiwan", PhoneCode: "+886" },
  { id: 191, name: "Tajikstan", PhoneCode: "+7" },
  { id: 192, name: "Thailand", PhoneCode: "+66" },
  { id: 193, name: "Togo", PhoneCode: "+228" },
  { id: 194, name: "Tonga", PhoneCode: "+676" },
  { id: 195, name: "Trinidad Tobago", PhoneCode: "+1868" },
  { id: 196, name: "Tunisia", PhoneCode: "+216" },
  { id: 197, name: "Turkey", PhoneCode: "+90" },
  { id: 198, name: "Turkmenistan", PhoneCode: "+7" },
  { id: 199, name: "Turkmenistan", PhoneCode: "+993" },
  { id: 200, name: "Turks Caicos Islands", PhoneCode: "+1649" },
  { id: 201, name: "Tuvalu", PhoneCode: "+688" },
  { id: 202, name: "Uganda", PhoneCode: "+256" },
  { id: 203, name: "UK", PhoneCode: "+44" },
  { id: 204, name: "Ukraine", PhoneCode: "+380" },
  { id: 205, name: "United Arab Emirates", PhoneCode: "+971" },
  { id: 206, name: "Uruguay", PhoneCode: "+598" },
  { id: 207, name: "USA", PhoneCode: "+1" },
  { id: 208, name: "Uzbekistan", PhoneCode: "+7" },
  { id: 209, name: "Vanuatu", PhoneCode: "+678" },
  { id: 210, name: "Vatican City", PhoneCode: "+379" },
  { id: 211, name: "Venezuela", PhoneCode: "+58" },
  { id: 212, name: "Vietnam", PhoneCode: "+84" },
  { id: 213, name: "Virgin Islands - British", PhoneCode: "+84" },
  { id: 214, name: "Virgin Islands - US", PhoneCode: "+84" },

  { id: 215, name: "Wallis Futuna", PhoneCode: "+681" },
  { id: 216, name: "Yemen (North)", PhoneCode: "+969" },
  { id: 217, name: "Yemen (South)", PhoneCode: "+967" },
  { id: 218, name: "Zambia", PhoneCode: "+260" },
  { id: 219, name: "Zimbabwe", PhoneCode: "+260" },
];
