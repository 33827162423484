import React, { useEffect, useState } from 'react';
import filedrop from '../../images/file-drop1.webp';
import { RxCross2 } from 'react-icons/rx';
import second_ellipes from '../../images/Ellipse_17.webp';
import Navbargrey from '../../Components/Navbargrey/Navbargrey';

// importing back and next button aldogwith progress bar
import BackandNext from './BackandNext/BackandNext';
import ProgressBar from './BackandNext/Progressbar';
import { setEncryptedItem } from '../../Components/CommonLibrary/EncryptDecrypt';

const LightFinder7 = () => {
  // ========== euro value setup ==========
  const [openpopups, setOpenpopups] = useState([false]);
  const [fileNames, setFileNames] = useState([]);

  const [files, setFiles] = useState([]);

  const [selectedCurrency, setSelectedCurrency] = useState('€');
  const [amount, setAmount] = useState(1);

  const handleAmountChange = (e) => {
    let inputAmount = parseInt(e.target.value, 10);

    if (inputAmount < 1) {
      inputAmount = 1;
    } else if (inputAmount > 999999) {
      inputAmount = 999999;
    }

    setAmount(inputAmount);
  };

  const handleFile = (index, e) => {
    handleSingleFileUpload(e.target.files[0]);
    const newFiles = [...files, e.target.files[0]];
    setFiles(newFiles);
    close(index);
  };

  const open = (index) => {
    const newOpenpopups = [...openpopups];
    newOpenpopups[index] = true;
    setOpenpopups(newOpenpopups);
  };

  const close = (index) => {
    const newOpenpopups = [...openpopups];
    newOpenpopups[index] = false;
    setOpenpopups(newOpenpopups);
  };

  // const handleFile = (index, e) => {
  //   const newFiles = [...files];
  //   newFiles[index] = e.target.files[0];
  //   setFiles(newFiles);
  //   close(index);
  // };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (index, e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    const newFiles = [...files];
    // handleFileUploadTest(selectedFile);
    newFiles[index] = droppedFile;
    setFiles(newFiles);
    close(index);
  };

  useEffect(() => {
    setEncryptedItem('lightfinder6Images', fileNames);
  }, [fileNames]);

  const handleSingleFileUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    const url = process.env.REACT_APP_API_URL + 'api/lightFinder-upload';
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    const result = await response.json();

    if (result.status === 'success') {
      setFileNames((prevFileNames) => [...prevFileNames, result.fileName]);
    }
  };

  useEffect(() => {
    const storeLightfinder6 = {
      currency: selectedCurrency,
      amount: amount,
    };
    setEncryptedItem('lightfinder6Budget', storeLightfinder6);
  }, [selectedCurrency, amount]);

  return (
    <>
      <div className="h-full overflow-hidden relative min-h-screen bg-zinc-300">
        <header className="relative z-10">
          <Navbargrey />
        </header>
        <div className="px-6 sm:px-26 lg:px-24 xl:px-56 py-10 mb-14 h-full">
          <img
            class="absolute right-0 w-96 md:w-1/2  ms-auto overflow-hidden top-1/2 left-1/2 translate-x-[0%] translate-y-[-50%] select-none"
            src={second_ellipes}
            alt="ellipes"
          />
          <div className="relative font-light">
            <p className="text-sm sm:text-lg">
              7. &nbsp; Upload files (if any). Images, Photos, Plan, Drawings,
              3D Models, Notes etc.
            </p>
            <div className=" text-zinc-100 grid w-fit gap-6 pt-10 ">
              <button
                onClick={() => open(0)}
                className="bg-zinc-200 text-zinc-700 px-4 py-1 sm:ml-8 flex items-center justify-center rounded-sm  cursor-pointer text-xs sm:text-base shadow-md shadow-lightfinder duration-300"
              >
                Upload
              </button>
              {/* Show a list/table of uploaded files */}
              {files.length > 0 && (
                <div className="flex flex-col text-zinc-500 text-sm w-full sm:w-80">
                  {files.map((file, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center gap-2 border-zinc-400 border-b py-1"
                    >
                      <span>{file.name}</span>
                      {/* Remove file button */}
                      <button
                        onClick={() => {
                          const newFiles = [...files];
                          newFiles.splice(index, 1);
                          setFiles(newFiles);
                        }}
                        className="text-red-500"
                      >
                        <RxCross2 />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="py-14">
              <p className="text-sm sm:text-lg">
                8. &nbsp; Do you have a budget in mind, if not our team will
                help you identify one.
              </p>
              <div className="flex flex-row items-center gap-5 md:gap-10 sm:pl-8">
                {/* Euro input */}
                <input
                  type="number"
                  min={1}
                  max={999999}
                  value={amount}
                  onChange={handleAmountChange}
                  className="w-52 mt-4 py-2 px-2 outline-none bg-zinc-200 border border-zinc-300 shadow shadow-lightfinder duration-300"
                />
                <select
                  value={selectedCurrency}
                  onChange={(e) => setSelectedCurrency(e.target.value)}
                  className="mt-4 py-1 text-center font-light text-zinc-700 text-base border border-zinc-300 outline-none bg-zinc-200 shadow-md shadow-lightfinder duration-300"
                >
                  <option value="€" className="font-light">
                    € Euro
                  </option>
                  <option value="$" className="font-light">
                    $ Dollar
                  </option>
                  <option value="₹" className="font-light">
                    ₹ Rupees
                  </option>
                </select>
              </div>
            </div>
          </div>

          {/* ===== next & back button ===== */}
          <div className="pt-8 sm:pt-14 relative max-w-4xl grid grid-cols-1 gap-y-8 lg:grid-cols-2 justify-between items-center">
            <BackandNext backLink="/lightfinder5" nextLink="/lightfinder7" />
            <ProgressBar currentPage={6} totalPages={7} />
          </div>
        </div>
      </div>

      {openpopups.map(
        (isOpen, index) =>
          isOpen && (
            <div
              key={index}
              className="font-light py-4 z-10 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 bg-zinc-200 p-4 rounded-lg shadow-lg"
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(index, e)}
            >
              <div className="flex justify-between py-2">
                <p className="text-base md:text-xl">Upload file</p>
                <button
                  onClick={() => close(index)}
                  className=" text-xl sm:text-4xl"
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="border-dashed border-2 border-[#7246FD] h-80 flex flex-col items-center justify-center">
                <div className="w-14 md:w-24 lg:w-fit">
                  <img src={filedrop} alt="" />
                </div>
                <div className="text-center text-sm md:text-base lg:text-lg pt-4">
                  <p>
                    Drag and drop your file here or
                    <label
                      htmlFor={`fileInput${index}`}
                      className="text-[#7246FD] cursor-pointer"
                    >
                      &nbsp; browse.
                    </label>
                  </p>

                  <input
                    type="file"
                    id={`fileInput${index}`}
                    className="hidden"
                    onChange={(e) => handleFile(index, e)}
                  />
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
};

export default LightFinder7;
