import { React, useState, useEffect, useRef } from 'react';
import Navbargrey from '../../../Components/Navbargrey/Navbargrey';
import Contactpopup from '../../../Components/Contactpopup/Contactpopup';
import LightBenefitsPop from '../../../Components/LightBenefitsPopupContent/LightBenefitsPopupContent';
import Ellipsecarbon from '../../../images/Ellipse-carbon.webp';
import { buildStyles } from 'react-circular-progressbar';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { RxCross2 } from 'react-icons/rx';
import { MdDone } from 'react-icons/md';
import 'react-circular-progressbar/dist/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CCGetres.css';

export const CCGetres = () => {
  const [countriesList, setCountriesList] = useState([]);
  const [options, setOptions] = useState([]);
  const [countryPopup, setCountryPopup] = useState(false);
  const [countryValue, setCountryValue] = useState('');
  const [footprintEfficiency, setFootprintEfficiency] = useState(0);
  const [token, setToken] = useState('');
  const [reportId, setReportId] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    footprintEfficiency: 0,
  });
  const [usagePopup, setUsagePopup] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [inputDays, setInputDays] = useState(0);
  const [inputHours, setInputHours] = useState(0);
  const [benefitsPop, setBenefitsPop] = useState(false);
  const [electriCityPopup, setElectriCityPopup] = useState(false);
  const [electriCityRate, setElectriCityRate] = useState(0);
  const [electriCityRateInput, setElectriCityRateInput] = useState(0);
  const [wattValue, setWattValue] = useState('');
  const [quantityValue, setQuantityValue] = useState(0);
  const [tableDataTwo, setTableDataTwo] = useState([]);
  const latestWattagesRef = useRef([]);
  const [upgradeLEDData, setUpgradeLEDData] = useState([]);
  const [multipliedValues, setMultipliedValues] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [overallTotalWattage, setOverallTotalWattage] = useState(0);
  const [overallTotalSavingInWattages, setoverallTotalSavingInWattages] =
    useState(0);
  const [month, setMonth] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const [showPopupContact, setshowPopupContact] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationMode, setVerificationMode] = useState('email');
  const [showPopup, setShowPopup] = useState(false);

  const [carbonFootprint, setCarbonFootprint] = useState(0);
  const [savingsPotential, setSavingsPotential] = useState(0);
  const [cost, setCost] = useState(0);
  const [roi, setRoi] = useState(0);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const cost2 = cost;
  let formattedNumber = cost2.toLocaleString();

  const handleSendCode = (e) => {
    e.preventDefault();
    if (verificationMode === 'email') {
      if (email === '') {
        toast.error('Please enter your email');
        return;
      }
      const formdata = new FormData();
      formdata.append('email', email);
      const requestOptions = {
        method: 'POST',
        body: formdata,
      };
      fetch(
        `${process.env.REACT_APP_API_URL}carbon_verification_request`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          toast.success(response.message);
          if (response.status == 'success') {
            setVerificationMode('otp');
          }
        })
        .catch((error) => console.error('Error fetching countries:', error));
    } else if (verificationMode === 'otp') {
      if (otp === '') {
        toast.error('Security code is required');
        return;
      }

      let formdata = new FormData();
      formdata.append('token', otp);
      formdata.append('consumption_facility', overallTotalWattage);
      formdata.append('lamp_object', JSON.stringify(tableDataTwo));
      formdata.append('hours_per_day', hours);
      formdata.append('use_per_year', days);
      formdata.append('country', countryValue);
      formdata.append('footprint', carbonFootprint);
      formdata.append(
        'potential',
        isNaN(savingsPotential) ? 0 : savingsPotential
      );
      formdata.append('total_euros', isNaN(cost) ? 0 : cost);
      formdata.append('total_months', isNaN(roi) ? 0 : roi);

      const requestOptions = {
        method: 'POST',
        body: formdata,
      };
      fetch(
        `${process.env.REACT_APP_API_URL}carbon_calculator_req`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status == 'success') {
            setShowResult(false);
            setBlurBackground(false);
            setVerificationMode('email');
            setToken(otp);
            setOtp('');
            setEmail('');
            setReportId(response.data.report_id);
          }

          toast.success(response.message);
        })
        .catch((error) => console.error('Error fetching countries:', error));
    }
  };

  const handleBenefitsPop = () => {
    setBenefitsPop(true);
    setBlurBackground(true);
  };

  const handleBenefitsClosePopup = () => {
    setBenefitsPop(false);
    setBlurBackground(false);
  };

  const handleCountryChange = (e) => {
    const selectedCountryName = e.target.value;
    const selectedCountryData = countriesList.find(
      (country) => country.country === selectedCountryName
    );

    if (selectedCountryData) {
      setSelectedCountry({
        name: selectedCountryData.country,
        footprintEfficiency: parseFloat(selectedCountryData.factor),
      });
      setCountryValue(selectedCountryData.country);
      setFootprintEfficiency(parseFloat(selectedCountryData.factor));
    }
  };

  const handleButtonClickCountry = () => {
    setCountryPopup(false);
    setBlurBackground(false);
    setSelectedCountry({ name: '', footprintEfficiency: 0 });
  };

  const handleDaysChange = (e) => {
    const value = e.target.value;
    setInputDays(
      isNaN(value) || value === ''
        ? 0
        : Math.max(0, Math.min(parseInt(value, 10), 365))
    );
  };

  const handleHoursChange = (e) => {
    const value = e.target.value;
    setInputHours(
      isNaN(value) || value === ''
        ? 0
        : Math.max(0, Math.min(parseInt(value, 10), 24))
    );
  };

  const handleButtonClickHoursDays = () => {
    setUsagePopup(false);
    setBlurBackground(false);
    if (inputDays !== 0) {
      setDays(inputDays);
    }

    if (inputHours !== 0) {
      setHours(inputHours);
    }
    setInputDays(0);
    setInputHours(0);
  };

  const handleButtonClickElectricityRate = () => {
    setElectriCityPopup(false);
    setBlurBackground(false);
    setElectriCityRateInput(0);
  };

  const handleSelectChange = (e) => {
    const selectedType = e.target.value;

    setSelectedOption(selectedType);

    const selectedLampOption = options.find(
      (lamp) => lamp.type === selectedType
    );
    const selectedLampWattages = selectedLampOption
      ? selectedLampOption.data.map((item) => item.Watt)
      : [];

    setWattValue('');
    latestWattagesRef.current = selectedLampWattages;
  };

  const handleAddToTable = () => {
    if (selectedOption !== '' && quantityValue > 0 && wattValue !== '') {
      const selectedLampOption = options.find(
        (lamp) => lamp.type === selectedOption
      );
      if (selectedLampOption) {
        const selectedLampData = selectedLampOption.data.find(
          (item) => parseFloat(item.Watt) === parseFloat(wattValue)
        );
        if (selectedLampData) {
          const newItem = {
            lampType: selectedOption,
            watt: wattValue,
            quantity: quantityValue,
          };

          const updatedTableData = [...tableDataTwo, newItem];
          setTableDataTwo(updatedTableData);

          const upgradeLEDWattage = selectedLampData.Upgrade_watt;
          const upgradeLEDPrice = selectedLampData.LED_eqv_price;

          const EnergyExpenditure =
            ((wattValue * quantityValue) / 1000) *
            hours *
            days *
            electriCityRate;
          const totalSavingsInWattage =
            (wattValue - upgradeLEDWattage) * quantityValue;

          const ExpenditureSavings =
            (totalSavingsInWattage / 1000) * hours * days * electriCityRate;

          const multipliedValue = wattValue * quantityValue;

          const updatedMultipliedValues = [
            ...multipliedValues,
            multipliedValue,
          ];

          setMultipliedValues(updatedMultipliedValues);

          const totalCostPerLampType = quantityValue * upgradeLEDPrice;

          const upgradeLEDItem = {
            lampType: selectedOption,
            watt: parseFloat(wattValue),
            quantity: quantityValue,
            UpdgradeWattage: upgradeLEDWattage,
            EnergyExpenditure: parseFloat(EnergyExpenditure.toFixed(0)),
            ExpenditureSavings: parseFloat(ExpenditureSavings.toFixed(0)),
            upgradePrice: upgradeLEDPrice,
            totalWattagePerLampType: multipliedValue,
            totalSavingsInWattage: totalSavingsInWattage,
            totalCostPerLampType: totalCostPerLampType,
          };
          setSelectedOption('');
          setWattValue('');
          setQuantityValue(0);
          const val = [...upgradeLEDData, upgradeLEDItem];

          const overallTotalWattagePerLampType = val.reduce(
            (accumulator, item) => accumulator + item.totalWattagePerLampType,
            0
          );

          setOverallTotalWattage(overallTotalWattagePerLampType);

          const overallTotalSavingsInWattage = val.reduce(
            (accumulator, item) => accumulator + item.totalSavingsInWattage,
            0
          );

          setoverallTotalSavingInWattages(overallTotalSavingsInWattage);

          const overallTotalCostPerLampType = val.reduce(
            (accumulator, item) => accumulator + item.totalCostPerLampType,
            0
          );

          setCost(overallTotalCostPerLampType);

          const overallExpenditureSavings = val.reduce(
            (accumulator, item) =>
              accumulator + parseFloat(item.ExpenditureSavings),
            0
          );
          setMonth(overallExpenditureSavings);

          const overallEnergyExpenditure = val.reduce(
            (accumulator, item) => accumulator + item.EnergyExpenditure,
            0
          );

          const updatedVal = val.map((item) => ({
            ...item,
            overallTotalWattagePerLampType: overallTotalWattagePerLampType,
            overallTotalSavingsInWattage: overallTotalSavingsInWattage,
            overallTotalCostPerLampType: overallTotalCostPerLampType,
            overallExpenditureSavings: overallExpenditureSavings,
            overallEnergyExpenditure: overallEnergyExpenditure,
          }));

          setUpgradeLEDData(updatedVal);
        }
      }
    }
  };

  const handleDeleteItem = (index) => {
    const deletedItem = tableDataTwo[index];

    // Remove the item from tableDataTwo
    const updatedTableData = tableDataTwo.filter((_, i) => i !== index);
    setTableDataTwo(updatedTableData);

    // Remove corresponding values from other state variables
    const updatedMultipliedValues = multipliedValues.filter(
      (_, i) => i !== index
    );
    setMultipliedValues(updatedMultipliedValues);

    const updatedUpgradeLEDData = upgradeLEDData.filter(
      (item) => item.lampType !== deletedItem.lampType
    );

    // Recalculate overall values based on updated data
    const overallTotalWattagePerLampType = updatedUpgradeLEDData.reduce(
      (accumulator, item) => accumulator + item.totalWattagePerLampType,
      0
    );
    setOverallTotalWattage(overallTotalWattagePerLampType);

    const overallTotalSavingsInWattage = updatedUpgradeLEDData.reduce(
      (accumulator, item) => accumulator + item.totalSavingsInWattage,
      0
    );
    setoverallTotalSavingInWattages(overallTotalSavingsInWattage);

    const overallTotalCostPerLampType = updatedUpgradeLEDData.reduce(
      (accumulator, item) => accumulator + item.totalCostPerLampType,
      0
    );
    setCost(overallTotalCostPerLampType);

    const overallExpenditureSavings = updatedUpgradeLEDData.reduce(
      (accumulator, item) => accumulator + parseFloat(item.ExpenditureSavings),
      0
    );
    setMonth(overallExpenditureSavings);

    const overallEnergyExpenditure = updatedUpgradeLEDData.reduce(
      (accumulator, item) => accumulator + item.EnergyExpenditure,
      0
    );

    const updatedVal = updatedUpgradeLEDData.map((item) => ({
      ...item,
      overallTotalWattagePerLampType,
      overallTotalSavingsInWattage,
      overallTotalCostPerLampType,
      overallExpenditureSavings,
      overallEnergyExpenditure,
    }));

    // Update the state with the recalculated values
    setUpgradeLEDData(updatedVal);
  };

  const handleResult = () => {
    setShowResult(true);
    setBlurBackground(true);
  };

  const handleButtonClickRepeat = () => {
    // Reload the page
    window.location.reload();
  };

  const getFontSizeClass = (number) => {
    if (number && number.toString().length > 4) {
      return 'text-lg';
    } else if (number && number.toString().length > 6) {
      return 'text-base';
    } else {
      return 'text-2xl';
    }
  };

  const commonPopupStyle =
    'bg-zinc-300 p-6 border border-zinc-950 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-sm shadow-2xl';
  const togglePopup = () => {
    setshowPopupContact(!showPopupContact);
    setBlurBackground(!blurBackground);
  };

  // Calculate the carbon footprint whenever the component mounts or when the constants change
  useEffect(() => {
    const currentCarbonFootprint = (
      (overallTotalWattage * hours * days * footprintEfficiency) /
      1000
    ).toFixed(0);
    setCarbonFootprint(parseFloat(currentCarbonFootprint));
  }, [overallTotalWattage, hours, days, footprintEfficiency]);

  // Calculate savings potential whenever the component mounts or when the constants change
  useEffect(() => {
    const calculatedSavingsPotential =
      overallTotalWattage !== 0
        ? ((overallTotalSavingInWattages / overallTotalWattage) * 100).toFixed(
            1
          )
        : 0;
    setSavingsPotential(parseFloat(calculatedSavingsPotential));
  }, [overallTotalWattage, overallTotalSavingInWattages]);

  // Calculate ROI whenever the component mounts or when the constants change
  useEffect(() => {
    if (!isNaN(cost) && !isNaN(month) && month !== 0) {
      const calculatedRoi = (12 * (cost / month)).toFixed(0);
      setRoi(parseFloat(calculatedRoi));
    } else {
      setRoi(0);
    }
  }, [cost, month]);

  // useEffect to update electriCityRate whenever electriCityRateInput changes
  useEffect(() => {
    if (electriCityRateInput !== 0) {
      setElectriCityRate(electriCityRateInput);
    }
  }, [electriCityRateInput]);

  useEffect(() => {
    // Fetch countries data from the API
    fetch(`${process.env.REACT_APP_API_URL}countries`)
      .then((response) => response.json())
      .then((response) => setCountriesList(response?.data))
      .catch((error) => console.error('Error fetching countries:', error));

    fetch(`${process.env.REACT_APP_API_URL}lamps_group`)
      .then((response) => response.json())
      .then((response) => {
        setOptions(response?.data);
      })
      .catch((error) => console.error('Error fetching countries:', error.message));

    if (localStorage.getItem('report_id') && localStorage.getItem('token')) {
      setToken(localStorage.getItem('token'));
      setReportId(localStorage.getItem('report_id'));
      fetch(
        `${
          process.env.REACT_APP_API_URL
        }carbon_calculator_find?report_id=${localStorage.getItem('report_id')}`
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status == 'success') {
            setOverallTotalWattage(response.data.consumption_facility);
            setTableDataTwo(JSON.parse(response.data.lamp_object));
            setHours(response.data.hours_per_day);
            setDays(response.data.use_per_year);
            setCountryValue(response.data.country);
            // here make the calculation
            // const CurrentCarbonFootprint = data.footprint;
            // const SavingsPotential = data.potential;
            // setCost(data.total_euros)
            // roi = data.total_months
          }
        })
        .catch((error) => console.error('Error fetching countries:', error));
    }
  }, []);

  const handleDownloadPdf = async (token, reportId) => {
    const url = process.env.REACT_APP_API_URL + 'carbon-calculate-pdf-generate';

    const Parameters = {
      token: token,
      reportId: reportId,
    };
    const requestBody = JSON.stringify(Parameters);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    });

    const result = await response.json();
    if (result?.status == 'error') {
      toast(result?.message);
    } else {
      window.open(process.env.REACT_APP_API_URL + result.message, '_blank');
    }
  };

  return (
    <>
      <div
        className={`h-full overflow-hidden relative min-h-screen bg-zinc-300 ${
          blurBackground ? "blur-background" : ""
        }`}
      >
        <img
          className="absolute w-96 md:w-1/2 right-0 ms-auto overflow-hidden top-1/2 translate-x-[0%] translate-y-[-50%]"
          src={Ellipsecarbon}
          alt="ellipes"
        />
        <header className="relative z-10">
          <Navbargrey />
        </header>
        <main className="relative px-6 sm:px-26 lg:px-28 xl:px-56 py-20">
          <section className="max-w-4xl">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
              <div
                className="h-28 md:h-44 p-2 bg-zinc-200 text-zinc-700 leading-tight font-light rounded-sm shadow-md shadow-dashboard duration-300 cursor-pointer"
                onClick={() => {
                  setCountryPopup(true);
                  setBlurBackground(true);
                }}
              >
                <h1>Country</h1>
                <h1 className="text-start pt-6 md:pt-12">
                  Carbon emission factor
                </h1>
                <p className="pt-2 text-xl text-[#03B829]">
                  {countryValue === "" ? "__________" : countryValue}
                </p>
              </div>

              {/* ===== usagecss ===== */}

              <div
                className="lg:col-start-2  md:col-span-2 h-40 sm:h-44 p-2 bg-zinc-200 text-zinc-700 leading-tight font-light rounded-sm shadow-md shadow-dashboard duration-300 cursor-pointer"
                onClick={() => {
                  setUsagePopup(true);
                  setBlurBackground(true);
                }}
              >
                <h1>Usage details</h1>
                <div className="flex flex-row items-center justify-center gap-10 pt-4">
                  <div className="w-20 h-20 md:w-24 md:h-24 text-center">
                    <CircularProgressbarWithChildren
                      value={hours}
                      maxValue="24"
                      styles={buildStyles({
                        strokeLinecap: "butt",
                        pathColor: "#03B829",
                        trailColor: "#d4d4d8",
                      })}
                    >
                      <p className="font-light text-xl text-[#03B829]">
                        {" "}
                        {hours}{" "}
                      </p>
                      <p className="font-light text-xs">hrs</p>
                    </CircularProgressbarWithChildren>
                    <p className="font-light text-[12px] pt-2">per day</p>
                  </div>
                  <div className="w-20 h-20 md:w-24 md:h-24 text-center">
                    <CircularProgressbarWithChildren
                      value={days}
                      maxValue="365"
                      styles={buildStyles({
                        strokeLinecap: "butt",
                        pathColor: "#03B829",
                        trailColor: "#D4D4D8",
                      })}
                    >
                      <p className="font-light text-xl text-[#03B829]">
                        {days} <br />{" "}
                      </p>
                      <p className="font-ight text-xs"> days </p>
                    </CircularProgressbarWithChildren>
                    <p className="font-light text-[12px] pt-2"> per year</p>
                  </div>
                </div>
              </div>

              {/* ===== unitrate ===== */}
              <div
                className="h-24 sm:h-44 bg-zinc-200 text-zinc-700 p-2 leading-tight font-light flex flex-col justify-between rounded-sm shadow-md shadow-dashboard duration-300 cursor-pointer"
                onClick={() => {
                  setElectriCityPopup(true);
                  setBlurBackground(true);
                }}
              >
                <h1>
                  Electricity <br />
                  unit cost
                </h1>
                <h2 className="font-light text-xl text-[#03B829] md:pb-4 ">
                  <span className="mr-2">{electriCityRate}</span>€ / kWh
                </h2>
              </div>
              <div className=" h-36 sm:h-44 hidden sm:flex flex-col justify-between font-light">
                <button
                  onClick={handleButtonClickRepeat}
                  className="h-12 bg-zinc-200 text-zinc-700 p-2 text-start text-base rounded-sm shadow-md shadow-dashboard duration-300"
                >
                  <h1>Repeat analysis</h1>
                </button>
                {token && reportId ? (
                  <button
                    onClick={() => handleDownloadPdf(token, reportId)}
                    className=" h-12 p-2 text-start text-base bg-gradient-to-r from-[#03B829] to-[#ABDD21] text-zinc-50 rounded-sm shadow-lg shadow-dashboard duration-300"
                  >
                    <h1>Download Report</h1>
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      toast.error("Please calculate carbon emissions first")
                    }
                    className=" h-12 p-2 text-start text-base bg-gradient-to-r from-[#03B829] to-[#ABDD21] text-zinc-50 rounded-sm shadow-lg shadow-dashboard duration-300"
                  >
                    <h1>Download Report</h1>
                  </button>
                )}
                <button className="  h-12 pl-2 text-base font-ight bg-[#c3c3c4] text-zinc-200 text-start rounded-sm shadow shadow-dashboard duration-300">
                  <p>Buy Lamps - </p>
                  <p>Coming Soon</p>
                </button>
              </div>
            </div>

            {/* ===== 2nd row section ===== */}
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 pt-8">
              <div className="flex flex-col gap-4 ">
                <div className="h-24 md:h-36 p-2 bg-zinc-200 text-zinc-700 leading-tight font-light flex flex-col justify-between rounded-sm shadow-md shadow-dashboard duration-300">
                  <h1>Lighting electrical consumption</h1>
                  <h2 className="text-xl text-[#03B829] sm:pb-4">
                    {overallTotalWattage} kW
                  </h2>
                </div>
                <div
                  onClick={handleBenefitsPop}
                  className="h-16 sm:h-36  p-2 bg-gradient-to-b from-[#03B829] to-[#ABDD21]  rounded-sm shadow-xl shadow-dashboard duration-300 cursor-pointer"
                >
                  <h2 className="text-xl font-light text-zinc-100">
                    Benefits of light upgradations.
                  </h2>
                </div>
              </div>
              {/* ===== lamp ===== */}
              <div className="md:col-span-2 lg:col-span-3 flex flex-col lg:h-76 bg-zinc-200 font-light text-zinc-700 overflow-x-hidden custom-scrollbar shadow-md shadow-dashboard duration-300">
                <div className="bg-zinc-200 p-2">
                  <h3 className="">Build your lamp configuration</h3>
                  <div className="flex flex-col lg:flex-row items-start justify-center p-2">
                    <div className="flex-1">
                      <label
                        htmlFor="dropdown"
                        className="block pb-1.5 text-xs font-light"
                      >
                        Lamp Type
                      </label>
                      <select
                        id="dropdown"
                        value={selectedOption}
                        onChange={handleSelectChange}
                        className="py-1 bg-zinc-200 w-52 text-sm hover:text-zinc-700 text-zinc-700 rounded-sm hover:bg-zinc-50 shadow-inner border border-zinc-400 outline-none
                      font-light cursor-pointer"
                      >
                        <option className="border text-sm" value="">
                          Select Lamp
                        </option>
                        {options.map((option, index) => (
                          <option
                            key={index}
                            value={option.type}
                            className="bg-zinc-400 text-sm text-[#00ff00] font-light cursor-pointer"
                          >
                            {option.type}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="lg:ml-1.5 pt-2 lg:pt-0">
                      <label
                        htmlFor="watt"
                        className="block pb-1.5 text-xs font"
                      >
                        Watt(W)
                      </label>
                      <select
                        id="watt"
                        value={wattValue}
                        onChange={(e) => setWattValue(e.target.value)}
                        disabled={!selectedOption}
                        className="py-1 px-1 text-sm hover:text-zinc-700 text-zinc-700 rounded-sm hover:bg-zinc-50 bg-zinc-200 shadow-inner border border-zinc-400 outline-none
                      font-light cursor-pointer"
                      >
                        <option className="text-sm" value="">
                          Select Watt
                        </option>
                        {[...new Set(latestWattagesRef.current)]
                          .sort((a, b) => a - b) // Sort numerically
                          .map((wattage, index) => (
                            <option
                              className="cursor-pointer"
                              key={index}
                              value={wattage}
                            >
                              {wattage}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="relative -ml-2 lg:ml-1.5 pt-2 lg:pt-0">
                      <label
                        htmlFor="quantity"
                        className="block pb-1.5 px-2 text-xs font-light"
                      >
                        Quantity
                      </label>
                      <div className="relative px-2">
                        <input
                          type="number"
                          id="quantity"
                          // step="0.001"
                          className="pl-6 pr- py-1 w-20 text-sm hover:text-zinc-700 text-zinc-700 rounded-sm shadow-inner hover:bg-zinc-50 bg-zinc-200 border border-zinc-400 outline-none"
                          style={{ appearance: "textfield" }}
                          value={quantityValue === 0 ? "" : quantityValue}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            setQuantityValue(
                              isNaN(value)
                                ? 0
                                : Math.max(0, Math.min(value, 999999))
                            );
                          }}
                          disabled={!selectedOption}
                        />
                      </div>
                    </div>
                    <div className="pt-4 p-2">
                      <button
                        onClick={handleAddToTable}
                        className="bg-gradient-to-r from-[#03B829] via-[#63C743] to-[#ABDD21] w-8 h-8 mt-1 text-zinc-50 rounded-full flex items-center justify-center"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                {tableDataTwo.length > 0 && (
                  <div className="w-full ">
                    <table className="w-full">
                      <tbody className="w-full">
                        {tableDataTwo.map((item, index) => (
                          <div
                            key={index}
                            className="grid grid-cols-6 w-full px-2 text-sm"
                          >
                            <div className="col-span-3 border border-b-zinc-300 px-4 py-1 text-start text-zinc-600   ">
                              {item.lampType}
                            </div>
                            <div className="border border-b-zinc-300 flex items-center justify-center text-zinc-600 ">
                              {item.watt}
                            </div>
                            <div className="border border-b-zinc-300 flex items-center justify-center text-zinc-600  ">
                              {item.quantity}
                            </div>
                            <div className="border border-b-zinc-300 flex items-center justify-center font-medium text-base ">
                              <button
                                className="text-red-600"
                                onClick={() => handleDeleteItem(index)}
                              >
                                <RxCross2 />
                              </button>
                            </div>
                          </div>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              {/* ================= */}
              <div className="flex flex-col gap-4 ">
                <div
                  className=" h-36 p-2 text-xl cursor-pointer font-light text-zinc-50 bg-gradient-to-br from-[#03B829] from-5% to-[#ABDD21] rounded-sm shadow-xl shadow-dashboard duration-300"
                  onClick={handleResult}
                >
                  <h1>Calculate</h1>
                  <h2>carbon</h2>
                  <h3>emissions</h3>
                </div>
                <div
                  className="h-16 sm:h-36 p-2  custom-scrollbar bg-gradient-to-tr from-[#03B829] from-5% to-[#ABDD21]  rounded-sm cursor-pointer shadow-xl shadow-dashboard duration-300"
                  onClick={togglePopup}
                >
                  <h1 className="text-lg font-light leading-tight text-zinc-50">
                    Book a free light audit.
                  </h1>
                </div>
              </div>
            </div>

            {token && reportId ? (
              <>
                {/* ===== 3rd row section ===== */}
                <div className="h-full font-light text-zinc-700 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-8">
                  <div className="bg-zinc-200 h-full p-2 rounded-sm shadow-md shadow-dashboard duration-300">
                    <h1 className="text-lg">Annual carbon emissions</h1>
                    <div className="flex items-center justify-center pt-6 ">
                      <div className="text-center w-36 h-36 p-2 flex items-center justify-center ">
                        <CircularProgressbarWithChildren
                          value={carbonFootprint ? carbonFootprint : 0}
                          styles={buildStyles({
                            pathColor: "#979797",
                            trailColor: "#979797",
                          })}
                          className={`${
                            token && reportId ? "progress-shadow" : ""
                          }`}
                        >
                          <div>
                            <strong
                              className={`font-light text-[#03B829] ${getFontSizeClass(
                                carbonFootprint
                              )}`}
                            >
                              {token && reportId ? <>{carbonFootprint}</> : "0"}
                              <span className="block text-base font-light text-zinc-600">
                                kg of CO₂
                              </span>
                            </strong>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>
                  <div className="bg-zinc-200 h-full  p-2 rounded-sm shadow-md shadow-dashboard duration-300">
                    <h1 className="text-lg">Annual savings potential</h1>
                    <div className="flex items-center justify-center pt-6">
                      <div className="text-center w-36 h-36 p-2  flex items-center justify-center">
                        <CircularProgressbarWithChildren
                          value={token && reportId ? savingsPotential : 0}
                          styles={
                            token && reportId
                              ? buildStyles({
                                  strokeLinecap: "butt",
                                  pathTransitionDuration: 0.9,
                                  pathColor: "#03B829",
                                  trailColor: "#D4D4D8",
                                })
                              : buildStyles({
                                  strokeLinecap: "butt",
                                  pathTransitionDuration: 0.9,
                                  pathColor: "#585858",
                                  trailColor: "#D4D4D8",
                                })
                          }
                          className={`${
                            token && reportId ? "progress-shadow" : ""
                          }`}
                        >
                          <div>
                            <strong className="text-2xl font-light text-[#03B829]">
                              {token && reportId ? (
                                <>{savingsPotential} %</>
                              ) : (
                                "0 %"
                              )}
                            </strong>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                  </div>
                  <div className="h-full lg:col-start-3 lg:col-span-2 flex flex-col justify-between gap-4 pb-4 md:pb-0">
                    <div className="bg-zinc-200 px-8 sm:px-10 py-7 rounded-sm shadow-md shadow-dashboard duration-300">
                      <h2 className="text-[#03B829] text-2xl">
                        {token && reportId ? (
                          <div>
                            <div className="flex gap-2">
                              <div className="text-[#03B829]">
                                {formattedNumber}{" "}
                              </div>
                              <div className="text-zinc-700 text-lg">Euros</div>
                            </div>
                            <div className="">
                              <p className="text-zinc-700 text-base">
                                Approximate cost for light upgrade{" "}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flex gap-2 ">
                              <div className="text-[#03B829] w-fit">0</div>
                              <div className="">
                                <div className="text-zinc-700 text-lg">
                                  Euros
                                </div>
                                <p className="text-zinc-700 text-base">
                                  Approximate cost for light upgrade{" "}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </h2>
                    </div>
                    <div className="bg-zinc-200 px-8 sm:px-10 py-7 rounded-sm shadow-md shadow-dashboard duration-300">
                      <h2 className="text-[#03B829] text-2xl">
                        {token && reportId ? (
                          <div>
                            <div className="flex gap-2">
                              <div className="text-[#03B829]">{roi} </div>
                              <div className="text-zinc-700 text-lg">
                                Months
                              </div>
                            </div>
                            <div className="">
                              <p className="text-zinc-700 text-base">
                                Return of investment{" "}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flex gap-2 ">
                              <div className="text-[#03B829] w-fit">0</div>
                              <div className="">
                                <div className="text-zinc-700 text-lg">
                                  Months
                                </div>
                                <p className="text-zinc-700 text-base">
                                  Return of investment{" "}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </h2>

                      <div className=" h-44 flex flex-col justify-between font-light sm:hidden">
                        <button
                          onClick={handleButtonClickRepeat}
                          className="h-12 bg-[#f4f4f7] p-2 text-base rounded-sm shadow shadow-dashboard duration-300"
                        >
                          <h1>Repeat analysis</h1>
                        </button>
                        <button
                          onClick={() => handleDownloadPdf(token, reportId)}
                          className=" h-12 p-2 text-base bg-gradient-to-r from-[#03B829] to-[#ABDD21] text-zinc-50 rounded-sm shadow-md shadow-dashboard duration-300"
                        >
                          <h1>Download Report</h1>
                        </button>
                        <button className="  h-12 pl-2 text-base  bg-[#c3c3c4] text-gray-600 text-start rounded-sm shadow-md shadow-dashboard duration-300">
                          <p>Buy Lamps - </p>
                          <p>Coming Soon</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </section>
        </main>
      </div>
      {/* ==========country Selction============ */}
      {countryPopup && (
        <div className={`${commonPopupStyle} w-[300px] sm:w-[480px]`}>
          <div className="flex items-center justify-between">
            <h1 className="text-zinc-700 font-light text-xl">Country</h1>
            <button
              onClick={() => {
                setCountryPopup(false);
                setBlurBackground(false);
                setSelectedCountry({ name: "", footprintEfficiency: 0 });
              }}
              className="text-xl"
            >
              <RxCross2 />
            </button>
          </div>
          <div className="pt-5">
            <form>
              <div className="input-field pt-2 sm:py-12">
                <select
                  id="countrySelect"
                  name="selectedCountry"
                  value={selectedCountry.name}
                  onChange={handleCountryChange}
                  className="bg-zinc-200 font-light cursor-pointer py-1 outline-none border border-zinc-400 px-2 hover:bg-zinc-200 rounded-sm shadow-inner hover:text-zinc-700"
                >
                  <option className="cursor-pointer" value="" disabled>
                    Select a country
                  </option>
                  {countriesList.map((country) => (
                    <option
                      key={country.code}
                      value={country.country}
                      className=" font-light cursor-pointer  w-full bg-zinc-300"
                    >
                      {country.country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center justify-end pt-5">
                <button
                  className={`p-2 text-xl text-zinc-300  ${
                    selectedCountry.name
                      ? "bg-[#03B829] cursor-pointer"
                      : "bg-zinc-400"
                  }`}
                  onClick={handleButtonClickCountry}
                  disabled={selectedCountry.name === ""}
                >
                  <MdDone />
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* ========== days and hours selection ===================== */}
      {usagePopup && (
        <div className={`${commonPopupStyle} w-[300px] sm:w-[480px]`}>
          <div className="flex items-center justify-between">
            <h1 className="text-zinc-700 font-light text-xl">Usage details</h1>
            <button
              onClick={() => {
                setUsagePopup(false);
                setBlurBackground(false);
                setInputDays(0);
                setInputHours(0);
              }}
              className="text-xl"
            >
              <RxCross2 />
            </button>
          </div>
          <div className="pt-8">
            <form>
              <div className="input-field flex flex-col gap-1 font-light w-36">
                <div className="w-16">
                  <label htmlFor="hours">Hours</label>
                </div>
                <input
                  className="bg-zinc-200 py-1 shadow-inner outline-none border border-zinc-400 px-2"
                  type="number"
                  id="hours"
                  placeholder="00"
                  value={inputHours || ""}
                  onChange={handleHoursChange}
                  max="24"
                />
              </div>
              <div className="input-field flex flex-col gap-1 pt-6 font-light w-36">
                <div className="w-16">
                  <label htmlFor="days" className="">
                    Days
                  </label>
                </div>
                <input
                  className="bg-zinc-200 py-1 outline-none shadow-inner border border-zinc-400 px-2"
                  type="number"
                  id="days"
                  placeholder="00"
                  value={inputDays || ""}
                  onChange={handleDaysChange}
                  max="366"
                />
              </div>
              <div className="flex items-center justify-end pt-8 ">
                <button
                  className={`p-2 text-xl text-zinc-200  ${
                    inputDays !== 0 || inputHours !== 0
                      ? "bg-[#03B829] cursor-pointer"
                      : "hover:bg-none hover:text-none hover:border-none bg-zinc-400"
                  }`}
                  onClick={handleButtonClickHoursDays}
                  disabled={inputDays === 0 && inputHours === 0}
                >
                  <MdDone />
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* =============== Electricity unit rate =================== */}
      {electriCityPopup && (
        <div className={`${commonPopupStyle} w-[300px] sm:w-[480px]`}>
          <div className="flex items-center justify-between">
            <h1 className="text-zinc-700 font-light text-xl">
              Electricity unit rate
            </h1>
            <button
              onClick={() => {
                setElectriCityPopup(false);
                setBlurBackground(false);
                setElectriCityRateInput(0);
              }}
              className="text-xl"
            >
              <RxCross2 />
            </button>
          </div>
          <div className="py-10">
            <form>
              <div className="input-field flex flex-col gap-2 font-light text-zinc-700 w-36">
                <label htmlFor="electricityRate">Rate (€ / kWh)</label>
                <input
                  className="bg-zinc-200 py-1 outline-none shadow-inner rounded-sm border border-zinc-400 px-2"
                  type="number"
                  step="0.001"
                  placeholder="0.00"
                  id="electricityRate"
                  value={electriCityRateInput === 0 ? "" : electriCityRateInput}
                  onChange={(e) =>
                    setElectriCityRateInput(
                      Math.max(0, Math.min(parseFloat(e.target.value), 999999))
                    )
                  }
                />
              </div>
            </form>
          </div>
          <div className="flex items-center justify-end pt-5 font-light">
            <button
              className={`p-2 text-xl text-zinc-200  ${
                electriCityRateInput !== 0
                  ? "bg-[#03B829] cursor-pointer"
                  : "hover:bg-none hover:text-none hover:border-none bg-zinc-400"
              }`}
              onClick={handleButtonClickElectricityRate}
              disabled={electriCityRateInput === 0}
            >
              <MdDone />
            </button>
          </div>
        </div>
      )}

      {/* Benefits pop up */}
      {benefitsPop && (
        <div
          className={`${commonPopupStyle} w-[340px] sm-[600px] md:w-[800px] bg-gradient-to-br from-[#ABDD21] from-40% to-[#03B829]  rounded-sm cursor-pointer shadow-xl`}
        >
          <LightBenefitsPop onClose={handleBenefitsClosePopup} />
        </div>
      )}

      {/* ============= email verification =============== */}
      {showResult && (
        <div className={`${commonPopupStyle} w-[300px] sm:w-[480px]`}>
          <div className="flex items-center justify-between">
            <h1 className="text-zinc-700 font-light text-lg">
              {verificationMode === "email"
                ? "Email Verification"
                : "Code sent to email"}
            </h1>
            <button
              onClick={() => {
                setShowResult(false);
                setBlurBackground(false);
              }}
              className="text-xl"
            >
              <RxCross2 />
            </button>
          </div>
          <div className="">
            <form>
              {verificationMode === "email" && (
                <div className="input-field flex flex-col text-zinc-700 w-64 font-light text-xs pb-12">
                  <label className="my-12" htmlFor="email"></label>
                  <input
                    className="bg-zinc-200 py-2 shadow-inner outline-none rounded-sm border border-zinc-400 text-xs px-2"
                    type="email"
                    id="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
              )}

              {verificationMode === "otp" && (
                <div className="input-field flex flex-col text-zinc-700 w-44 font-light text-xs">
                  <label className="my-12" htmlFor="otp"></label>
                  <input
                    className="bg-zinc-200 py-2 shadow-inner outline-none rounded-sm border border-zinc-400 text-sm px-2"
                    type="number"
                    id="otp"
                    placeholder="Enter security code"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
              )}

              <div className="flex items-center justify-end pt-10 ">
                <button
                  onClick={handleSendCode}
                  className="bg-[#03B829] py-2 px-3 text-sm  text-zinc-200 tracking-wide"
                >
                  {verificationMode === "email" ? "Send" : "Verify"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="contactpop-section">
        {showPopupContact && <Contactpopup onClose={togglePopup} />}
      </div>
      <ToastContainer position="top-center" />
    </>
  );
};
export default CCGetres;
