import React from "react";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import Navbargrey from "../../../../Components/Navbargrey/Navbargrey";
import Footergrey from "../../../../Components/Footergrey/Footergrey";
import "../Articlehome/ArticleHome.css";
import blog1 from "../../../../images/blog-1.webp";
import blog2 from "../../../../images/blog-2.webp";
import blog3 from "../../../../images/blog-3.webp";
import blog4 from "../../../../images/blog-4.webp";
import blog5 from "../../../../images/blog-5.webp";
import blog6 from "../../../../images/blog-6.webp";
import blog7 from "../../../../images/blog-7.webp";
import blog8 from "../../../../images/blog-8.webp";
import blog9 from "../../../../images/blog-9.webp";

import AOS from "aos";
import "aos/dist/aos.css";

export const ArticleHome = () => {
  useEffect(() => {
    AOS.init({ once: "true" });
  }, []);

  return (
    <>
      <header className="absolute w-full z-10">
        <Navbargrey />
      </header>
      <main>
        <section className="hero p-6 sm:px-26 lg:px-24 xl:p-56 relative py-16 text-zinc-300">
          <h1 className="pt-28 font-extralight  text-6xl md:text-9xl ">
            Blog
          </h1>
          <div className="">
            <a href="/pollinators">
              <h2 className="mt-44 font-light text-3xl sm:text-5xl/normal max-w-5xl ">
                Poor agriculture and dying pollinators can urban lighting be
                blamed ?
              </h2>
            </a>
          </div>
        </section>
        <section className="img-gallery bg-zinc-400 py-16 sm:py-32 xl:py-64 ">
          <div className="grid md:grid-cols-2 gap-3 p-6 sm:px-26 lg:px-24 xl:px-56 pb-4">
            <a href="/immersive" className="flex">
              <div className="border-2 border-red-500 overflow-hidden flex flex-col">
                <figure className="flex-shrink-0">
                  <LazyLoadImage
                    alt="Immersive hotels with Light"
                    effect="blur"
                    className="h-full w-full object-center object-cover"
                    src={blog1}
                  />
                </figure>
                <div className="px-3 py-4 flex-grow">
                  <p className="text-zinc-800 font-light text-sm sm:text-base xl:text-lg">
                    Immersive hotels with Light
                  </p>
                </div>
              </div>
            </a>

            <div className="grid grid-cols-2 gap-3">
              <a href="/festivals" className="flex">
                <div className="border-2 border-red-500 overflow-hidden flex flex-col">
                  <figure className="flex-shrink-0">
                    <LazyLoadImage
                      alt="Lights in Bergamo and Brescia"
                      effect="blur"
                      className="h-full w-full object-center object-cover"
                      src={blog2}
                    />
                  </figure>
                  <div className="px-3 py-4 flex-grow">
                    <p className="text-zinc-800 font-light text-sm sm:text-base xl:text-lg">
                      Lights in Bergamo and Brescia
                    </p>
                  </div>
                </div>
              </a>

              <a href="/lightai" className="flex">
                <div className="border-2 border-red-500 overflow-hidden flex flex-col">
                  <figure className="flex-shrink-0">
                    <LazyLoadImage
                      alt="Intelligence of Light"
                      effect="blur"
                      className="h-full w-full object-center object-cover"
                      src={blog3}
                    />
                  </figure>
                  <div className="px-3 py-4 flex-grow">
                    <p className="text-zinc-800 font-light text-sm sm:text-base xl:text-lg">
                      Intelligence of Light
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-3 p-6 sm:px-26 lg:px-24 xl:px-56">
            <div className="grid grid-cols-2 gap-3">
              <a href="/victorian" className="flex">
                <div className="border-2 border-red-500 overflow-hidden">
                  <figure>
                    <LazyLoadImage
                      alt="Sustainable lighting from victorian era"
                      effect="blur"
                      className="h-full w-full object-center object-cover"
                      src={blog4}
                    />
                  </figure>
                  <div className="px-3 py-4">
                    <p className="text-zinc-800 font-light text-sm sm:text-base xl:text-lg">
                      Sustainable lighting from victorian era.
                    </p>
                  </div>
                </div>
              </a>

              <a href="/ecosystem" className="flex">
                <div className="border-2 border-red-500 overflow-hidden">
                  <figure>
                    <LazyLoadImage
                      alt="Light carbon in sustainable ecosystem"
                      effect="blur"
                      className="h-full w-full object-center object-cover"
                      src={blog5}
                    />
                  </figure>
                  <div className="px-3 py-4">
                    <p className="text-zinc-800 font-light text-sm sm:text-base xl:text-lg">
                      Light carbon in sustainable ecosystem.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <a href="/tourism" className="flex">
              <div className="border-2 border-red-500 overflow-hidden h-full">
                <figure>
                  <LazyLoadImage
                    alt="Re-inventing tourism with Light"
                    effect="blur"
                    className="h-full w-full object-center object-cover"
                    src={blog6}
                  />
                </figure>
                <div className="px-3 py-4">
                  <p className="text-zinc-800 font-light text-sm sm:text-base xl:text-lg">
                    Re-inventing tourism with Light
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div className="grid md:grid-cols-2 gap-3 p-6 sm:px-24 lg:px-28 xl:px-56 pt-4 pb-24">
            <a href="/human" className="flex">
              <div className="border-2 border-red-500 overflow-hidden">
                <figure>
                  <LazyLoadImage
                    alt="Human Centric Lighting Design"
                    effect="blur"
                    className="h-full w-full object-center object-cover"
                    src={blog9}
                  />
                </figure>
                <div className="px-3 py-4">
                  <p className="text-zinc-800 font-light text-sm sm:text-base xl:text-lg">
                    Human centric lighting design
                  </p>
                </div>
              </div>
            </a>

            <div className="grid grid-cols-2 gap-3">
              <a href="/lighting" className="flex">
                <div className="border-2 border-red-500 overflow-hidden">
                  <figure>
                    <LazyLoadImage
                      alt="Luxury of lighting design"
                      effect="blur"
                      className="h-full w-full object-center object-cover"
                      src={blog8}
                    />
                  </figure>
                  <div className="px-3 py-4">
                    <p className="text-zinc-800 font-light text-sm sm:text-base xl:text-lg">
                      Luxury of lighting design
                    </p>
                  </div>
                </div>
              </a>

              <a href="/dancing" className="flex">
                <div className="border-2 border-red-500 overflow-hidden">
                  <figure>
                    <LazyLoadImage
                      alt="Dancing to light tunes"
                      effect="blur"
                      className="h-full w-full object-center object-cover"
                      src={blog7}
                    />
                  </figure>
                  <div className="px-3 py-4">
                    <p className="text-zinc-800 font-light text-sm sm:text-base xl:text-lg">
                      Dancing to light tunes
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <Footergrey />
      </footer>
    </>
  );
};

export default ArticleHome;
