import React, { useState } from "react";
import "./Lengthdropdown.css";

const LengthDropdown = (props) => {
  const {setDimensionsDropDownValue}=props;
  const [selectedUnit, setSelectedUnit] = useState("cm");
  const lengthUnits = ["cm", "m", "km", "in", "ft", "yd", "mi"];

  const handleUnitChange = (event) => {
    setDimensionsDropDownValue(event.target.value);
    setSelectedUnit(event.target.value);
  };

  return (
    <div>
      <select
        id="length-dropdown"
        value={selectedUnit}
        onChange={handleUnitChange}
        className="outline-none py-1 px-3 text-center font-light text-zinc-800 text-base border border-zinc-300  bg-zinc-200 shadow-md rounded-sm shadow-lightfinder duration-300"
      >
        {lengthUnits.map((unit) => (
          <option key={unit} value={unit}>
            {unit}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LengthDropdown;
