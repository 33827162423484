import React from 'react';
import { useState } from 'react';
import FooterLogo from '../../images/Footerlogokaashblack.webp';
import Partnershipicons from '../../images/Partnershipiconsdark.webp';
import { GrFacebookOption } from 'react-icons/gr';
import { GrInstagram } from 'react-icons/gr';
import { GrLinkedinOption } from 'react-icons/gr';
import Contactpop from '../../Components/Contactpopup/Contactpopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };
  const [subscribe, setSubscribe] = useState('');

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSubscribe(value);
  };

  const handleSubscribe = async () => {
    try {
      const Parameters = {
        email: subscribe,
      };

      // Convert Parameters to JSON string
      const requestBody = JSON.stringify(Parameters);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}subscribe`,
        {
          method: 'POST',
          body: requestBody,
        }
      );

      const result = await response.json();
      if (result.status == 'success') {
        toast(result?.message);
      } else {
        console.error('Error submitting the form:', result.message);
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  };
  return (
    <>
      <footer class="bg-zinc-500">
        <div class="container px-6 py-6 mx-auto">
          <div class="grid grid-cols-2 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
            <div class="sm:col-span-2 col-start-1 col-span-2 ">
              <h1 class="max-w-lg text-lg font-extralight tracking-tight text-zinc-800 xl:text-2xl">
                Sign up for light stories !
              </h1>
              <div class="flex flex-col mx-auto mt-4 space-y-3 md:space-y-0 md:flex-row">
                <input
                  id="email"
                  type="text"
                  class="px-4 py-2 font-extralight text-zinc-800 bg-zinc-600 focus:border-orange-700 focus:outline-none focus:ring focus:ring-opacity-100 focus:ring-orange-700"
                  placeholder="Your email"
                  value={subscribe}
                  onChange={handleInputChange}
                />

                <button
                  class="w-full px-6 py-2 text-mg font-light tracking-wider text-zinc-400 transition-colors duration-300 transform md:w-auto md:mx-4 focus:outline-none bg-zinc-600 square-lg hover:bg-orange-700 focus:ring focus:ring-gray-300 focus:ring-opacity-80"
                  onClick={handleSubscribe}
                >
                  Subscribe
                </button>
              </div>
              <p className="max-w-md text-xs font-extralight tracking-tight text-zinc-700 mt-5">
                By signing up to receive emails, you agree to our Privacy
                Policy. We treat your info responsibly. Unsubscribe anytime.
              </p>
            </div>

            <div class="">
              <p class="text-lg font-extralight tracking-tight text-zinc-800 xl:text-2xl"></p>
              <div class="flex flex-col items-start mt-1 space-y-1  text-zinc-800 w-40 font-extralight text-xs xl:text-base">
                <button onClick={togglePopup}>Contact us</button>
                <a href="/ourprojects">Projects</a>
                <a href="/whatwedo">Services</a>
                <a href="/whoarewe">Company</a>
                <a href="/lightfinder">Light Finder</a>
                <a href="/carbonemission">Light Calculator</a>
              </div>
            </div>
            <div class="">
              <img
                class=" w-1/2 ms-auto"
                src={Partnershipicons}
              />
            </div>
          </div>
          <hr class="my-3 border-zinc-600 md:my-4 " />
          <div class="flex items-center justify-between">
            <a href="#">
              <img class="w-auto h-4 " src={FooterLogo} alt="" />
            </a>
            <div class="flex -mx-2 text-zinc-700">
              <a
                href="https://www.facebook.com/kaash.eu"
                target="_blank"
                class="mx-2 ! transition-colors duration-300 hover:text-blue-500 text-lg sm:text-2xl"
                aria-label="Reddit"
              >
                <GrFacebookOption />
              </a>
              <a
                href="https://www.instagram.com/kaash.eu/"
                target="_blank"
                class="mx-2 ! transition-colors duration-300 hover:text-blue-500 text-lg sm:text-2xl"
                aria-label="Facebook"
              >
                <GrInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/kaash/"
                target="_blank"
                class="mx-2 ! transition-colors duration-300 hover:text-blue-500 text-lg sm:text-2xl"
                aria-label="Github"
              >
                <GrLinkedinOption />
              </a>
            </div>
          </div>
        </div>
      </footer>
      <div className="contactpop-section">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
      <ToastContainer position="top-center" />
    </>
  );
};

export default Footer;
