import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Blogsustainability from "../../../../images/Blogsustainability.webp";
import Blogsustainabilitychart from "../../../../images/Blogsustainabilitychart.webp";
import Blogsustainabilitychart3 from "../../../../images/Blogsustainabilitychart3.webp";
import Navbargrey from "../../../../Components/Navbargrey/Navbargrey";
import Footergrey from "../../../../Components/Footergrey/Footergrey";
export const Ecosystem = () => {
  return (
    <div className="bg-zinc-400">
      <header className="relative z-10">
        <Navbargrey />
      </header>
      <div class=" flex flex-row justify-center">
        <div class="bg-zinc-400">
          {/* main heading and date */}
          <p class="bg-zinc-400 p-6 sm:p-26 lg:p-24 xl:p-56 py-32">
            <span class="text-zinc-800 font-extralight text-3xl sm:text-5xl md::text-6xl lg:text-8xl">
              Light carbon in sustainable ecosystem
            </span>
            <br />
            <br />
            <span class="text-zinc-800 font-extralight text-sm md:text-lg lg:text-xl ">
              updated jan 2023
            </span>
          </p>

          {/* image */}
          <div className="">
            <LazyLoadImage
              alt="Blogsustainability"
              effect="blur"
              className="left-0 w-full  object-cover object-center"
              src={Blogsustainability}
            />
          </div>

          {/* first grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="text-zinc-800 font-light text-xl md:text-2xl xl:text-3xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              “Human-centric light planning creates impactful designs but also
              reduce carbon emissions of light infrastructure”
            </div>
            <div>
              <div class="text-zinc-800 font-light text-sm md:base xl:text-xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
                The world has been a witness to surging temperatures and rising
                sea levels, thanks to complications of global warming and
                climate change. As long as we don&#39;t make the essential
                lifestyle adjustments, these issues will continue to wreak
                havoc.
                <br />
                <br />
                <br />
                Among many comes light, something so mechanical yet intuitive.
                In between the mesmerising hues of dusk and dawn, lies the real
                need of artificial lighting. City streets, commercial centres,
                office complexes or transport hubs, light is a necessity to
                function. According to estimates, electricity accounts for about
                half of our carbon footprint, with lighting accounting for good
                quarter in buildings.
              </div>
              <div class="text-zinc-800 font-light text-sm md:base xl:text-xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
                Lighting carbon emissions have reduced by half over a decade
                given the widespread usage of LEDs. Despite all efforts, they
                seem to have fallen short in light of the current climate
                crisis. Governments and organisations have been trying smart
                solutions, as sustainability moved up on their agenda. When a
                mayor in a European city ordered turning off streetlights after
                midnight to save energy, the chaos by residents finally caused
                the order to be reversed. Such measures are not only frivolous,
                but also question the competence of the authorities. The
                popularity of intelligent lighting systems has been growing for
                years, but only a few examples are available to illustrate their
                utility. Technical incompetence or economic challenges with
                unjustified returns on investments may be the primary reasons
                behind this.
              </div>
            </div>
          </div>

          {/* second-grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Blogsustainabilitychart"
                effect="blur"
                className="max-w-5/12 pt-2 object-cover object-center"
                src={Blogsustainabilitychart3}
              />
            </div>
            <div class="text-zinc-900 font-light text-sm md:base xl:text-xl max-w-xl">
              Concept of human centric light planning can be a stepping stone
              toward carbon neutral Europe by 2050. Starting with light planning
              for space function, its user and architecture, the wasteful
              flooding of lights in grid ceilings for the obsession of lux
              (lust) level can be avoided. Blending daylight in light planning
              further improves the efficiency. In the outdoor environment,
              simplistic occupancy controllers can significantly reduce
              municipal light bills by up to 80% further with a big boost to
              reduction in carbon emissions. There are a number of
              cost-effective solutions with good return-on-investment, waiting
              for the action to improve the health of our planet.
            </div>
          </div>

          {/* third-grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Blogsustainabilitychart"
                effect="blur"
                className="max-w-5/12 pt-2 object-cover object-center"
                src={Blogsustainabilitychart}
              />
            </div>

            <div class="text-zinc-900 font-light text-sm md:base xl:text-xl max-w-xl">
              Standing at the quarter of 21st century, fired by passion and
              driven by innovation, we may end up with the finest technologies
              available out there. Be that as it may, smart groups of
              individuals are still required to materialise the best of all
              ideas. As lighting consultants, we&#39;ve come a long way, yet the
              question still remains: “Is our approach towards creating
              sustainable lighting designs good enough? “ “Is it?”
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 p-6 sm:py-12 md:p-24 xl:p-48">
            <p class="text-zinc-800 font-light text-sm md:base xl:text-xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              <br />
              If you would like to discuss further or you have an interesting
              light story to share, write to us at moien@kaash.eu.
            </p>
          </div>
          <Footergrey />
        </div>
      </div>
    </div>
  );
};
export default Ecosystem;
