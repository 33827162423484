import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import dimage9 from "../../../../images/Bloglightluxurybar.webp";
import dimage8 from "../../../../images/Bloglightluxurybedroom.webp";
import dimage35 from "../../../../images/Bloglightluxuryoffice.webp";
import dimage14 from "../../../../images/Bloglightluxury.webp";
import Navbargrey from "../../../../Components/Navbargrey/Navbargrey";
import Footergrey from "../../../../Components/Footergrey/Footergrey";

export const Lighting = () => {
  return (
    <div className="bg-zinc-400">
      <header className="relative z-10">
        <Navbargrey />
      </header>
      <div className="flex flex-row justify-center">
        <div className="bg-zinc-400">
          {/* main heading and date */}
          <p className="p-6 sm:p-26 lg:p-24 xl:p-56 py-32">
            <span className="text-zinc-800 font-extralight text-3xl sm:text-5xl md::text-6xl lg:text-8xl">
              Luxury of lighting design
              <br />
              <br />
            </span>
            <span className="text-zinc-800 font-extralight text-sm md:text-lg lg:text-xl">
              updated jan 2023
            </span>
          </p>

          <LazyLoadImage
            alt="Luxury of lighting design"
            effect="blur"
            className="left-0 w-full h-730 object-center object-cover"
            src={dimage14}
          />

          {/* first grid  */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div className="text-zinc-800 font-light text-xl md:text-2xl xl:text-3xl pt-10 md:pt-20 xl:pt-30 max-w-xl">
              “Designing with multi dimensional identity of light as art, form,
              function and emotion”
            </div>
            <div className="text-zinc-800 font-light text-sm md:base xl:text-xl pt-10 md:pt-20 xl:pt-30 max-w-xl"></div>
          </div>

          {/* second grid  */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Luxury of lighting design"
                effect="blur"
                className="max-w-5/12 pt-2 object-center object-cover"
                src={dimage8}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
              Design and style evolve over time. Some gradually altering
              themselves along the way while others disappear in favour of newer
              expressions. Looking for personality reflection in products and
              lifestyle, people&#39;s desire for luxury is endless, lately
              expressed in NFTs.
              <br />
              <br />
              In a built environment, a luxurious affair is complemented by
              upscale amenities, sumptuous furnishings, and opulent accents.
              Exotic art collections, celebrated designs, and breath-taking
              views are never enough. Bringing this experience together is the
              unsung hero of light. Often limited to beautifully crafted
              chandeliers and tabletop icons, light adds immense depth to the
              built environment.
            </div>
          </div>

          {/* third grid  */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Luxury of lighting design"
                effect="blur"
                className="max-w-5/12 pt-2 object-center object-cover"
                src={dimage9}
              />
            </div>
            <div class="text-zinc-800 font-light text-sm md:base xl:text-xl max-w-xl">
              As an art form, it can influence human emotions and spatial
              feelings, adding an invisible character to the environment. A
              richly constructed space gets its much-needed spirit from
              sophisticated lighting design. The lighting world is trying to
              eliminate the indifference towards light planning by impressing
              people with the possibilities of thoughtful human-centric light
              planning.
            </div>
          </div>

          {/* image */}
          <figure className="flex flex-row justify-center align-items">
            <LazyLoadImage
              alt="Luxury of lighting design"
              effect="blur"
              className="w-full h-730 object-center object-cover"
              src={dimage35}
            />
          </figure>
          <figcaption class="flex flex-row justify-center content-center italic">
            {" "}
            Conceptual representation of futuristic lighting in hospitality
          </figcaption>

          {/* fourth grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 p-6 sm:py-12 md:p-24 xl:p-48">
            <div class="text-zinc-800 font-light text-sm md:text-base xl:text-xl max-w-xl">
              <br />
              If you would like to discuss further or you have an interesting
              light story to share, write to us at moien@kaash.eu.
            </div>
            <div class="text-zinc-800 font-light text-sm md:text-lg lg:text-xl">
              The way light falls and reflects creates a desired environment to
              support space function and drive people’s moods. Accents of light,
              precisely highlighting the masterpieces of paintings, sculptures,
              or even furniture, can create drama for an intriguing atmosphere.
              Soft illumination of architecture or vertical surfaces adds warmth
              to the environment. Just like furnishings, color tones from light
              can set the mood for a cozy, business or tropical environment.
              Moving from all things bright for attraction or all things dark
              for drama, spaces need to be adorned with the play of light and
              shadow, enough to support function yet dramatic to attract. The
              first layer of light begins with space planning in conjunction
              with interior design, adding on more layers as the function
              evolves.
              <br />
              Controlled light can create an intimate space in a public
              environment or raise the pulse with color and speed. This power of
              light, when used thoughtfully, adds a layer of luxury.
              Well-crafted light scenes set a benchmark of luxury and opulence.
            </div>
          </div>
        </div>
      </div>
      <Footergrey />
    </div>
  );
};
export default Lighting;
