import { React, useEffect, useState } from 'react';
import LengthDropdown from './Lengthdropdown/Lengthdropdown';
import AreaDropdown from './Areadropdown/Areadropdown';
import second_ellipes from '../../images/Ellipse_17.webp';
import Navbargrey from '../../Components/Navbargrey/Navbargrey';
import { Link } from 'react-router-dom';

// importing back and next button aldogwith progress bar
import BackandNext from './BackandNext/BackandNext';
import ProgressBar from './BackandNext/Progressbar';
import { setEncryptedItem } from '../../Components/CommonLibrary/EncryptDecrypt';

const LightFinder6 = () => {
  const [selected, setSelected] = useState(() => {
    const savedSelected = localStorage.getItem('selected');
    return savedSelected !== null ? JSON.parse(savedSelected) : 0;
  });

  useEffect(() => {
    localStorage.setItem('selected', JSON.stringify(selected));
  }, [selected]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('selected');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const [selectedName, setSelectedName] = useState(null);

  const [dimensionsLength, setDimensionsLength] = useState('');
  const [dimensionsBreadth, setDimensionsBreadth] = useState('');
  const [dimensionsHeight, setDimensionsHeight] = useState('');
  const [dimensionsDropDownValue, setDimensionsDropDownValue] = useState('cm');

  useEffect(() => {
    if (dimensionsLength !== '' && dimensionsBreadth !== '' && dimensionsHeight !== '') {
      const storeLightfinder5 = {
        applicationArea: selectedName,
        type: 'dimensions',
        dimensionsOrArea: {
          length: dimensionsLength,
          breadth: dimensionsBreadth,
          height: dimensionsHeight,
        },
        dimensionsOrValue: dimensionsDropDownValue,
      };
      localStorage.setItem('lightfinder5', JSON.stringify(storeLightfinder5));
    }
  }, [dimensionsLength, dimensionsBreadth, dimensionsHeight, dimensionsDropDownValue, selectedName]);

  const [area, setArea] = useState('');
  const [areaDropDownValue, setAreaDropDownValue] = useState('m2');

  useEffect(() => {
    if (area !== '') {
      const storeLightfinder5 = {
        applicationArea: selectedName,
        type: 'area',
        dimensionsOrArea: area,
        dimensionsOrValue: areaDropDownValue,
      };

      localStorage.setItem('lightfinder5', JSON.stringify(storeLightfinder5));
    }
  }, [area, selectedName, areaDropDownValue]);

  const handleClick = (index, name) => {
    setSelected(index);
    setSelectedName(name);
  };

  const handleDimensionsChange = (type, value) => {
    switch (type) {
      case 'Length':
        setDimensionsLength(value);
        break;
      case 'Breadth':
        setDimensionsBreadth(value);
        break;
      case 'Height':
        setDimensionsHeight(value);
        break;
      default:
        break;
    }

    setArea('');
  };

  const handleAreaChange = (value) => {
    if (value !== '') {
      setArea(value);
    } else {
      setArea('');
    }

    setDimensionsLength('');
    setDimensionsBreadth('');
    setDimensionsHeight('');
  };
  // const [selected, setSelected] = useState(() => {
  //   const savedSelected = localStorage.getItem('selected');
  //   return savedSelected !== 0 ? JSON.parse(savedSelected) : 0;
  // });

  // useEffect(() => {
  //   localStorage.setItem('selected', JSON.stringify(selected));
  // }, [selected]);

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem('selected');
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // const handleClick = (index) => {
  //   setSelected(index);
  // };

  // // const [selected, setSelected] = useState(null);
  // const [selectedName, setSelectedName] = useState(null);

  // //Dimensions
  // const [dimensionsLength, setDimensionsLength] = useState('');
  // const [dimensionsBreadth, setDimensionsBreadth] = useState('');
  // const [dimensionsHeight, setDimensionsHeight] = useState('');
  // const [dimensionsDropDownValue, setDimensionsDropDownValue] = useState('cm');

  // useEffect(() => {
  //   if (dimensionsLength != '') {
  //     const storeLightfinder5 = {
  //       applicationArea: selectedName,
  //       type: 'dimensions',
  //       dimensionsOrArea: {
  //         length: dimensionsLength,
  //         breadth: dimensionsBreadth,
  //         height: dimensionsHeight,
  //       },
  //       dimensionsOrValue: dimensionsDropDownValue,
  //     };
  //     setEncryptedItem('lightfinder5', storeLightfinder5);
  //   }
  // }, [
  //   dimensionsLength,
  //   dimensionsBreadth,
  //   dimensionsHeight,
  //   dimensionsDropDownValue,
  //   selectedName,
  //   dimensionsDropDownValue,
  // ]);
  // //Area
  // const [area, setArea] = useState('');
  // const [areaDropDownValue, setAreaDropDownValue] = useState('m2');

  // useEffect(() => {
  //   if (area != '') {
  //     const storeLightfinder5 = {
  //       applicationArea: selectedName,
  //       type: 'area',
  //       dimensionsOrArea: area,
  //       dimensionsOrValue: areaDropDownValue,
  //     };

  //     setEncryptedItem('lightfinder5', storeLightfinder5);
  //   }
  // }, [area, selectedName, areaDropDownValue]);
  // // const handleClick = (index, name) => {
  // //   setSelected(index);
  // //   setSelectedName(name);
  // // };

  // const handleDimensionsChange = (type, value) => {
  //   // Update the state based on the dimension type
  //   switch (type) {
  //     case 'Length':
  //       setDimensionsLength(value);
  //       break;
  //     case 'Breadth':
  //       setDimensionsBreadth(value);
  //       break;
  //     case 'Height':
  //       setDimensionsHeight(value);
  //       break;
  //     default:
  //       break;
  //   }

  //   setArea('');
  // };

  // const handleAreaChange = (value) => {
  //   if (value != '') {
  //     setArea(value);
  //   } else {
  //     setArea('');
  //   }

  //   setDimensionsLength('');
  //   setDimensionsBreadth('');
  //   setDimensionsHeight('');
  // };

  return (
    <>
      <div className="h-full overflow-hidden relative min-h-screen bg-zinc-300">
        <header className="relative z-10">
          <Navbargrey />
        </header>
        <div className="px-6 sm:px-26 lg:px-24 xl:px-56 pt-5">
          <img
            class="absolute right-0 w-96 md:w-1/2  ms-auto overflow-hidden top-1/2 left-1/2 translate-x-[0%] translate-y-[-50%] select-none"
            src={second_ellipes}
            alt="ellipes"
          />
          {/* ====== 6th point ====== */}
          <div className="relative font-light">
            <div>
              <p className="text-sm sm:text-lg text-zinc-700 pt-4">
                5. &nbsp; Choose the application area.
              </p>
            </div>
            <div className=" grid grid-cols-1 gap-y-3 w-fit sm:flex sm:flex-row sm:gap-2 md:gap-12 pt-4 sm:pt-8 sm:pl-8 text-xs sm:text-sm">
              <div
                className={`${
                  selected === 0
                    ? 'bg-[#7246fd] text-zinc-100'
                    : 'bg-zinc-200 text-zinc-700'
                }  p-2  sm:px-6 flex items-center justify-center rounded-sm shadow-md shadow-lightfinder duration-300 cursor-pointer`}
                onClick={() => handleClick(0, 'Interior')}
              >
                <p>Interior</p>
              </div>
              <div
                className={`${
                  selected === 1
                    ? 'bg-[#7246fd] text-zinc-100'
                    : 'bg-zinc-200 text-zinc-700'
                } p-2   sm:px-6 flex items-center justify-center rounded-sm shadow-md shadow-lightfinder duration-300 cursor-pointer`}
                onClick={() => handleClick(1, 'Exterior')}
              >
                <p>Exterior</p>
              </div>
              <div
                className={`${
                  selected === 2
                    ? 'bg-[#7246fd] text-zinc-100'
                    : 'bg-zinc-200 text-zinc-700'
                } p-2   sm:px-6 flex items-center justify-center rounded-sm shadow-md shadow-lightfinder duration-300 cursor-pointer`}
                onClick={() => handleClick(2, 'Mix-use')}
              >
                <p>Mix-use</p>
              </div>
            </div>
          </div>

          {/* ======7th point ====== */}
          <div className="relative pt-12 sm:pt-14 font-light">
            <div>
              <p className="text-sm sm:text-lg">
                6. &nbsp; Enter space dimensions or area.
              </p>
            </div>

            <div className="relative pb-4 flex flex-col xl:flex-row text-xs sm:text-sm">
              <div className="sm:pl-8 pt-4 sm:pt-8 flex flex-col gap-y-3">
                <div className="flex items-center gap-6 xl:gap-10">
                  <span className=" w-16 sm:w-20 md:w-16">Length</span>
                  <input
                    className="py-1 md:w-28 px-2 w-24 border border-zinc-300 outline-none bg-zinc-200 shadow shadow-lightfinder duration-300"
                    type="number"
                    min="0"
                    onChange={(e) =>
                      handleDimensionsChange('Length', e.target.value)
                    }
                    value={dimensionsLength}
                  ></input>
                  <LengthDropdown
                    setDimensionsDropDownValue={setDimensionsDropDownValue}
                  />
                </div>
                <div className="flex  items-center gap-6 xl:gap-10">
                  <span className=" w-16 sm:w-20 md:w-16">Breadth</span>
                  <input
                    className="py-1 md:w-28 px-2 w-24 border border-zinc-300 outline-none bg-zinc-200 shadow shadow-lightfinder duration-300"
                    type="number"
                    min="0"
                    onChange={(e) =>
                      handleDimensionsChange('Breadth', e.target.value)
                    }
                    value={dimensionsBreadth}
                  ></input>
                  {/* <LengthDropdown /> */}
                </div>
                <div className="flex items-center gap-6 xl:gap-10">
                  <span className="w-16 sm:w-20 md:w-16">Height</span>
                  <input
                    className="py-1 md:w-28 px-2 w-24 border border-zinc-300 outline-none bg-zinc-200 shadow shadow-lightfinder duration-300"
                    type="number"
                    min="0"
                    onChange={(e) =>
                      handleDimensionsChange('Height', e.target.value)
                    }
                    value={dimensionsHeight}
                  ></input>
                  {/* <LengthDropdown /> */}
                </div>
                <div className="flex items-center gap-6 xl:gap-10 sm:hidden">
                  <span className="w-16 sm:w-20 md:w-16">Area</span>
                  <input
                    className="py-1 md:w-28 px-2 w-24 border border-zinc-300 outline-none bg-zinc-200 shadow shadow-lightfinder duration-300"
                    type="number"
                    min="0"
                  ></input>
                  <AreaDropdown setAreaDropDownValue={setAreaDropDownValue} />
                </div>
              </div>

              <div className="pl-8 py-4 sm:py-8">
                <ul class=" hidden xl:flex flex-col items-center mx-10">
                  <li class=" h-16 border-l border-zinc-700 "></li>
                  <li class="my-4 text-xl font">OR</li>
                  <li class="h-16 border-l border-zinc-700 "></li>
                </ul>
              </div>

              <div className="hidden sm:block sm:pl-8 pt-8 ">
                <div className="flex items-center gap-6 ">
                  <span className="w-20 md:w-16">Area</span>
                  <input
                    className="py-1 md:w-28 px-2 w-24 border border-zinc-300 outline-none bg-zinc-200 shadow shadow-lightfinder duration-300"
                    type="number"
                    min="0"
                    onChange={(e) => handleAreaChange(e.target.value)}
                    value={area}
                  ></input>
                  <AreaDropdown setAreaDropDownValue={setAreaDropDownValue} />
                </div>
              </div>
            </div>
          </div>

          {/* ===== next & back button ===== */}
          <div className="relative max-w-4xl sm:pt-8 grid grid-cols-1 gap-y-4 lg:grid-cols-2 justify-between items-center">
            <BackandNext
              backLink="/lightfinder4"
              nextLink="/lightfinder6"
              // disabled={selected===null}
            />
            <ProgressBar currentPage={5} totalPages={7} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LightFinder6;
