import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: 'ravi',
};

// Create a app slice using createSlice
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reset: () => initialState,
    setUserName: (state, action) => {
      console.log(action.payload);
      state.username = action.payload;
    },
  },
});

export const { reset, setUserName } = appSlice.actions;

export default appSlice.reducer;
