import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import vimage15 from "../../../../images/Blogvictoriadarkstreet.webp";
import vimage14 from "../../../../images/Blogvictoriapalace1a.webp";
import vimage14_1 from "../../../../images/Blogvictoriahybridlamp4.webp";
import vmappa from "../../../../images/Blogvictoriakerosenelamp2.webp";
import vmappa_1 from "../../../../images/Blogvictoriaargandlamp3.webp";
import Navbargrey from "../../../../Components/Navbargrey/Navbargrey";
import Footergrey from "../../../../Components/Footergrey/Footergrey";
export const Victorian = () => {
  return (
    <div className="bg-zinc-400">
      <header className="relative z-10">
        <Navbargrey />
      </header>
      <div className="flex flex-row justify-center">
        <div className="bg-zinc-400">
          {/* main heading and date */}
          <p className="bg-zinc-400 p-6 sm:p-26 lg:p-24 xl:p-56 py-32">
            <span className="text-zinc-800 font-extralight text-3xl sm:text-5xl md::text-6xl lg:text-8xl">
              Sustainable lighting from Victoria Era
              <br />
              <br />
            </span>
            <span className="text-zinc-800 font-extralight text-base md:text-lg lg:text-xl leading-43.2px">
              updated jan 2023
            </span>
          </p>

          <figure className="flex flex-row justify-center align-items w-screen">
            <LazyLoadImage
              alt="Sustainable lighting from Victoria Era"
              effect="blur"
              className="w-full h-730 object-center object-cover"
              src={vimage14}
            />
          </figure>

          {/* first grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <figure class="">
              <LazyLoadImage
                alt="Sustainable lighting from Victoria Era"
                effect="blur"
                className="max-w-5/12 pt-2 object-center object-cover"
                src={vmappa}
              />
              <figcaption class="italic ">Kerosene Lamps</figcaption>
            </figure>
            <div class="text-zinc-900 font-light text-sm md:base xl:text-xl max-w-xl">
              The reign of Queen Victoria spanned the years 1837 to 1901, the
              era of technological advancements. In Victoria&#39;s time, candle
              lights and oil lamps were the only means of lighting houses and
              buildings. While the wealthiest used chandeliers or great candles
              for special occasions, luxury hotels in Italy and Spain were the
              first to use electric lights.&nbsp;&nbsp;At that time candles
              illuminated specific areas or the entire room. By consequence, too
              much of brightness was disturbing and gave the idea of a new
              source of lighting.
            </div>
          </div>

          {/* second grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <figure class="">
              <LazyLoadImage
                alt="Sustainable lighting from Victoria Era"
                effect="blur"
                className="max-w-5/12 pt-2 object-center object-cover"
                src={vmappa_1}
              />
              <figcaption class="italic">
                Revolutionary innovation of the 18th century&nbsp;&nbsp;- Argand
                Lamps
              </figcaption>
            </figure>
            <div class="text-zinc-900 font-light text-sm md:base xl:text-xl max-w-xl">
              The 1880s also saw circular wick lamps, but as electric lighting
              became prevalent, people ceased using oil lamps in their daily
              lives. In addition, there were hand-blown glass period bulbs
              available. Furthermore, oil lamps and candles were often used
              alone, and electric power was used when there were guests.
              Consequently, the bulbs lasted a long time. Besides, light did not
              emit any smell, unlike oil and candles, so it made for a more
              pleasant atmosphere for people.
              <br />
              <br />
              In Victorian homes, wick lamps were quite popular, available flat
              and circular. Unlike other lamps, this has a flame spreader built
              into the burner which allowed the possibility of increasing or
              decreasing the illumination of a candle, hence adapting light
              levels to specific needs. An open ribbon of cotton makes up the
              wick, as capillary action sucked up paraffin oil through the wick,
              the choice to choose paraffin smell further personalised the
              experience. Paraffin was cheaper than fuel, but it wasn’t enough
              efficient to illuminate the entire rooms.
            </div>
          </div>

          {/* image */}
          <figure className=" flex flex-row justify-center align-items">
            <LazyLoadImage
              alt="Sustainable lighting from Victoria Era"
              effect="blur"
              className="h-500 object-center object-cover"
              src={vimage14_1}
            />
          </figure>
          <figcaption className="flex flex-row justify-center align-items italic text-center">
            Efficiency and light control - magical powers of Circular wick lamps
          </figcaption>

          {/* image */}
          <div className=" flex flex-row justify-center align-items">
            <LazyLoadImage
              alt="Sustainable lighting from Victoria Era"
              effect="blur"
              className="p-5 h-500 w-full object-center object-cover"
              src={vimage15}
            />
          </div>

          {/* third grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 p-6 sm:py-12 md:p-24 xl:p-48">
            <div class="text-zinc-800 font-light text-sm md:text-base xl:text-xl max-w-xl">
              <br />
              If you would like to discuss further or you have an interesting
              light story to share, write to us at moien@kaash.eu.
            </div>
            <div class="text-zinc-800 font-light text-sm md:text-lg lg:text-xl">
              Lights were first turned on Pall Mall in 1930s, now with energy
              crisis and climate change we are going back to dark ages with
              municipalities switching off the lights. Just like Victorian ages,
              it's time for us to explore new innovations and set an example
              with new technologies to create efficient systems in elder
              communities.
            </div>
          </div>
        </div>
      </div>
      <Footergrey />
    </div>
  );
};
export default Victorian;
