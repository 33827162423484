import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbargrey from "../../../../Components/Navbargrey/Navbargrey";
import Footergrey from "../../../../Components/Footergrey/Footergrey";
import Bloglightconcert1 from "../../../../images/Bloglightconcert1.webp";
import Bloglightconcert2 from "../../../../images/Bloglightconcert2.webp";
import Bloglightconcert3 from "../../../../images/Bloglightconcert3.webp";
import Bloglightconcert5 from "../../../../images/Bloglightconcert5.webp";
export const Dancing = () => {
  return (
    <div className="bg-zinc-200">
      <header className="relative z-10">
        <Navbargrey />
      </header>
      <div className="flex flex-row justify-center">
        <div className="bg-zinc-200">
          {/* main heading and date */}
          <p className="bg-zinc-200 p-6 sm:px-26 lg:px-24 xl:px-56 py-32">
            <span className="text-neutral-950 font-extralight text-3xl sm:text-5xl md::text-6xl lg:text-8xl">
              Dancing with light tunes
              <br />
              <br />
            </span>
            <span className="text-neutral-950 font-extralight text-sm md:text-lg lg:text-xl ">
              updated jan 2023
            </span>
          </p>

          <LazyLoadImage
            alt="Rectangle"
            effect="blur"
            className="left-0 w-full object-cover object-center"
            src={Bloglightconcert2}
          />

          {/* first grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Bloglightconcert"
                effect="blur"
                className="max-w-5/12 pt-2 object-cover object-center"
                src={Bloglightconcert5}
              />
            </div>
            <div class="text-zinc-900 font-light text-sm md:base xl:text-xl max-w-xl">
              Lighting adds a layer of immersive experience in live concerts,
              triggering the sensorial receptors of our mind and body. The
              result is an atmosphere with positive vibes and feelings.
              <br />
              <br />
              <br />
              Lighting is built into concerts, addressing four major sections of
              the stage: downstage right, centre, left and upstage centre. Along
              with specific attention from soft ambient lighting and spotlights
              for added contrast. Then there are ancient par cans, which are
              essentially metal-encased car headlights. A dash of colour will
              fresnel and you have the great combination for stage lighting.
              <br />
              <br />
              <br />
              Traditionally, stage lighting came to life with theatres and music
              shows, eventually upgrading the infrastructure with LED lights.
              which on flick of a button switches the colour and light effect,
              with possibilities of millions of colours, shapes and shades.
            </div>
          </div>

          {/* second grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="">
              <LazyLoadImage
                alt="Bloglightconcert"
                effect="blur"
                className="max-w-5/12 pt-2 object-cover object-center"
                src={Bloglightconcert1}
              />
            </div>
            <div class="text-zinc-900 font-light text-sm md:base xl:text-xl max-w-xl">
              When was it conceived that lighting systems are as important as
              the concert itself? It all started out with strobe lights which
              then became laser displays. The first band to use it was Led
              Zeppelin in 1975, followed by many artists such as Marylin Manson
              in 2009 who performed with lights everywhere (from the stage to
              his fingertips) as the main characters. The curious thing is that
              he is blind, but he wanted to better involve his public and make
              they live a unique experience.
              <br />
              <br />
              Laser displays are also used by Indie artist to create a better
              ambiance for the public and breathtaking results. For example,
              indie electronic artist Dan Deacon created a free app for his
              concert that interacts with the concerts and make the public live
              a memorable experience.
            </div>
          </div>

          <div className="">
            <LazyLoadImage
              alt="Bloglightconcert"
              effect="blur"
              className="w-full object-cover object-center"
              src={Bloglightconcert3}
            />
          </div>

          {/* third grid */}
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-6 py-6 sm:py-12 md:py-24 md:px-24 xl:px-48">
            <div class="text-neutral-950 font-light text-sm md:text-base xl:text-xl max-w-xl">
              If you would like to discuss further or you have an interesting
              light story to share, write to us at moien@kaash.eu.
            </div>
            <div class="text-zinc-900 font-light text-sm md:text-lg lg:text-xl">
              With technology came programmable wristbands, synchronised colour
              flickers add another dimension to audience experience.
              <br />
              Tools are extremely advanced and intelligent, however the human
              evolution with the use of light power is not commendable. Over the
              last 50 years, the light effects have primarily improvised to
              laser strobes but the personalised lighting design approach to a
              concert is still missing. The inconsistent splash of colour with
              random strobes have not done much in elevating a music lover
              experience, the stages have definitely become fancier with crazy
              venues.
              <br />
              <br />
              It will be interesting to see the evolution of lighting design
              into concert planning where lighting is used as a visual element
              along with sound to complement each other. One can steal some tips
              from the theatre lighting guide book.
            </div>
          </div>
        </div>
      </div>
      <Footergrey />
    </div>
  );
};
export default Dancing;
