import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appReducer from './app/app.slice';

// Combine multiple reducers using combineReducers
const rootReducer = combineReducers({
  app: appReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
