import React from "react";

const ProgressBar = ({ currentPage, totalPages }) => {
  const renderProgressBar = () => {
    const progressBarItems = [];
    for (let i = 0; i < totalPages; i++) {
      const bgColor = i < currentPage ? "bg-purple-600" : "bg-gray-300";
      const borderRadius =
        i === 0 ? "rounded-l-lg" : i === totalPages - 1 ? "rounded-r-lg" : "";
      progressBarItems.push(
        <div key={i} className={`${bgColor} h-2 w-20 ${borderRadius}`}></div>
      );
    }
    return progressBarItems;
  };

  return (
    <div className="flex gap-x-1 items-center">
      {renderProgressBar()}
      <div className="ml-5 text-md">
        <text className="text-[#7246FD]">{currentPage}</text>
        <text className="text-[#9c7dfd]">/{totalPages}</text>
      </div>
    </div>
  );
};

export default ProgressBar;
