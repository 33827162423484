import React, { useState } from "react";
import { CgMenuGridR } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import Navbar_logo_dark from "../../images/Navbar_logo_dark.webp";
import Contactpop from '../../Components/Contactpopup/Contactpopup';

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [Talkdropdown, setTalkdropdown] = useState(false);
  const [Aboutdropdown, setAboutdropdown] = useState(false);
  const [Lightdropdown, setLightdropdown] = useState(false);
  const [Calculatordropodown, setCalculatordropodown] = useState(false);
  const [Lightfinderdropdown, setLightfinderdropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };

  return (
    <>
      <div className="shadow-md w-full ">
        <div
          className={`px-6 sm:px-26 lg:px-24 xl:px-56 text-zinc-400 md:flex items-center justify-between bg-transparent bg-blur-md py-5`}
        >
          <div className="">
            <Link to="/">
              <img className="w-28 sm:w-32" src={Navbar_logo_dark} alt="" />
            </Link>
          </div>

          <div
            onClick={() => setOpen(!open)}
            className="text-3xl absolute right-8 top-3 cursor-pointer md:hidden"
          >
            <ion-icon name={open ? "close" : "menu"}>
              {open ? <RxCross2 /> : <CgMenuGridR />}
            </ion-icon>
          </div>

          <ul
            className={`bg-zinc-100 font-light text-zinc-400 bg-opacity-10 shadow-2xl backdrop-blur-lg md:bg-transparent md:flex md:items-center md:pb-0 pb-4 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
              open ? "top-14 " : "top-[-490px]"
            }`}
          >
            {/* =====Talk===== */}
            <li
              className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
              onMouseEnter={() => setTalkdropdown(true)}
              onMouseLeave={() => setTalkdropdown(false)}
            >
              <Link className="md:py-5">Talk</Link>
              {Talkdropdown && (
                <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500 w-fit p-2 px-4">
                  <li>
                    <Link to="/whatwedo">What we do ?</Link>
                  </li>
                  <li>
                    <Link to="/ourprojects">Our projects</Link>
                  </li>
                </ul>
              )}
            </li>
            {/* =====About===== */}
            <li
              className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
              onMouseEnter={() => setAboutdropdown(true)}
              onMouseLeave={() => setAboutdropdown(false)}
            >
              <Link className="md:py-5">About</Link>
              {Aboutdropdown && (
                <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500  w-fit p-2 px-4">
                  <li>
                    <Link to="/whoarewe">Who are we ?</Link>
                  </li>
                  <li>
                    <Link to="/whatwebelieve">What we believe ? </Link>
                  </li>
                </ul>
              )}
            </li>
            {/* =====Light===== */}
            <li
              className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
              onMouseEnter={() => setLightdropdown(true)}
              onMouseLeave={() => setLightdropdown(false)}
            >
              <Link className="md:py-5">Light</Link>
              {Lightdropdown && (
                <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500  w-fit p-2 px-4">
                  <li>
                    <a href="https://kaash.eu/blog/">Light blogs</a>
                  </li>
                  <li>
                    <Link to="/lightschool">Light school</Link>
                  </li>
                </ul>
              )}
            </li>
            {/* =====Calculator===== */}
            <li
              className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
              onMouseEnter={() => setCalculatordropodown(true)}
              onMouseLeave={() => setCalculatordropodown(false)}
            >
              <Link className="md:py-5">Calculator</Link>
              {Calculatordropodown && (
                <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500  w-fit p-2 px-4">
                  <li>
                    <Link to="/carbonemission">Calculate carbon emission</Link>
                  </li>
                </ul>
              )}
            </li>
            {/* =====Light Finder===== */}
            <li
              className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
              onMouseEnter={() => setLightfinderdropdown(true)}
              onMouseLeave={() => setLightfinderdropdown(false)}
            >
              <Link className="md:py-5">Light Finder</Link>
              {Lightfinderdropdown && (
                <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500  w-fit p-2 px-4">
                  <li>
                    <Link to="/lightfinder">Find your perfect light</Link>
                  </li>
                </ul>
              )}
            </li>
            <button className="md:ml-8 text-base 2xl:text-lg  " onClick={togglePopup} >
              Contact us
            </button>
          </ul>
        </div>
      </div>
      <div className="contactpop-section z-50">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
    </>
  ); 
};

export default Navbar;
